import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {genetic_tooltip, genetic_max_tooltip, genetic_track_tooltip, genetic_tooltip_wtrack, genetic_max_tooltip_wtrack} from "../../../../organisms/Tooltips/race_tooltips";
import {createRedirectToken} from "../../../../../utils/signon";
import {axiosInstance, closeAllModals, startPageModals } from "../../../../../utils";

import $ from "jquery";

let current_user_id = 0; 
let current_wallet_address = '';
let signup_racer = 'spectator';
let prediction_raceday_id = 0;
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;
let pilots_checked = 0;
let racecrafts_checked = 0;
let boosters_checked = 0;
let current_racer_global = 0;
let current_race_unique_id = '';
let predictionRaces:any = [];
let raceDetails:any = [];

export const BaseLeagueWeeklyPredictions = (props:any) => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state:any) => state.account);

    /* EXR Event ID */
    var exreventid = props.exreventid;
    let league_permalink_id = 'pink-racing-league'
    let signup_user_id = 0;
    const [entrySet, setEntry] = useState(false);
    
    const [race_hash_global,setRaceHashGlobal] = useState('');
    const [race_season_id,setRaceSeasonID] = useState(props.season_id);
    const [raceday_id,setRacedayID] = useState(props.raceday_id);
    const [racer_details,setRacerDetails] = useState([]);
    const [prediction_html, setPredictionHTML] = useState(<></>);
    const [selected_prediction_html, setSelectedPredictionHTML] = useState(<></>);
    const [item_list, setItemList] = useState([]);
    let [racerList,setRacerList] = useState([]);


    function grabUserWeeklyPredictions() {
        prediction_raceday_id = props.raceday_id;
        var addData:any = new Object();
        addData.seasonID = encodeURIComponent(props.season_id);
        addData.racedayID = encodeURIComponent(props.raceday_id);
        addData.userID = encodeURIComponent(userID);

        axiosInstance.post('league/raceday_predictions/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var race_details = result.data;
                predictionRaces = race_details;
                if(predictionRaces.length > 0) {
                    generatePredictionRaceRows(predictionRaces);
                }
                
                startPageModals();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    async function generatePredictionRaceRows(racearr:any) {
        var racerowhtml:any = [];
        for(var i = 0; i < racearr.length;i++) {
            var nextracedata = racearr[i];
            var race_simulated = racearr[i]['race_simulated'];
            var simulation_viewable = racearr[i]['race_simulated'];
            var admin_gated = racearr[i]['admin_gated'];
            var next_race_id = racearr[i]['race_id'];
            var next_race_unique_id = racearr[i]['race_unique_id'];
            var importance_score = racearr[i]['importance_score'];
            var racers = racearr[i]['participants'];

            var watchability = 0;
            var viewsource = 'predict';
            if((race_simulated == 1)&&(admin_gated == 0)&&(simulation_viewable == 1)) {
                viewsource = 'results';
                watchability = 1;
            }

            var current_racer_watched = 1;
            var current_predictor_watched = 1;
            for(var z = 0; z < racers.length;z++) {
                var racer_predicted_watched = racers[z]['racer_predicted_watched'];
                var racer_sim_watched = racers[z]['racer_sim_watched'];
                var user_racer_predicted = racers[z]['racer_predicted'];
                var racer_racer_id = racers[z]['racer_id'];

                if(racer_racer_id == racerID) {
                    current_racer_watched = racer_sim_watched;
                }
                if(user_racer_predicted == 1) {
                    current_predictor_watched = racer_predicted_watched;
                }
            }

            if((viewsource == 'results')&&((current_racer_watched == 0)||(current_predictor_watched == 0))) {
                viewsource = 'predict';
                watchability = 2;
            }

            var race_html = await generatePredictionRaceRacers(racearr[i],viewsource);
                        
            let total_xp_points = 100;
            var racer_photos = [];
            var top_racer = '';
            var top_racer_image = <></>;
            var top_pct = 0;
            var predicted_racer = '';
            var predicted_racer_image = <></>;
            var predicted_racer_pct = 0;
            var thisracepredicted = 0;
            var thisracepredictid = 0;

            var winnerid = 0;
            var winning_racer = '';
            var winning_racer_xp:any = '';
            var winning_racer_pct = '';
            var winning_racer_image = <></>;
            var next_race_watched = 1;
            var shared_stack_race = 0;
            for(var z = 0; z < racers.length;z++) {
                var pilot_image = racers[z].pilot_image_location;
                var rowimage = <span className="left" 
                style={{flex:"1",height:"80px",borderRadius:"5px",marginRight:'2px',
                background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></span>;
                racer_photos.push(rowimage);


                var win_chance = Math.floor(100*(racers[z].racer_start_win_pct));
                var pred_xp = Math.round(total_xp_points*(1-(racers[z].racer_start_win_pct)));

                if(top_pct < racers[z].win_chance) {
                    top_pct = racers[z].win_chance;
                    top_racer = racers[z].racer_name;
                    top_racer_image = <span className="left" 
                    style={{width:"60px",height:"80px",borderRadius:"5px",marginRight:'2px',
                    background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></span>;
                }

                if(racers[z].racer_predicted == 1) {
                    predicted_racer_pct = racers[z].win_chance;
                    predicted_racer = racers[z].racer_name;
                    predicted_racer_image = <span className="left" 
                    style={{width:"60px",height:"80px",borderRadius:"5px",marginRight:'2px',
                    background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></span>;
                    thisracepredicted = 1;
                    thisracepredictid = racers[z].racer_id;

                    next_race_watched = racers[z].racer_predicted_watched;
                }

                if(racers[z].racer_id == racerID) {
                    next_race_watched = racers[z].racer_sim_watched;
                }

                if(racers[z].race_final_position == 1) {
                    winning_racer_pct = racers[z].win_chance;
                    winning_racer = racers[z].racer_name;
                    winning_racer_xp = pred_xp;
                    winning_racer_image = <span className="left" 
                    style={{width:"60px",height:"80px",borderRadius:"5px",marginRight:'2px',
                    background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></span>;

                    winnerid = racers[z].racer_id;
                }

                var shared_items = racers[z]['shared_items'];
                for(var p = 0; p < shared_items.length;p++) {
                    var shared_item_id = shared_items[p]['shared_item_id'];
                    var lender_id = shared_items[p]['lender_id'];
                    if(lender_id == userID) {
                        if(shared_item_id == racers[z]['pilot_item_id']) {
                            shared_stack_race = 1;
                        } else if(shared_item_id == racers[z]['ship_item_id']) {
                            shared_stack_race = 1;
                        } else if(shared_item_id == racers[z].boosters[0]) {
                            shared_stack_race = 1;
                        }
                    }
                }
            }
            
            var favorite_html = <><div className="row center-align" style={{marginTop:'15px',marginBottom:'10px',fontSize:'12px'}}>
                                        FAVORITE
                                    </div>
                                    <div className="row center-align" style={{marginBottom:'0px'}}>
                                    <span className="exr-base-blue-text">{top_pct}%</span> // {top_racer}
                                </div></>;
            var make_pick_text = 'MAKE PICK';
            var make_pick_class = 'white-text';
            if(predicted_racer.length > 0) {
                favorite_html = <><div className="row center-align exr-base-blue-text" style={{marginTop:'15px',marginBottom:'10px',fontSize:'12px'}}>
                                YOUR PICK
                            </div>
                            <div className="row center-align" style={{marginBottom:'0px'}}>
                            <span className="exr-base-blue-text">{predicted_racer_pct}%</span> // {predicted_racer}
                        </div></>;
                make_pick_text = 'PICK MADE';
                make_pick_class = 'exr-base-blue-text';
            }
            var relevance_html = <></>;
            if(importance_score >= 100) {
                relevance_html = <span className="left exr-base-blue white-text"
                                        style={{borderRadius:'5px',display:'inline-block',lineHeight:'16px',padding: '3px',marginTop:'-5px'}}
                                        title="This Is Your Race">
                                        <span style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}} >
                                            YOUR RACE
                                        </span>
                                    </span>;
            } else if((importance_score > 0)&&(shared_stack_race == 1)) {
                relevance_html = <span className="left"
                                        style={{borderRadius:'5px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding: '2.5px',marginTop:'-5px'}}
                                        title="Your Items Are Being Used In This Race">
                                        <span className="white-text" style={{padding:'20px 10px 10px 10px',fontSize:'14px',verticalAlign: 'middle'}} >
                                            SHARED STACK
                                        </span>
                                    </span>;
            }


            var make_pick_html = <><div className="row center-align" style={{marginTop:'15px',marginBottom:'10px',fontSize:'12px'}}>
                                        PREDICTION
                                    </div>
                                    <div key={'racepredbutton_'+next_race_id+'_p'+thisracepredicted+'_'+thisracepredictid} className="row center-align" style={{marginBottom:'0px'}}>
                                        <span className="white-base-blue-border-link"
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer'}}
                                            title="View Racers To Make Pick">
                                            <span className={make_pick_class} style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    onClick={(e)=>togglePredictRacersRows(e)}>
                                                {make_pick_text}
                                            </span>
                                        </span>
                                    </div></>;

            var mobilespacing = 'flex';
            var mobile1 = '32.5';
            var mobile2 = '35';
            var mobile3 = '32.5';
            var mobilebutton1 = 'block';
            var mobilebutton2 = 'none';
            var mobilebutton3 = 'none';
            if(viewsource == 'results') {
                favorite_html = <><div className="row center-align" style={{marginTop:'15px',marginBottom:'10px',fontSize:'12px'}}>
                                WINNER
                            </div>
                            <div className="row center-align" style={{marginBottom:'0px'}}>
                            {winning_racer} // <span className="exr-base-blue-text">{winning_racer_xp} XP</span>
                        </div></>;

                var pick_result = <>LOST</>;
                var won_xp = <>0 XP</>;
                if(winnerid == thisracepredictid) {
                    pick_result = <span className="exr-base-blue-text">WON</span>;
                    won_xp = <span className="exr-base-blue-text">{winning_racer_xp} XP</span>;
                }
                make_pick_html = <><div className="row center-align" style={{marginTop:'15px',marginBottom:'10px',fontSize:'12px'}}>
                                        YOUR PICK: {pick_result}
                                    </div>
                                    <div className="row center-align" style={{marginBottom:'0px'}}>
                                     {predicted_racer} // {won_xp}
                                </div></>;
                mobilespacing = 'block';
                mobile1 = '0';
                mobile2 = '50';
                mobile3 = '50';
                mobilebutton1 = 'none';
                mobilebutton2 = 'block';
                mobilebutton3 = 'none';
            }

            var race_action_buttons =<><span className="left white-base-blue-button-link"
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px'}}
                                            title="View Racers In Race">
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    id={"predict_view_rows_button_"+next_race_id}
                                                    onClick={(e)=>togglePredictRacersRows(e)}>
                                                VIEW RACERS
                                            </span>
                                        </span></>;
            if(watchability == 1) {
                race_action_buttons = <><span className="left white-base-blue-button-link"
                                                style={{marginTop:'5px',borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',marginRight:'10px'}}
                                                title="View Racers In Race">
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}}
                                                        data-raceid={next_race_id}
                                                        id={"predict_view_rows_button_"+next_race_id}
                                                        onClick={(e)=>togglePredictRacersRows(e)}>
                                                    VIEW RESULTS
                                                </span>
                                            </span>
                                        <span className="white-base-blue-border-link"
                                            style={{marginTop:'5px',borderRadius:'15px',border:'0px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer'}}
                                            title="View Racers To Make Pick">
                                            <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    data-raceuniqid={next_race_unique_id}
                                                    data-racewatched={next_race_watched}
                                                    onClick={(e)=>goToRaceView(e)}>
                                                WATCH RACE
                                            </span>
                                        </span></>;
            } else if(watchability == 2) {
                next_race_watched = 0;
                mobilebutton1 = 'none';
                mobilebutton2 = 'none';
                mobilebutton3 = 'block';
                race_action_buttons = <>
                                        <span className="left white-base-blue-border-link"
                                            style={{marginTop:'5px',borderRadius:'15px',border:'0px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer',marginRight:'10px'}}
                                            title="View Racers To Make Pick">
                                            <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    data-raceuniqid={next_race_unique_id}
                                                    data-racewatched={next_race_watched}
                                                    onClick={(e)=>goToRaceView(e)}>
                                                WATCH RACE
                                            </span>
                                        </span>
                                        <span id={"skipbutton_"+next_race_id} className="left white-base-blue-button-link"
                                                style={{marginTop:'5px',borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',marginRight:'10px'}}
                                                title="View Racers In Race">
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}}
                                                        data-raceid={next_race_id}
                                                        data-raceuniqid={next_race_unique_id}
                                                        data-racewatched={next_race_watched}
                                                        id={"predict_skip_rows_button_"+next_race_id}
                                                        onClick={(e)=>skipToResults(e)}>
                                                    SKIP TO RESULTS
                                                </span>
                                        </span>
                                        <div id={"skiploading_"+next_race_id} className="left preloader-wrapper small active splash-modal-loader" 
                                                style={{display:'none',marginTop:'5px',height:"25px",width:"25px",marginLeft:"20px",marginRight:"10px"}}>
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="left white-base-blue-button-link"
                                            style={{marginTop:'5px',borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px'}}
                                            title="View Racers In Race">
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'16px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    id={"predict_view_rows_button_"+next_race_id}
                                                    onClick={(e)=>togglePredictRacersRows(e)}>
                                                VIEW RACERS
                                            </span>
                                        </span></>;
            }
            
            var row_html = <div key={'racepred_'+next_race_id+'_p'+thisracepredicted+'_'+thisracepredictid+'_watch'+watchability} className="row exr-dark-grey-bg" style={{padding:'10px 0px',borderRadius:'10px'}}>
                <div className="col s12 hide-on-med-and-down show-on-large-only" style={{display:'flex',flexDirection:'row'}}>
                    <div className="left" style={{width:'240px'}}>
                        <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                            {racer_photos}
                        </div>
                    </div>
                    <div className="left" style={{flex:1,padding:'10px 25px'}}>
                        <div className="row" style={{marginTop:'5px',marginBottom:'10px'}}>
                            <span className="left headline-text" style={{marginRight:'20px',fontSize:'18px'}} >RACE #{next_race_id}</span>
                            {relevance_html}
                        </div>
                        <div className="row" style={{marginBottom:'0px'}}>
                            {race_action_buttons}
                        </div>
                    </div>
                    <div className="left" style={{width:'200px'}}>
                        {favorite_html}
                    </div>
                    <div className="left" style={{width:'200px'}}>
                        {make_pick_html}
                    </div>
                </div>
                <div className="col s12 show-on-med-and-down hide-on-large-only">
                    <div className="left" style={{width:'100%'}}>
                        <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                            {racer_photos}
                        </div>
                    </div>
                    <div className="left" style={{width:'100%'}}>
                        <div className="row" style={{marginTop:'15px',marginBottom:'0px',padding:'10px 25px'}}>
                            <span className="left headline-text" style={{marginRight:'20px',fontSize:'16px'}} >RACE #{next_race_id}</span>
                            {relevance_html}
                        </div>
                    </div>
                    <div className="left" style={{width:'100%'}}>
                        <div className="left" style={{display:mobilebutton3,width:'100%'}}>
                            <div className="left" style={{width:'100%',marginTop:'5px',marginBottom:'10px'}}>
                                <div key={'racepredbutton_'+next_race_id+'_p'+thisracepredicted+'_'+thisracepredictid} className="row center-align" style={{marginBottom:'0px'}}>
                                        
                                        <span className="left white-base-blue-border-link"
                                            style={{borderRadius:'15px',border:'0px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer',marginLeft:'20px'}}
                                            title="Watch Race">
                                            <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'10px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    data-raceuniqid={next_race_unique_id} 
                                                    data-racewatched={next_race_watched} 
                                                    onClick={(e)=>goToRaceView(e)}>
                                                WATCH RACE
                                            </span>
                                        </span>
                                        <span id={"skipbutton2_"+next_race_id} className="left white-base-blue-button-link"
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer',marginLeft:'10px'}}
                                            title="Skip To Results">
                                            <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'10px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    data-raceuniqid={next_race_unique_id}
                                                    data-racewatched={next_race_watched}
                                                    id={"predict_skip_rows_button2_"+next_race_id}
                                                    onClick={(e)=>skipToResults(e)}>
                                                SKIP TO RESULTS
                                            </span>
                                        </span>
                                        <div id={"skiploading2_"+next_race_id} className="left preloader-wrapper small active splash-modal-loader" 
                                                style={{display:'none',marginTop:'5px',height:"25px",width:"25px",marginLeft:"20px",marginRight:"10px"}}>
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="left white-base-blue-button-link"
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer',marginLeft:'10px'}}
                                            title="View Racers">
                                            <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'10px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    onClick={(e)=>togglePredictRacersRows(e)}>
                                                VIEW RACERS
                                            </span>
                                        </span>
                                </div>
                            </div>
                            <div className="left" style={{width:mobile2+'%',marginTop:'-5px',marginBottom:'10px'}}>
                                {favorite_html}
                            </div>
                            <div className="left" style={{width:mobile3+'%',marginTop:'-5px',marginBottom:'10px'}}>
                                {make_pick_html}
                            </div>
                        </div>
                        <div className="left" style={{display:mobilebutton2,width:'100%'}}>
                            <div className="left" style={{display:mobilespacing,flexDirection:'row',width:'100%'}}>
                                <div className="left" style={{width:'100%',marginTop:'5px',marginBottom:'10px'}}>
                                    <div key={'racepredbutton_'+next_race_id+'_p'+thisracepredicted+'_'+thisracepredictid} className="row center-align" style={{marginBottom:'0px'}}>
                                            <span className="left white-base-blue-button-link"
                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer',marginLeft:'20px'}}
                                                title="View Racers To Make Pick">
                                                <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'10px',verticalAlign: 'middle'}}
                                                        data-raceid={next_race_id}
                                                        onClick={(e)=>togglePredictRacersRows(e)}>
                                                    VIEW RESULTS
                                                </span>
                                            </span>
                                            <span className="left white-base-blue-border-link"
                                                style={{borderRadius:'15px',border:'0px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer',marginLeft:'10px'}}
                                                title="View Racers To Make Pick">
                                                <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'10px',verticalAlign: 'middle'}}
                                                        data-raceid={next_race_id}
                                                        data-raceuniqid={next_race_unique_id}
                                                        data-racewatched={next_race_watched}
                                                        onClick={(e)=>goToRaceView(e)}>
                                                    WATCH RACE
                                                </span>
                                            </span>
                                    </div>
                                </div>
                                <div className="left" style={{width:mobile2+'%',marginTop:'-5px',marginBottom:'10px'}}>
                                    {favorite_html}
                                </div>
                                <div className="left" style={{width:mobile3+'%',marginTop:'-5px',marginBottom:'10px'}}>
                                    {make_pick_html}
                                </div>
                            </div>
                        </div>
                        <div className="left" style={{display:mobilebutton1,width:'100%'}}>
                            <div className="left" style={{display:mobilespacing,flexDirection:'row',width:'100%'}}>
                                <div className="left" style={{width:mobile1+'%',marginTop:'-5px'}}>
                                    <div className="row center-align" style={{marginTop:'15px',marginBottom:'10px',fontSize:'10px'}}>
                                        VIEW
                                    </div>
                                    <div key={'racepredbutton_'+next_race_id+'_p'+thisracepredicted+'_'+thisracepredictid} className="row center-align" style={{marginBottom:'0px'}}>
                                        <span className="white-base-blue-border-link"
                                            style={{borderRadius:'15px',border:'0px solid white',display:'inline-block',lineHeight:'16px',padding:'2.5px',cursor:'pointer'}}
                                            title="View Racers To Make Pick">
                                            <span className='white-text' style={{padding:'20px 10px 10px 10px',fontSize:'10px',verticalAlign: 'middle'}}
                                                    data-raceid={next_race_id}
                                                    onClick={(e)=>togglePredictRacersRows(e)}>
                                                ALL RACERS
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="left" style={{width:mobile2+'%',marginTop:'-5px',marginBottom:'10px'}}>
                                    {favorite_html}
                                </div>
                                <div className="left" style={{width:mobile3+'%',marginTop:'-5px',marginBottom:'10px'}}>
                                    {make_pick_html}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"prediction_rows_"+next_race_id} className="col s12" style={{display:'none',marginTop:'15px'}}>
                    {race_html}
                </div>
            </div>
            racerowhtml.push(row_html);
        }
        setPredictionHTML(racerowhtml);

        var tooltips = document.querySelectorAll('.lineuptips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }

    async function generatePredictionRaceRacers(raceDetails:any,viewSource:string) {
        var itemarr = raceDetails['participants'];

        if(viewSource == 'results') {
            itemarr.sort(function(a:any, b:any) {
                return a.race_final_position - b.race_final_position;
            });
        }
        var section_html = [];
        var section_html_final = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes:any = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];

        var item_col_size = "col s12 m6";
        var item_image_height = '200px';
        var stats_box_height = '407px';

        item_image_height = '350px';

        let predicted_racer_id = 0;

        let total_xp_points = 20+(20*(itemarr.length-2));
        if((itemarr.length >= 4)||(raceDetails.open_invite_race == 0)) {
            total_xp_points = 100;
        }
        let current_max_pct = 0;
        let favorite_racer_id = 0;
        var tmp_predicted_totals = 0;
        var favorite_racer_adjustment = 0;
        
        /* CALCULATE BEST ODDS */
        for(var z = 0; z < itemarr.length;z++) {
            var racer_racer_id = itemarr[z].racer_id;
            var racer_bot_racer = itemarr[z].bot_racer;
            var racer_win_pct = itemarr[z].racer_start_win_pct;
            if(racer_win_pct > current_max_pct) {
                current_max_pct = racer_win_pct;
                favorite_racer_id = racer_racer_id
            }

            /* Update Prediction */
            if(itemarr[z].racer_predicted == 1) {
                predicted_racer_id = racer_racer_id;
            }
        }

        var prediction_whitelisted = 0;
        var prediction_whitelist_list = [];
        var user_prediction_whitelist_okay = 1;
        if(raceDetails.predictor_whitelist.length > 0) {
            prediction_whitelisted = 1;
            prediction_whitelist_list = raceDetails.predictor_whitelist.split(",");
            user_prediction_whitelist_okay = 0;
            for(var z = 0; z < prediction_whitelist_list.length;z++) {
                if(prediction_whitelist_list[z] == userID) {
                    user_prediction_whitelist_okay = 1;
                }
            }
        } 

        for(var z = 0; z < itemarr.length;z++) {
            var racer_racer_id = itemarr[z].racer_id;
            var race_participant_id = itemarr[z].race_participant_id;
            var pilot_image = itemarr[z].pilot_image_location;
            var racer_image = itemarr[z].ship_image_location;
            var booster_image = itemarr[z].boosters[6];
            var racer_name = itemarr[z].racer_name;
            var racer_bot_racer = itemarr[z].bot_racer;
            var race_track_faction = itemarr[z].race_track_galaxy;
            
            var score_speed = 0;
            var score_handling = 0;
            var score_acceleration = 0;
            var score_strength = 0;
            var score_focus = 0;
            var score_stamina = 0;
            var score_intelligence = 0;
            var score_aggression = 0;
            var score_speed2 = 0;
            var score_handling2 = 0;
            var score_acceleration2 = 0;
            var score_strength2 = 0;
            var score_focus2 = 0;
            var score_stamina2 = 0;
            var score_intelligence2 = 0;
            var score_aggression2 = 0;
            var score_handling3 = 0;
            var score_focus3 = 0;
            var score_speed3 = 0;
            var score_intelligence3 = 0;

            /* Setup Top Attribute Array */
            var attribute_arr = [];
            for(var p = 0; p < racer_attributes.length;p++) {
                var tmp_attr:any = {};
                tmp_attr['name'] = racer_attributes[p];
                tmp_attr['value'] = 0;
                attribute_arr.push(tmp_attr);
            }

            for(var p = 0; p < pilot_attributes.length;p++) {
                var tmp_attr2:any = {};
                tmp_attr2['name'] = pilot_attributes[p];
                tmp_attr2['value'] = 0;
                attribute_arr.push(tmp_attr2);
            }

            /* If Bot Racer, Then Use Override Attribute Stats and Booster Stats */
            if(racer_bot_racer == 1) {
                for(var m = 0; m < itemarr[z].override_attributes.length;m++) {
                    itemarr[z].override_attributes[m].attribute = itemarr[z].override_attributes[m].attribute.toLowerCase();
                    if(itemarr[z].override_attributes[m].attribute == 'speed') {
                        score_speed = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'handling') {
                        score_handling = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'acceleration') {
                        score_acceleration = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'strength') {
                        score_strength = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'focus') {
                        score_focus = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'stamina') {
                        score_stamina = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'aggression') {
                        score_aggression = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'intelligence') {
                        score_intelligence = itemarr[z].override_attributes[m].score;
                    } 

                    for(var p = 0; p < attribute_arr.length;p++) {
                        if(itemarr[z].override_attributes[m].attribute == attribute_arr[p]['name']) {
                            attribute_arr[p]['value'] = attribute_arr[p]['value'] + itemarr[z].override_attributes[m].score;
                        }
                    }
                }

                var booster_attribute_score = itemarr[z].boosters[9];
                var booster_attribute_name = itemarr[z].boosters[4].toLowerCase();
                if(booster_attribute_name == 'focus') {
                    score_focus2 = booster_attribute_score;
                } else if(booster_attribute_name == 'stamina') {
                    score_stamina2 = booster_attribute_score;
                } else if(booster_attribute_name == 'aggression') {
                    score_aggression2 = booster_attribute_score;
                } else if(booster_attribute_name == 'intelligence') {
                    score_intelligence2 = booster_attribute_score;
                } else if(booster_attribute_name == 'speed') {
                    score_speed2 = booster_attribute_score;
                } else if(booster_attribute_name == 'handling') {
                    score_handling2 = booster_attribute_score;
                } else if(booster_attribute_name == 'acceleration') {
                    score_acceleration2 = booster_attribute_score;
                }  else if(booster_attribute_name == 'strength') {
                    score_strength2 = booster_attribute_score;
                } 

                for(var p = 0; p < attribute_arr.length;p++) {
                    if(booster_attribute_name == attribute_arr[p]['name']) {
                        attribute_arr[p]['value'] = attribute_arr[p]['value'] + booster_attribute_score;
                    }
                }

            } else {
                
                for(var m = 0; m < itemarr[z].ship_attributes.length;m++) {
                    itemarr[z].ship_attributes[m].attribute = itemarr[z].ship_attributes[m].attribute.toLowerCase();

                    if(itemarr[z].ship_attributes[m].attribute == 'speed') {
                        score_speed = itemarr[z].ship_attributes[m].score;
                    } else if(itemarr[z].ship_attributes[m].attribute == 'handling') {
                        score_handling = itemarr[z].ship_attributes[m].score;
                    } else if(itemarr[z].ship_attributes[m].attribute == 'acceleration') {
                        score_acceleration = itemarr[z].ship_attributes[m].score;
                    }  else if(itemarr[z].ship_attributes[m].attribute == 'strength') {
                        score_strength = itemarr[z].ship_attributes[m].score;
                    } 

                    for(var p = 0; p < attribute_arr.length;p++) {
                        if(itemarr[z].ship_attributes[m].attribute == attribute_arr[p]['name']) {
                            attribute_arr[p]['value'] = attribute_arr[p]['value'] + itemarr[z].ship_attributes[m].score;
                        }
                    }
                }

                for(var m = 0; m < itemarr[z].pilot_attributes.length;m++) {
                    itemarr[z].pilot_attributes[m].attribute = itemarr[z].pilot_attributes[m].attribute.toLowerCase();
                    
                    if(itemarr[z].pilot_attributes[m].attribute == 'focus') {
                        score_focus = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'stamina') {
                        score_stamina = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'aggression') {
                        score_aggression = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'intelligence') {
                        score_intelligence = itemarr[z].pilot_attributes[m].score;
                    } 

                    for(var p = 0; p < attribute_arr.length;p++) {
                        if(itemarr[z].pilot_attributes[m].attribute == attribute_arr[p]['name']) {
                            attribute_arr[p]['value'] = attribute_arr[p]['value'] + itemarr[z].pilot_attributes[m].score;
                        }
                    }
                }
                
                var booster_attributes = itemarr[z].boosters[8];
                if(booster_attributes != null) {
                    for(var m = 0; m < booster_attributes.length;m++) {
                        booster_attributes[m].attribute = booster_attributes[m].attribute.toLowerCase();
                        if(booster_attributes[m].attribute == 'focus') {
                            score_focus2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'stamina') {
                            score_stamina2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'aggression') {
                            score_aggression2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'intelligence') {
                            score_intelligence2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'speed') {
                            score_speed2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'handling') {
                            score_handling2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'acceleration') {
                            score_acceleration2 = booster_attributes[m].score;
                        }  else if(booster_attributes[m].attribute == 'strength') {
                            score_strength2 = booster_attributes[m].score;
                        } 

                        for(var p = 0; p < attribute_arr.length;p++) {
                            if(booster_attributes[m].attribute == attribute_arr[p]['name']) {
                                attribute_arr[p]['value'] = attribute_arr[p]['value'] + booster_attributes[m].score;
                            }
                        }
                    }
                }
            }

            

            var genetic_boost_text = <></>;
            var track_faction_pilot_match = 0;
            var track_faction_racecraft_match = 0;
            if(itemarr[z].pilot_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_pilot_match = 1;
                score_intelligence3 = 2;
            }
            if(itemarr[z].ship_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_racecraft_match = 1;
                score_speed3 = 2;
            }
            if(itemarr[z].boosters[5] == null) {
                itemarr[z].boosters[5] = '';
            }

            if((itemarr[z].pilot_faction.toLowerCase() == itemarr[z].ship_faction.toLowerCase())&&
                (((itemarr[z].pilot_faction.toLowerCase() == itemarr[z].boosters[5].toLowerCase())||(itemarr[z].boosters[5].toLowerCase() == 'any'))||
                    (race_track_faction.toLowerCase() == itemarr[z].pilot_faction.toLowerCase()))) {
                score_handling3 = 4;
                score_focus3 = 4;
                var finalboosttip = genetic_max_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_max_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC MAX</span>;
            } else if(itemarr[z].pilot_faction.toLowerCase() == itemarr[z].ship_faction.toLowerCase()) {
                score_handling3 = 3;
                score_focus3 = 3;
                var finalboosttip = genetic_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC BOOST</span>;
            } else if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={genetic_track_tooltip}>GENETIC TRACK</span>;
            }

            for(var p = 0; p < attribute_arr.length;p++) {
                if(attribute_arr[p]['name'] == 'handling') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_handling3;
                } else if(attribute_arr[p]['name'] == 'focus') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_focus3;
                } else if(attribute_arr[p]['name'] == 'speed') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_speed3;
                } else if(attribute_arr[p]['name'] == 'intelligence') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_intelligence3;
                }
            }

            attribute_arr = attribute_arr.sort(function(a, b){ return b.value - a.value });

            var top3_stats_html = [];
            for(var p = 0;p<3;p++) {
                var attribute_name = attribute_arr[p]['name'];
                if(attribute_name.length > 6) {
                    attribute_name = attribute_name.substring(0,5);
                }
                var attribute_value = attribute_arr[p]['value'];
                var tmp_top3 = <>
                                <span className="left exr-stats-light-grey"
                                                        style={{marginLeft:'0px',marginRight:'7px',borderRadius:'5px',position:'relative',overflow:'hidden'}}>
                                                        <span className="left" style={{padding:'2.5px 10px',fontSize:'12px'}}>
                                                            {attribute_name}
                                                        </span>
                                                        <span className="right exr-stats-light-grey2" style={{padding:'2.5px 10px',fontSize:'12px'}}>
                                                            {attribute_value}
                                                        </span>
                                                    </span>
                            </>;
                top3_stats_html.push(tmp_top3);
            }

            var tmp_top3 = <>
                                <span className="left exr-stats-light-grey light-grey-stats-grey-button-link hide-on-med-and-down show-on-large-only" data-racerid={racer_racer_id} 
                                                        style={{marginLeft:'0px',marginRight:'0px',borderRadius:'5px',position:'relative',overflow:'hidden'}} 
                                                        onClick={(e)=>viewPredRacerLinueupStack(e)}>
                                                        <span className="left " style={{padding:'2.5px 10px',fontSize:'12px'}}
                                                             id={"view_pred_stack_button_"+racer_racer_id} data-racerid={racer_racer_id}>
                                                            View Stack
                                                        </span>
                                                    </span>
                            </>;
            
            top3_stats_html.push(tmp_top3);


            var win_chance:any = 0;
            var pred_xp = 0; 
            var predicted_place = '--';
            var predicted_place_avg = '--';
            if(itemarr[z].racer_start_win_pct != 0) {
                win_chance = Math.floor(100*(itemarr[z].racer_start_win_pct));
                pred_xp = Math.round(total_xp_points*(1-(itemarr[z].racer_start_win_pct)));
            }

            
            if(itemarr[z]['racer_start_likely_position'] > 0) {
                predicted_place = ordinal_suffix_of(itemarr[z]['racer_start_likely_position']);
            }

            if(itemarr[z]['racer_start_med_position'] > 0) {
                predicted_place_avg = itemarr[z]['racer_start_med_position'];
            }

            if ((win_chance == 0)&&(predicted_place != '--')) {
                win_chance = 1;
                favorite_racer_adjustment = favorite_racer_adjustment + 1;
            } else if ((win_chance == 0)&&(predicted_place == '--')) {
                win_chance = '--';
            }
            if(racer_racer_id == favorite_racer_id) { 
                win_chance = win_chance - favorite_racer_adjustment;
            }

            itemarr[z].win_chance = win_chance;
            itemarr[z].pred_xp = pred_xp;

            var total_predicted_amount = itemarr[z].total_predicted_amount;
            var total_predictors = itemarr[z].total_predictors;
            var tmp_predicted_totals = tmp_predicted_totals + parseInt(total_predictors);
            
            var actionbutton = <></>;
            var leaveactionbutton = <></>;

            var stackcol = 's12';
            var score_html = <div className="col s12 white-text" style={{fontSize:'14px'}}>
                                <div className="row headline-text" style={{marginTop:'0px',marginBottom:'2px',padding:'0px',position:'relative',fontSize:'12px'}}>
                                    <span className="left white-base-blue-link" title="Learn About Racing Stacks" style={{cursor:'pointer',marginLeft:'15px'}} onClick={()=>showStackInfoBox()}>
                                        RACE STACK
                                    </span>
                                    {genetic_boost_text} 
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row" style={{marginTop:'15px',marginBottom:'5px'}}>
                                    <div className={"col "+stackcol}>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Speed</div>
                                                    <div className="right">{(score_speed+score_speed2+score_speed3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_speed2+'%',left:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_speed3+'%',left:(score_speed+score_speed2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Handling</div>
                                                    <div className="right">{(score_handling+score_handling2+score_handling3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_handling2+'%',left:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_handling3+'%',left:(score_handling+score_handling2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Acceleration</div>
                                                    <div className="right">{(score_acceleration+score_acceleration2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_acceleration+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_acceleration2+'%',left:score_acceleration+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className={"col s12"}>
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Strength</div>
                                                    <div className="right">{(score_strength+score_strength2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_strength+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_strength2+'%',left:score_strength+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col "+stackcol}>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Focus</div>
                                                    <div className="right">{(score_focus+score_focus2+score_focus3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_focus2+'%',left:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_focus3+'%',left:(score_focus+score_focus2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Stamina</div>
                                                    <div className="right">{(score_stamina+score_stamina2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_stamina+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_stamina2+'%',left:score_stamina+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Aggression</div>
                                                    <div className="right">{(score_aggression+score_aggression2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_aggression+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_aggression2+'%',left:score_aggression+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Intelligence</div>
                                                    <div className="right">{(score_intelligence+score_intelligence2+score_intelligence3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_intelligence2+'%',left:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_intelligence3+'%',left:(score_intelligence+score_intelligence2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;

            var inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}}
                                                title="Predict This Racer to Win"  >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom'}}
                                                        data-raceid={raceDetails.race_id}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}
                                                        data-racernum={z+1}
                                                        data-racername={racer_name}
                                                        data-racerphoto={pilot_image}
                                                        data-racerxp={pred_xp}
                                                        data-racerwinchance={win_chance}
                                                        onClick={(e)=>openPredictRacerBox(e)}>
                                                    PREDICT
                                                </span>
                                            </span>;
            
            if ((raceDetails.open_invite_race == 1)&&(raceDetails.entry_window_over == 0)) {
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} 
                                                title="Picks Are Closed" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                        data-raceid={raceDetails.race_id}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}
                                                        data-racernum={z+1}
                                                        data-racername={racer_name}
                                                        data-racerphoto={pilot_image}
                                                        data-racerxp={pred_xp}
                                                        data-racerwinchance={win_chance}>
                                                    CLOSED
                                                </span>
                                            </span>;
            } else if ((predicted_racer_id > 0)&&(predicted_racer_id == racer_racer_id)) {
                inline_predict_button = <span className="white-base-blue-button-link-active" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} 
                                                title="You Predicted This Racer To Win" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom'}}
                                                        data-raceid={raceDetails.race_id}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}
                                                        data-racernum={z+1}
                                                        data-racername={racer_name}
                                                        data-racerphoto={pilot_image}
                                                        data-racerxp={pred_xp}
                                                        data-racerwinchance={win_chance}>
                                                    PICKED
                                                </span>
                                            </span>;
            } else if ((raceDetails.open_invite_race == 1)&&(raceDetails.predictions_window_open == 0)) {
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} 
                                                title="Picks Are Closed" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                        data-raceid={raceDetails.race_id}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}
                                                        data-racernum={z+1}
                                                        data-racername={racer_name}
                                                        data-racerphoto={pilot_image}
                                                        data-racerxp={pred_xp}
                                                        data-racerwinchance={win_chance}>
                                                    CLOSED
                                                </span>
                                            </span>;
            } else if ((raceDetails.open_invite_race == 0)&&(raceDetails.predictions_window_over == 1)) {
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} 
                                                title="Picks Are Closed" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                        data-raceid={raceDetails.race_id}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}
                                                        data-racernum={z+1}
                                                        data-racername={racer_name}
                                                        data-racerphoto={pilot_image}
                                                        data-racerxp={pred_xp}
                                                        data-racerwinchance={win_chance}>
                                                    CLOSED
                                                </span>
                                            </span>;
            } else if (((predicted_racer_id > 0)&&(predicted_racer_id != racer_racer_id)&&(total_predicted_amount > 0))||(raceDetails.race_simulated == 1)) {
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} 
                                            title="Picks Are Closed" >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                    data-raceid={raceDetails.race_id}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    data-racernum={z+1}
                                                    data-racername={racer_name}
                                                    data-racerphoto={pilot_image}
                                                    data-racerxp={pred_xp}
                                                    data-racerwinchance={win_chance}>
                                                CLOSED
                                            </span>
                                        </span>;
            } else if (user_prediction_whitelist_okay == 0) {
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} 
                                            title="You Don't Have Access To Predict This Race" >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                    data-raceid={raceDetails.race_id}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    data-racernum={z+1}
                                                    data-racername={racer_name}
                                                    data-racerphoto={pilot_image}
                                                    data-racerxp={pred_xp}
                                                    data-racerwinchance={win_chance}>
                                                LOCKED
                                            </span>
                                        </span>;
                
            } else if ((predicted_racer_id > 0)&&(predicted_racer_id != racer_racer_id)&&(user_prediction_whitelist_okay == 1)) {
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}}
                                            title="Predict This Racer to Win"  >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom'}}
                                                    data-raceid={raceDetails.race_id}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    data-racernum={z+1}
                                                    data-racername={racer_name}
                                                    data-racerphoto={pilot_image}
                                                    data-racerxp={pred_xp}
                                                    data-racerwinchance={win_chance}
                                                    onClick={(e)=>openPredictRacerBox(e)}>
                                                CHANGE
                                            </span>
                                        </span>;
            } else if((raceDetails.open_invite_race == 1)&&($("#current-racer-preds").val() == 0)) {
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} 
                                            title="You Are Out Of Predictions For This Race Day">
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                    data-raceid={raceDetails.race_id}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    data-racernum={z+1}
                                                    data-racername={racer_name}
                                                    data-racerphoto={pilot_image}
                                                    data-racerxp={pred_xp}
                                                    data-racerwinchance={win_chance}>
                                                CLOSED
                                            </span>
                                        </span>;
            }

            var total_predictors_text = total_predictors+' Predictor';
            if(total_predictors != 1) {
                total_predictors_text = total_predictors_text+'s';
            }

            var bonus_html = '';
            var underdog_bonus = itemarr[z].racer_underdog_bonus;
            if (underdog_bonus > 0) {
                bonus_html = ' // '+underdog_bonus+' RPM Bonus';
            }
            if((underdog_bonus == 0)&&(predicted_place == '--')) {
                underdog_bonus = '--';
            } else {
                underdog_bonus = underdog_bonus+' RPM';
            }

            var predict_html = <><div className="row finish-screen-racer-divider"></div>
                                    <div className="row finish-screen-racer-sub" style={{margin:'0px',padding:'0px'}}>
                                        <div className="col s6 left-align">
                                            {win_chance}% Win&nbsp;{bonus_html}
                                        </div>
                                        <div className="col s6 right-align">
                                            {pred_xp} XP Reward&nbsp;&nbsp;//&nbsp;&nbsp;
                                            {total_predictors_text}
                                        </div>
                                        <div className="col s12 center-align" style={{padding:'0px',paddingTop:'10px'}}>
                                            <span style={{display:'table',margin:'0 auto'}}>  
                                            {inline_predict_button}
                                            </span>
                                        </div>
                                    </div></>;

            if((raceDetails.open_invite_race == 1)&&(raceDetails.entry_window_over == 0)) {
                predict_html = <></>;
            }


            var shared_items = itemarr[z]['shared_items'];
            var user_owns_stacks = 0;
            var pilot_shared_index = -1;
            var racecraft_shared_index = -1;
            var booster_shared_index = -1;
            for(var p = 0; p < shared_items.length;p++) {
                var shared_item_id = shared_items[p]['shared_item_id'];
                var lender_id = shared_items[p]['lender_id'];
                var lender_name = shared_items[p]['lender_name'];
                if(shared_item_id == itemarr[z]['pilot_item_id']) {
                    pilot_shared_index = p;
                    if(lender_id == userID) {
                        user_owns_stacks = 1;
                    }
                } else if(shared_item_id == itemarr[z]['ship_item_id']) {
                    racecraft_shared_index = p;
                    if(lender_id == userID) {
                        user_owns_stacks = 1;
                    }
                } else if(shared_item_id == itemarr[z].boosters[0]) {
                    booster_shared_index = p;
                    if(lender_id == userID) {
                        user_owns_stacks = 1;
                    }
                }
            }
            var booster_split = itemarr[z].boosters[3].replace("EXR ","").split("[");
            var booster_name = booster_split[0];

            var pilot_faction = itemarr[z].pilot_faction;
            var ship_faction = itemarr[z].ship_faction;
            var booster_faction = itemarr[z].boosters[5];
            if(pilot_faction == 'Augment') {
                pilot_faction = 'Aug';
            } else {
                pilot_faction = pilot_faction.substring(0,4);
            }
            if(ship_faction == 'Augment') {
                ship_faction = 'Aug';
            } else {
                ship_faction = ship_faction.substring(0,4);
            }
            if(booster_faction == 'Augment') {
                booster_faction = 'Aug';
            } else {
                booster_faction = booster_faction.substring(0,4);
            }
            

            var stack_visual = [];
            var tmp_pilot_visual = <div className="left" style={{width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
            if(pilot_shared_index >= 0) {
                var sectiontextcolor = 'grey-text';
                if(shared_items[pilot_shared_index]['lender_id'] == userID) {
                    sectiontextcolor = 'exr-base-blue-text';
                }
                tmp_pilot_visual = <div className="left" style={{position:'relative',width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                    <div className="exr-trans-grey-bg center-align" 
                        style={{position:'absolute',bottom:'5px',left:'1.5%',width:'97%',paddingTop:'20px',paddingBottom:'5px',borderRadius:'10px',fontSize:'14px'}}>
                        <div className="row headline-text" style={{margin:'0px',padding:'0px 20px',maxHeight:'45px',overflow:'hidden'}}>
                            {itemarr[z].pilot_name.replace("EXR ","").toUpperCase()}
                        </div>
                        <div className="row subheadline-text exr-base-blue-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',fontSize:'12px'}}>
                            {itemarr[z].pilot_subname.toUpperCase()} &nbsp;&nbsp;///&nbsp;&nbsp;{pilot_faction.toUpperCase()}
                        </div>
                        <div className={"row subheadline-text grey-text"} style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',overflow:'hidden'}}>
                            SHARED BY: <span className={sectiontextcolor} >{shared_items[pilot_shared_index]['lender_name'].toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            }
            var tmp_racecraft_visual = <div className="left" style={{width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
            if(racecraft_shared_index >= 0) {
                var sectiontextcolor = 'grey-text';
                if(shared_items[racecraft_shared_index]['lender_id'] == userID) {
                    sectiontextcolor = 'exr-base-blue-text';
                }
                tmp_racecraft_visual = <div className="left" style={{position:'relative',width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                    <div className="exr-trans-grey-bg center-align" 
                        style={{position:'absolute',bottom:'5px',left:'1.5%',width:'97%',paddingTop:'20px',paddingBottom:'5px',borderRadius:'10px',fontSize:'14px'}}>
                        <div className="row headline-text" style={{margin:'0px',padding:'0px 20px',maxHeight:'45px',overflow:'hidden'}}>
                            {itemarr[z].ship_name.replace("EXR ","").toUpperCase()}
                        </div>
                        <div className="row subheadline-text exr-base-blue-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',fontSize:'12px'}}>
                            {itemarr[z].ship_subname.toUpperCase()} &nbsp;&nbsp;///&nbsp;&nbsp;{ship_faction.toUpperCase()}
                        </div>
                        <div className={"row subheadline-text grey-text"} style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',overflow:'hidden'}}>
                            SHARED BY: <span className={sectiontextcolor} >{shared_items[racecraft_shared_index]['lender_name'].toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            }

            var tmp_booster_visual = <div className="left" style={{width:"32%",height:"400px",borderRadius:"10px",background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
            if(booster_shared_index >= 0) {
                var sectiontextcolor = 'grey-text';
                if(shared_items[booster_shared_index]['lender_id'] == userID) {
                    sectiontextcolor = 'exr-base-blue-text';
                }
                tmp_booster_visual = <div className="left" style={{position:'relative',width:"32%",height:"400px",borderRadius:"10px",background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                    <div className="exr-trans-grey-bg center-align" 
                        style={{position:'absolute',bottom:'5px',left:'1.5%',width:'97%',paddingTop:'20px',paddingBottom:'5px',borderRadius:'10px',fontSize:'14px'}}>
                        <div className="row headline-text" style={{margin:'0px',padding:'0px 20px',maxHeight:'45px',overflow:'hidden'}}>
                            {booster_name.toUpperCase()}
                        </div>
                        <div className="row subheadline-text exr-base-blue-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',fontSize:'12px'}}>
                            {itemarr[z].boosters[4].toUpperCase()} &nbsp;&nbsp;///&nbsp;&nbsp;{booster_faction.toUpperCase()}
                        </div>
                        <div className={"row subheadline-text grey-text"} style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',overflow:'hidden'}}>
                            SHARED BY: <span className={sectiontextcolor} >{shared_items[booster_shared_index]['lender_name'].toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            }

            var user_owned_flag = <></>;
            if(user_owns_stacks == 1) {
                user_owned_flag = <span className="left white-base-blue-border-link"
                                        style={{borderRadius:'5px',border:'1px solid white',display:'inline-block',lineHeight:'14px',padding: '2.5px 0px',marginTop:'2px',marginLeft:'0px'}}
                                        title="Your Items Are Being Used In This Race">
                                        <span className="white-text" style={{padding:'10px 5px',fontSize:'10px',verticalAlign: 'middle'}} >
                                            SHARED STACK
                                        </span>
                                    </span>;
            }

            let statsopen = 'none';
            // if(racer_racer_id == racerID){
            //     statsopen = 'block'
            // }

            var tmp_html = <div key={z} className={"col s12 finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px"}}>
                <div id={"fboard"+z} className="final-results-col exr-new-light-grey" style={{position:'relative'}}>
                    <div className="row" style={{marginBottom:'0px'}}>
                        <div className="col s12" style={{padding:'0px'}}>
                            <div className="col s12 l6">
                                <div className="row" style={{display:'flex',flexDirection:'row',padding:'0px 20px 0px 20px',marginTop:'5px',marginBottom:'10px'}}>
                                    <div className="left" style={{marginRight:'5px'}}>
                                        <div className="left" style={{width:"30px",height:"40px",borderRadius:"2px",marginRight:'2px',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                        <div className="left hide-on-small-only show-on-med-and-up" style={{width:"30px",height:"40px",borderRadius:"2px",marginRight:'2px',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                        <div className="left hide-on-small-only show-on-med-and-up" style={{width:"30px",height:"40px",borderRadius:"2px",marginRight:'0px',background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                    </div>
                                    <div className="left" style={{flex:'1'}}>
                                        <div className="left" style={{fontSize:'20px'}}>
                                            <div className="left grey-text" style={{marginTop:'0px',width:'100%'}}>
                                                <div style={{marginLeft:'1px',padding:'0px',fontSize:'10px',verticalAlign: 'middle'}}>
                                                RACER {z+1}
                                                </div>
                                            </div>
                                            <div className="left" style={{marginTop:'2px',width:'100%'}}>
                                                <div className="left" style={{marginTop:'2px',marginRight:'20px'}}>{racer_name.toUpperCase()}</div>
                                                {user_owned_flag}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right center-align show-on-med-and-down hide-on-large-only" style={{width:'70px'}}>
                                        <div className="left" style={{width:'100%',marginBottom:'2px',fontSize:'10px'}}>Win {pred_xp} XP</div>
                                        <div className="left" style={{width:'100%',marginBottom:'10px',fontSize:'16px'}}>
                                            {inline_predict_button}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{padding:'0px 20px 0px 20px',marginBottom:'5px'}}>
                                    <div className="left">
                                        {top3_stats_html}
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 l6">
                                <div className="row show-on-large-only hide-on-med-and-down" style={{margin:'0px',padding:'0px',marginBottom:'5px'}}>
                                    &nbsp;
                                </div>
                                <div className="row center-align" style={{padding:'0px 20px',marginTop:'0px',marginBottom:'10px'}}>
                                    <div className="col s12 m2">
                                        &nbsp;
                                    </div>
                                    <div className="col s3 m2">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Win Chance</div>
                                        <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{win_chance}%</div>
                                    </div>
                                    <div className="col s3 m2">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Most Finishes</div>
                                        <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{predicted_place}</div>
                                    </div>
                                    <div className="col s3 m2">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Dog Bonus</div>
                                        <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{underdog_bonus}</div>
                                    </div>
                                    <div className="col s3 m2">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Predictors</div>
                                        <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{total_predictors}</div>
                                    </div>
                                    <div className="col s12 m2 show-on-large-only hide-on-med-and-down">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Win {pred_xp} XP</div>
                                        <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>
                                            {inline_predict_button}
                                        </div>
                                    </div>
                                    <div className="col s6 m2" style={{display:'none'}}>
                                        <div className="row" style={{marginBottom:'0px',fontSize:'10px'}}>Win ZTG</div>
                                        <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>
                                            <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'middle'}}
                                                        data-raceid={raceDetails.race_id}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}
                                                        data-racernum={z+1}
                                                        data-racername={racer_name}
                                                        data-racerphoto={pilot_image}
                                                        data-racerxp={pred_xp}
                                                        data-racerwinchance={win_chance}
                                                        onClick={(e)=>openPredictRacerBox(e)}>
                                                    PAID
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 center-align show-on-med-and-down hide-on-large-only">
                                <span className="left exr-stats-light-grey light-grey-stats-grey-button-link" data-racerid={racer_racer_id} 
                                    style={{width:'100%',marginLeft:'0%',borderRadius:'5px',position:'relative',overflow:'hidden'}} 
                                    onClick={(e)=>viewPredRacerLinueupStack(e)}>
                                    <span style={{padding:'2.5px 10px',fontSize:'12px'}}
                                            id={"view_stack_button2_"+racer_racer_id} data-racerid={racer_racer_id}>
                                        View Stack
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id={"lineup_pred_stack_"+racer_racer_id} className="col s12" style={{padding:'5px 20px',display:statsopen}}>
                            <div className="row exr-trans-black-bg-subtle" style={{margin:'0px',padding:"10px",borderRadius:"10px",height:'auto',marginBottom:'0px'}}>
                                <div className="col s12 m8" style={{padding:'0px'}}>
                                    {tmp_pilot_visual}
                                    {tmp_racecraft_visual}
                                    {tmp_booster_visual}
                                </div>
                                <div className="col s12 m4" style={{padding:'10px 0px 0px 0px'}}>
                                    {score_html}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;

            /* Calculate Race Time */
            var racer_final_seconds = itemarr[z].race_final_time.toString();
            var millisplit = racer_final_seconds.split(".");
            var millicount:any = '';
            if(millisplit.length == 1) {
                millicount = '00';
                var totalseconds = parseInt(millisplit[0]);
            } else {
                millicount = millisplit[1];
                var totalseconds = parseInt(millisplit[0]);
            }
            if(millicount.length == 1) {
                millicount = millicount+'0';
            }
            var totalmins = Math.floor(totalseconds / 60);
            var finalseconds = totalseconds - (totalmins*60);
            var finaltimetxt = '';
            if(totalmins < 10) {
                finaltimetxt = finaltimetxt+'0';
            }
            finaltimetxt = finaltimetxt+totalmins+'m ';
            if(finalseconds < 10) {
                finaltimetxt = finaltimetxt+'0';
            }
            finaltimetxt = finaltimetxt+finalseconds+'s ';
            
            finaltimetxt = finaltimetxt+millicount+'ms';


            var racer_final_xp = itemarr[z].race_final_xp;
            var racer_final_rpm = itemarr[z].race_final_coins;

            var user_prediction_winner = itemarr[z].racer_predicted_win;
            var user_prediction_winner_xp = itemarr[z].racer_predicted_win_xp; 
            var final_finish_place = itemarr[z].race_final_position;
            var final_xp = itemarr[z].race_final_xp;
            var winning_xp = itemarr[z].racer_predicted_win_xp;
            var final_underdog_bonus = 0;
            var final_xp_won = 0;
            var pick_status_text = 'PICK STATUS';
            
            inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                                                                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} >
                                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                                        data-raceid={raceDetails.race_id}
                                                                        data-racerid={racer_racer_id}
                                                                        data-pracerid={race_participant_id}
                                                                        data-racernum={z+1}
                                                                        data-racername={racer_name}
                                                                        data-racerphoto={pilot_image}
                                                                        data-racerxp={pred_xp}
                                                                        data-racerwinchance={win_chance}>
                                                                    LOST
                                                                </span>
                                                            </span>;
            if(final_finish_place == 1) {
                final_xp_won = pred_xp;
                final_underdog_bonus = underdog_bonus;
                inline_predict_button = <>WON</>;
                inline_predict_button = <span className="white-base-blue-button-link" data-predracerid={racer_racer_id} 
                    style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}}>
                    <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                            data-raceid={raceDetails.race_id}
                            data-racerid={racer_racer_id}
                            data-pracerid={race_participant_id}
                            data-racernum={z+1}
                            data-racername={racer_name}
                            data-racerphoto={pilot_image}
                            data-racerxp={pred_xp}
                            data-racerwinchance={win_chance}>
                        WON
                    </span></span>;
            }
            
            if(itemarr[z].racer_predicted > 0) {
                pick_status_text = 'YOUR PICK';
                
                inline_predict_button = <span className="white-base-blue-button-link-active" data-predracerid={racer_racer_id} 
                style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}}>
                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                        data-raceid={raceDetails.race_id}
                        data-racerid={racer_racer_id}
                        data-pracerid={race_participant_id}
                        data-racernum={z+1}
                        data-racername={racer_name}
                        data-racerphoto={pilot_image}
                        data-racerxp={pred_xp}
                        data-racerwinchance={win_chance}>
                    LOST
                </span></span>;
                if(final_finish_place == 1) {
                    inline_predict_button = <span className="white-base-blue-button-link-active" data-predracerid={racer_racer_id} 
                    style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}}>
                    <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                            data-raceid={raceDetails.race_id}
                            data-racerid={racer_racer_id}
                            data-pracerid={race_participant_id}
                            data-racernum={z+1}
                            data-racername={racer_name}
                            data-racerphoto={pilot_image}
                            data-racerxp={pred_xp}
                            data-racerwinchance={win_chance}>
                        WON
                    </span></span>;
                }
            }

            var final_place_color = 'white-text';
            // if(racer_racer_id == racerID){
            //     final_place_color = 'exr-base-blue-text';
            // }

            var tmp_html_results = <div key={z} className={"col s12 finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px"}}>
                <div id={"fboard"+z} className="final-results-col exr-new-light-grey" style={{position:'relative'}}>
                    <div className="row" style={{marginBottom:'0px'}}>
                        <div className="col s12" style={{padding:'0px'}}>
                            <div className="col s12 l6">
                                <div className="row" style={{display:'flex',flexDirection:'row',padding:'0px 20px 0px 20px',marginTop:'5px',marginBottom:'10px'}}>
                                    <div className="left" style={{marginLeft:'-5px',marginRight:'15px'}}>
                                        <div className={"left headline-text "+final_place_color} style={{marginTop:'5px',fontSize:'24px'}}>
                                            {final_finish_place}
                                        </div>
                                    </div>
                                    <div className="left" style={{marginRight:'5px'}}>
                                        <div className="left hide-on-med-and-down show-on-large-only" style={{width:"30px",height:"65px",borderRadius:"2px",marginRight:'2px',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                        <div className="left hide-on-med-and-down show-on-large-only" style={{width:"30px",height:"65px",borderRadius:"2px",marginRight:'2px',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                        <div className="left hide-on-med-and-down show-on-large-only" style={{width:"30px",height:"65px",borderRadius:"2px",marginRight:'0px',background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                        <div className="left show-on-med-and-down hide-on-large-only" style={{width:"30px",height:"35px",borderRadius:"2px",marginRight:'2px',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                    </div>
                                    <div className="left" style={{flex:'1',overflow:'hidden'}}>
                                        <div className="left" style={{fontSize:'20px'}}>
                                            <div className="left grey-text" style={{marginTop:'0px',width:'100%'}}>
                                                <div style={{marginLeft:'1px',padding:'0px',fontSize:'10px',verticalAlign: 'middle'}}>
                                                RACER {z+1}
                                                </div>
                                            </div>
                                            <div className="left" style={{marginTop:'2px',width:'100%'}}>
                                                <div className="left" style={{marginTop:'2px',marginRight:'10px'}}>{racer_name.toUpperCase()}</div>
                                                {user_owned_flag}
                                            </div>
                                        </div>
                                        <div className="left" style={{marginTop:'7px',width:'100%'}}>
                                            <span className="left exr-stats-light-grey light-grey-stats-grey-button-link hide-on-med-and-down show-on-large-only" data-racerid={racer_racer_id} 
                                                style={{marginLeft:'0px',marginRight:'10px',borderRadius:'5px',position:'relative',overflow:'hidden'}} 
                                                onClick={(e)=>viewPredRacerLinueupStack(e)}>
                                                <span className="left " style={{padding:'2.5px 10px',fontSize:'12px'}}
                                                        id={"view_stack_button2_"+racer_racer_id} data-racerid={racer_racer_id}>
                                                    View Stack
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="right center-align show-on-med-and-down hide-on-large-only" style={{width:'70px'}}>
                                        <div className="left" style={{width:'100%',marginBottom:'2px',fontSize:'10px'}}>{pick_status_text}</div>
                                        <div className="left" style={{width:'100%',marginBottom:'10px',fontSize:'16px'}}>
                                            {inline_predict_button}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 l6">
                                <div className="row show-on-large-only hide-on-med-and-down" style={{margin:'0px',padding:'0px',marginBottom:'5px'}}>
                                    &nbsp;
                                </div>
                                <div className="row center-align" style={{padding:'0px 20px',marginTop:'0px',marginBottom:'10px'}}>
                                    <div className="col s6 m3 l3">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Finish Time</div>
                                        <div className="row" style={{marginBottom:'15px',fontSize:'16px'}}>{finaltimetxt}</div>
                                    </div>
                                    <div className="col s6 m3 l2">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Predictors</div>
                                        <div className="row" style={{marginBottom:'15px',fontSize:'16px'}}>{total_predictors}</div>
                                    </div>
                                    <div className="col s6 m3 l2">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Predictors Win</div>
                                        <div className="row" style={{marginBottom:'15px',fontSize:'16px'}}>
                                            {final_xp_won} XP
                                        </div>
                                    </div>
                                    <div className="col s6 m3 l2">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Win Chance</div>
                                        <div className="row" style={{marginBottom:'15px',fontSize:'16px'}}>{win_chance}%</div>
                                    </div>
                                    <div className="col s3 l3 show-on-large-only hide-on-med-and-down">
                                        <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>{pick_status_text}</div>
                                        <div className="row" style={{marginBottom:'15px',fontSize:'16px'}}>
                                            {inline_predict_button}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 center-align show-on-med-and-down hide-on-large-only">
                                <span className="left exr-stats-light-grey light-grey-stats-grey-button-link" data-racerid={racer_racer_id} 
                                    style={{width:'100%',marginLeft:'0%',borderRadius:'5px',position:'relative',overflow:'hidden'}} 
                                    onClick={(e)=>viewPredRacerLinueupStack(e)}>
                                    <span style={{padding:'2.5px 10px',fontSize:'12px'}}
                                            id={"view_stack_button2_"+racer_racer_id} data-racerid={racer_racer_id}>
                                        View Stack
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id={"lineup_pred_stack_"+racer_racer_id} className="col s12" style={{padding:'5px 20px',display:statsopen}}>
                            <div className="row exr-trans-black-bg-subtle" style={{margin:'0px',padding:"10px",borderRadius:"10px",height:'auto',marginBottom:'0px'}}>
                                <div className="col s12 m8" style={{padding:'0px'}}>
                                    {tmp_pilot_visual}
                                    {tmp_racecraft_visual}
                                    {tmp_booster_visual}
                                </div>
                                <div className="col s12 m4" style={{padding:'10px 0px 0px 0px'}}>
                                    {score_html}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;

            
            
            if(viewSource == 'results') {
                section_html.push(tmp_html_results);
            } else {
                if(racer_racer_id == racerID){
                    section_html_final.push(tmp_html);
                } else {
                    section_html.push(tmp_html);
                }
            }
        }

        for(var m = 0; m < section_html.length;m++) {
            section_html_final.push(section_html[m]);
        }

        let predictDisplay = 'block';
        if((itemarr.length == 1)) {
            predictDisplay = 'none';
        } else if((raceDetails.open_invite_race == 1)&&(raceDetails.entry_window_over == 0)) {
            predictDisplay = 'none';
        } else {
            predictDisplay = 'block';
        }

        if(viewSource == 'results') {
            return section_html;
        } else {
            return section_html_final;
        }
    }

    function ordinal_suffix_of(i:number) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function viewPredRacerLinueupStack(e:any) {
        var tmp_racer_id = e.target.dataset.racerid;
        if($("#lineup_pred_stack_"+tmp_racer_id).is(":visible")) {
            $("#lineup_pred_stack_"+tmp_racer_id).slideUp();
            $("#view_stack_button_"+tmp_racer_id).html('View Stack');
            $("#view_stack_button2_"+tmp_racer_id).html('View Stack');
        } else {
            $("#lineup_pred_stack_"+tmp_racer_id).slideDown();
            $("#view_stack_button_"+tmp_racer_id).html('Hide Stack');
            $("#view_stack_button2_"+tmp_racer_id).html('Hide Stack');
        }
    }

    function togglePredictRacersRows(e:any) {
        var tmp_race_id = e.target.dataset.raceid;
        if($("#prediction_rows_"+tmp_race_id).is(":visible")) {
            $("#prediction_rows_"+tmp_race_id).slideUp();
            $("#predict_view_rows_button_"+tmp_race_id).html('VIEW RACERS');
        } else {
            $("#prediction_rows_"+tmp_race_id).slideDown();
            $("#predict_view_rows_button_"+tmp_race_id).html('HIDE RACERS');
        }
    }

    function openPredictRacerBox(e:any) {
        e.preventDefault();
        var pred_race_id = e.target.dataset.raceid;
        var pred_racer_id = e.target.dataset.racerid;
        var pred_participant_id = e.target.dataset.pracerid;
        var pred_finish = 1;
        var free_pred_amount = 0;
        var paid_pred_amount = 10;
        var racer_number = e.target.dataset.racernum;
        var racer_name = e.target.dataset.racername;
        var pilot_image = e.target.dataset.racerphoto;
        var pred_xp = e.target.dataset.racerxp;
        var win_chance = e.target.dataset.racerwinchance;

        var selected_prediction_html2 = <><div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div className="row exr-new-dark-grey" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                    <div className="col s12 m6" style={{position:'relative',zIndex:'10px'}}>
                                                    <div className="left" style={{width:"40%",maxWidth:'100px',lineHeight:'0.75'}}>
                                                        <img src={pilot_image} style={{width:'100%',borderRadius:'5px'}} />
                                                    </div>
                                                    <div className="left" style={{marginLeft:'0px',width:"47%",maxWidth:'200px',fontSize:'16px',fontFamily:"helvetica"}}>
                                                        <div className="center center-align headline-text" style={{width:'100%',fontSize:'24px',marginTop:'10px',marginBottom:'0px'}}>
                                                            {win_chance}%
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',marginTop:'-5px'}}>
                                                            WIN CHANCE
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',fontSize:'14px'}}>
                                                            Your Selection
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m6 subheadline-text" style={{position:'relative',zIndex:'10px',fontSize:'16px',fontFamily:"helvetica"}}>
                                                    <div className="row" style={{marginTop:'15px',marginBottom:'0px'}}>
                                                        <b>RACER {racer_number}:</b> {racer_name.toUpperCase()}
                                                    </div>
                                                    <div className="row" style={{width:'100%',fontSize:'14px',marginBottom:'0px'}}>
                                                        When we test ran this race over 500 times, this racer won {win_chance}% of the time vs all other racers.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div id="free-prediction-select-box" className="row exr-stats-light-grey predict-hover" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{fontSize:"14px",marginTop:"0px",padding:"5px 10px 0px 10px"}}>
                                                    <div className="left headline-text" style={{marginLeft:'5px'}}>FREE PREDICTIONS</div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    Play our free predictions game to build your reputation and earn XP for predicting winners.
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    <div className="col s6" style={{padding:"0px"}}>
                                                        <b>Prediction Ticket</b><br/>
                                                        Free 
                                                    </div>
                                                    <div className="col s6">
                                                        <b>Winner Rewards*</b><br/>
                                                        {pred_xp} XP
                                                    </div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px",display:"none"}}>
                                                    * Note: XP Amount determined by racers odds to win the race. 
                                                </div>
                                                <div className="col s12 right-align" style={{marginTop:'35px',marginBottom:'7px',padding:"0px 15px"}}>
                                                    <div className="col s6" style={{padding:"0px"}}>
                                                        <span className="left" style={{marginTop:'0px',lineHeight:'1'}}>
                                                            <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="20px"/>
                                                        </span>
                                                    </div>
                                                    <div className="col s6">
                                                        <div id="freepredcheckbutton" className="round-border-button white-base-blue-button-link left" style={{fontSize:'14px',marginTop:'-1px',display:'inline-block',height:'24px'}} 
                                                                title="Place Free Race Prediction" 
                                                                onClick={()=>addRacePrediction(pred_race_id, pred_racer_id, pred_participant_id, pred_finish, free_pred_amount)}>
                                                            PREDICT
                                                        </div>
                                                    </div>
                                                    <div id="freepredcheckloading" className="preloader-wrapper small active right splash-modal-loader" 
                                                            style={{marginTop:'-5px',height:"25px",width:"25px",marginRight:"10px"}}>
                                                        <div className="spinner-layer spinner-red-only">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="gap-patch">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="circle-clipper right">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div className="row" style={{position:'relative',margin:'0px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{marginTop:'5px',padding:"0px 15px",fontSize:'12px'}}>
                                                    * Note: XP rewards determined by the number of racers and the odds to win the race. Final paid winner rewards are set once all predictions are in. 
                                                </div>
                                            </div>
                                        </div></>;

        setSelectedPredictionHTML(selected_prediction_html2);

        var predictbox:any = document.getElementById('predictracerbox');
        var predict_instance = window.M.Modal.getInstance(predictbox);
        predict_instance.open();

        setTimeout(function(){
            var freepredboxheight:any = $("#free-prediction-select-box").outerHeight();
            var paidpredboxheight:any = $("#paid-prediction-select-box").outerHeight();
            if(freepredboxheight > paidpredboxheight) {
                $("#paid-prediction-select-box").css({'min-height':freepredboxheight+'px'});
            }else {
                $("#free-prediction-select-box").css({'height':paidpredboxheight+'px'});
            }
        },100);
    }

    function addRacePrediction(race_id:number, pred_racer_id:number, pred_participant_id:number, pred_finish:number, pred_amount:number) {
        var addData:any = new Object();
        addData.raceID = race_id;
        addData.userID = userID;
        addData.racerID = racerID;
        addData.predRacerID = pred_racer_id;
        addData.predParticipantID = pred_participant_id;
        addData.predFinish = pred_finish;
        addData.predAmount = pred_amount;

        $("#freepredcheckbutton").hide();
        $("#freepredcheckloading").show();
        axiosInstance.post('race/racer_predict/',addData).then(result => {
            grabUserWeeklyPredictions();
            setTimeout(function(){
                $("#freepredcheckbutton").show();
                $("#freepredcheckloading").hide();
                var predictbox:any = document.getElementById('predictracerbox');
                var predict_instance = window.M.Modal.getInstance(predictbox);
                predict_instance.close();
            },1500);
        }).catch(error => {
            console.log(error);
        });
    }

    function showStackInfoBox() {
        var sinfobox:any = document.getElementById('stackinfobox');
        var info_instance = window.M.Modal.getInstance(sinfobox);
        info_instance.open();
    }

    async function goToRaceLanding() {
        let redirect_link:any = await createRedirectToken(userID);
        if(redirect_link['status'] == 'new') {
            let redirect_token = redirect_link['token'];
            window.open("https://www.exiledracers.com/#/redirect?p=race/"+current_race_unique_id+"&"+redirect_token);
        } else {
            window.open("https://www.exiledracers.com/#/race/"+current_race_unique_id);
        }
    }

    function watchInitialRace(race_id:number) {
        if(userID) {
            var addData:any = new Object();
            addData.raceID = encodeURIComponent(race_id);
            addData.userID = encodeURIComponent(userID);
            axiosInstance.post('race/racer_watched/',addData).then(result => {
                
            }).catch(error => {
                console.log(error);
            });
        }
    }

    async function goToRaceView(e:any) {
        var tmp_race_id = e.target.dataset.raceid;
        var tmp_race_uniq_id = e.target.dataset.raceuniqid;
        var tmp_race_watched = e.target.dataset.racewatched;
        if(tmp_race_watched == 0) {
            watchInitialRace(tmp_race_id);
        }
        let redirect_link:any = await createRedirectToken(userID);
        if(redirect_link['status'] == 'new') {
            let redirect_token = redirect_link['token'];
            window.open("https://www.exiledracers.com/#/redirect?p=watch/"+tmp_race_uniq_id+"&"+redirect_token);
        } else {
            window.open("https://www.exiledracers.com/#/watch/"+tmp_race_uniq_id);
        }
    }

    async function skipToResults(e:any) {
        var tmp_race_id = e.target.dataset.raceid;
        var tmp_race_uniq_id = e.target.dataset.raceuniqid;
        var tmp_race_watched = e.target.dataset.racewatched;

        $("#skipbutton_"+tmp_race_id).hide();
        $("#skipbutton2_"+tmp_race_id).hide();
        $("#skiploading_"+tmp_race_id).show();
        $("#skiploading2_"+tmp_race_id).show();

        if(tmp_race_watched == 0) {
            watchInitialRace(tmp_race_id);
        }
        setTimeout(function() {
            grabUserWeeklyPredictions();
        },1000);
        
    }

    if((entrySet == false)||(prediction_raceday_id != props.raceday_id)) {
        setEntry(true);
        grabUserWeeklyPredictions();
    }

    return(<><div id="predict_race_section" className="row" style={{display:'none',padding:'0px',margin:'0px',marginBottom:'10px'}}>
            <div className="row" style={{marginTop:'0px',padding:'0px',display:'none'}}>
                    <div className="col s12">
                        <div className="left section-line-seperator white-text grey" style={{width:'10%',height:'2px'}}>
                        </div>
                    </div>
                </div>
            <div className="row white-text" style={{padding:'0px',margin:'0px',marginBottom:'40px',lineHeight:'1.5',fontSize:'16px',textTransform:'uppercase'}}>
                    Every week, you receive <span className="exr-base-blue-text">5 races</span> to predict on. Races are randomly assigned unless there are relevant races to you that week. Pick 
                    the racer you think will win each race for a chance to climb the Predictors Leaderboard for prizes.
            </div>
            <div className="row" style={{padding:'0px',margin:'0px',marginBottom:'10px'}}>
                {prediction_html}
            </div>
            <div id="predictracerbox" className="modal page-modal page-modal-large">
                <div className="modal-content" style={{paddingBottom:'5px'}}>
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>PREDICT RACE WINNER</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        {selected_prediction_html}
                    </div>
                </div>
            </div>
        </div>
        </>);
}