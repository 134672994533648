import * as THREE from "three";

export const effectGlow = new THREE.ShaderMaterial({
    // language=GLSL
    vertexShader: `
            out vec2 vUv;
            
            void main() {
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
                
                vUv = uv;
            }
            `,
    // language=GLSL
    fragmentShader: `
            #define GLOW_POWER .8
            #define CORE_RADIUS_POWER 1.7
            #define TRANSPARENCY .5
        
            uniform vec3 color;
            uniform float opacity;
            
            in vec2 vUv;
            
            void main() {
                vec2 delta = vUv - .5;
                float radius = length(delta);
                float alpha = 1. - radius - radius + pow(1. - radius - radius, CORE_RADIUS_POWER);

                gl_FragColor = vec4(mix(vec3(1.), color, pow(radius + radius, GLOW_POWER)), alpha * alpha * TRANSPARENCY * opacity);
            }
            `,
    uniforms: {
        color: new THREE.Uniform(new THREE.Color("#ffdb65")),
        opacity: {
            value: 1
        }
    },
    transparent: true,
    side: THREE.DoubleSide,
    blending: THREE.AdditiveBlending,
    depthWrite: false
});