/**
 * A track slice
 */
export class TrackSlice {
    /**
     * Construct a track slice
     * @param {Vector3} position The slice position
     * @param {number} direction The slice direction in radians
     */
    constructor(position, direction) {
        this.position = position;
        this.direction = direction;
    }

    /**
     * Transform this slice
     * @param {Vector3} position The position to shift to
     * @param {number} direction The direction to rotate to
     * @returns {TrackSlice} The modified track slice
     */
    transform(position, direction) {
        const c = Math.cos(direction);
        const s = Math.sin(direction);
        const x = this.position.x;
        const z = this.position.z;

        this.position.x = c * x - s * z + position.x;
        this.position.y += position.y;
        this.position.z = s * x + c * z + position.z;

        this.direction += direction;

        return this;
    }

    /**
     * Transform a vector by this slice position and rotation
     * @param {Vector3} vector The vector to transform
     * @returns {Vector3} The modified vector
     */
    transformVector(vector) {
        this.rotateVector(vector);

        vector.x += this.position.x;
        vector.y += this.position.y;
        vector.z += this.position.z;

        return vector;
    }

    /**
     * Rotate a vector by this slice rotation
     * @param {Vector3} vector The vector to rotate
     * @returns {Vector3} The modified vector
     */
    rotateVector(vector) {
        const c = Math.cos(this.direction);
        const s = Math.sin(this.direction);
        const x = vector.x;
        const z = vector.z;

        vector.x = c * x - s * z;
        vector.z = s * x + c * z;

        return vector;
    }
}