/**
 * A data loader
 */
export class DataLoader {
    /**
     * Construct a data loader
     * @param {string} url The query url
     * @param {string} key The query key
     * @param {string} id The id for this query
     */
    constructor(url, key, id, key2='p2', id2=0) {
        this.url = url;
        this.key = key;
        this.id = id;
        this.key2 = key2;
        this.id2 = id2;
        if (this.id2 == undefined) {
            this.id2 = 0;
        }
    }

    /**
     * Receive loaded data
     * @param {Object} data The loaded data
     */
    receiveData(data) {

    }

    /**
     * Load data
     * @returns {Promise} A promise
    //  */
    // load() {
    //     return new Promise((resolve, reject) => {
    //         const request = new XMLHttpRequest();

    //         request.overrideMimeType("text/plain");
    //         request.open("GET", this.url + "?" + this.key + "=" + this.id.toString(), true);
    //         request.onreadystatechange = () => {
    //             if (request.readyState === 4) {
    //                 if (request.responseText.length > 0) {
    //                     this.receiveData(JSON.parse(request.responseText));

    //                     resolve();
    //                 }
    //                 else
    //                     reject();
    //             }
    //         };

    //         request.send(null);
    //     });
    // }
    load() {
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();

            var dataq = new Object();
            dataq[this.key] = this.id.toString();
            dataq[this.key2] = this.id2.toString();
            
            request.open("POST", this.url, true);
            request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            request.onreadystatechange = () => {
                if (request.readyState === 4) {
                    if (request.responseText.length > 0) {
                        this.receiveData(JSON.parse(request.responseText));

                        resolve();
                    }
                    else
                        reject();
                }
            };
            request.send(JSON.stringify(dataq));
        });
    }
}