import {TrackPropOverride} from "./trackPropOverride.js";
import {effectStartLight} from "../../../../../../exr-webgl-hub/effects/2d/effectStartLight.js";
import * as THREE from "three";
import {EffectStats} from "../../../../effectStats.js";

export class TrackPropOverrideStartLight extends TrackPropOverride {
    static REPLACE = false;
    static NAME = "@startSignLights";

    material = effectStartLight.clone();

    constructor(mesh) {
        super();

        mesh.material = new THREE.MeshStandardMaterial({
            color: new THREE.Color("#2d2d2d"),
        });

        const clone = mesh.clone();

        clone.material = this.material;

        this.group.add(clone);
    }

    update() {
        this.material.uniforms.countdown.value = EffectStats.startCountdown;
    }
}