import * as THREE from "three";
import {trackPropOverrides} from "./overrides/trackPropOverrides.js";

/**
 * An instance of a track prop
 */
export class TrackPropInstance {
    /**
     * Construct a track prop instance
     */
    constructor() {
        this.objects = [];
        this.overrides = [];
        this.hasOverrides = false;
    }

    /**
     * Instantiate this instance
     * @param {Scene} parent The parent scene to add the prop to
     * @param {Scene} scene The scene containing the meshes
     * @param {Track} track The track
     * @param {number} trackWidth The track width
     * @param {string} sponsorName 
     */
    instantiate(parent, scene, track, trackWidth, sponsor) {
        const remove = [];
        
        scene.traverse((node) => {
            if (node instanceof THREE.Mesh) {
                const name = node.name.split("_")[0];

                if (node.name === "startSign" || node.name === "@startSignLights")
                    node.translateY(5);

                if (name[0] === "@") for (let override = 0, overrideCount = trackPropOverrides.length; override < overrideCount; ++override) {
                    if (trackPropOverrides[override].NAME === name) {
                        if (trackPropOverrides[override].REPLACE)
                            remove.push(node);
                            
                        const overrider = new trackPropOverrides[override](node, track, trackWidth, sponsor);

                        this.overrides.push(overrider);
                        this.hasOverrides = true;

                        scene.add(overrider.group);
                    }
                }
            }
        });

        for (const node of remove)
            scene.remove(node);
    }

    /**
     * Update the state
     */
    update() {
        if (this.hasOverrides)
            for (let override = 0, overrideCount = this.overrides.length; override < overrideCount; ++override)
                this.overrides[override].update();
    }

    /**
     * Render a frame
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        if (this.hasOverrides)
            for (let override = 0, overrideCount = this.overrides.length; override < overrideCount; ++override)
                this.overrides[override].render(time);
    }

    /**
     * Delete instantiated objects
     * @param {Scene} parent The parent scene this instance was instantiated on
     */
    delete(parent) {
        for (const object of this.objects)
            parent.remove(object);

        this.objects.length = 0;
    }
}