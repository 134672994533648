import * as THREE from "three";

export class TrackPropOverride {
    group = new THREE.Group();

    constructor(mesh) {

    }

    update() {

    }

    render(time) {

    }
}