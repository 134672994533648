import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js";
import {GlobalCache} from "./globalCache.js";
import {LoadTracker} from "../loadTracker.js";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader.js";

export class ModelCache extends GlobalCache {
    /**
     * Construct the model cache
     */
    constructor() {
        super();

        const draco = new DRACOLoader();

        draco.setDecoderPath("./js/draco/");

        this.loader = new GLTFLoader();
        this.loader.setDRACOLoader(draco);
    }

    /**
     * Load an asset from a server
     * @param {string} asset The asset url
     * @param {function} onLoaded A function that returns with the loaded asset after loading completes
     */
    loadFromServer(asset, onLoaded) {
        LoadTracker.register();

        this.loader.load(asset, loaded => {
            onLoaded(loaded);

            LoadTracker.resolve();
        });
    }
}