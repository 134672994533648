import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, ethers } from 'ethers'

import {addEventUserAction, saveRacerHandle} from "../../../../../utils/signon";
import {axiosInstance } from "../../../../../utils";

import $ from "jquery";

let current_user_id = 0; 
let current_racer_status = 'predictor';
let signup_racer = 'spectator';
let start_handle = '';
let confirm_handle = 0;
let confirm_pass = 0;
let confirm_disclaimer = 0;
let confirm_reveal = 0;

// PROPS: racername, racerstatus, exreventid, moveToNext
export const BasePreMintSetup = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state:any) => state.account);

    const [pageSet, setPage] = useState(false);
    const [racer_name, setRacerName] = useState('user123');
    const [racer_status,setRacerStatus] = useState('predictor');
    const [racer_action_status,setRacerActionStatus] = useState('predict');

    /* EXR Event ID */
    let exreventid = props.exreventid;

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Black_Ship_Splash_Background_1704920753949.png';
    let bg_image_ship = 'https://media.exiledracers.com/assets/game/builder/Ship_Bottom_Background_1704950678438.png';

    let bg_primary_splash = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Primary_Splash_Background_1709765585438.png';
    let bg_image_left = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Left_1709765609425.png';
    let bg_image_right = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Right_1709765624764.png';
    

    function startPremint() {
        start_handle = props.racername;
        current_racer_status = props.racerstatus;

        setRacerName(start_handle);
        setRacerStatus(current_racer_status);

        if (current_racer_status == 'racer') {
            setRacerActionStatus('race');
            $("#welcome-reveal-racer").show();
            $("#welcome-reveal-predictor").hide();
        } else {
            setRacerActionStatus('predict');
            $("#welcome-reveal-racer").hide();
            $("#welcome-reveal-predictor").show();
            $("#race-weekly-inner-nav").hide();
        }

        setTimeout(function(){
            $("#leagueRacerHandle").val(start_handle);
            $("#leagueRacerHandle2").val(start_handle);
        },1000);        
    }

    async function confirmRacerHandle(usertype:string) {
        $("#handle-confirm-button").hide();
        $("#handle-confirm-loading").show();
        $("#handle-confirm-button2").hide();
        $("#handle-confirm-loading2").show();
        var racerhandle = (document.getElementById("leagueRacerHandle") as HTMLInputElement).value;
        if(usertype == 'predictor') {
            racerhandle = (document.getElementById("leagueRacerHandle2") as HTMLInputElement).value;
        }

        var moveforward = 0;
        if(start_handle != racerhandle) {
            let handle_response = await saveRacerHandle(userID,start_handle,racerhandle);
            if(handle_response.indexOf('error') >= 0) {
                moveforward = 0;
            } else {
                moveforward = 1;
            }
        } else {
            moveforward = 1;
        }
        
        if(moveforward == 1) {
            let actiontype = 'racer_name';
            let actionid = '';
            let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);

            setTimeout(function() {
                $("#handle-confirm-button").hide();
                $("#handle-confirm-loading").hide();
                $("#handle-confirm-button2").hide();
                $("#handle-confirm-loading2").hide();
                $("#event-landing-page-3b").show();
                $("html, body").animate({ scrollTop: $("#event-landing-page-3b").offset()?.top }, 1000);
            },3000);
            setTimeout(function() {
                $("#event-landing-page-2b").hide();
            },5000);
        } else {
            $("#handle-confirm-button").show();
            $("#handle-confirm-loading").hide();
            $("#handle-confirm-button2").show();
            $("#handle-confirm-loading2").hide();
        } 
    }

    async function confirmLeagueDisclaimer() {
        $("#disclaimer-confirm-button").hide();
        $("#disclaimer-confirm-loading").show();
        let actiontype = 'disclaimer';
        let actionid = '';
        let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);

        setTimeout(function() {
            $("#disclaimer-confirm-button").hide();
            $("#disclaimer-confirm-loading").hide();
            disclaimerConfirmed();
        },3000);
    }

    function disclaimerConfirmed() {
        props.moveToNext();
    }

    if(pageSet === false) {
        setPage(true);
        startPremint();
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto',overflowY:'hidden'}}>
        <div className="row" id="event-landing-page-2b" 
            style={{position:'relative',background:'black',width:'100%',minHeight:'100vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_primary_splash+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        CONGRATS {racer_name.toUpperCase()}
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        YOU'RE REGISTERED TO <span className="exr-base-blue-text">{racer_action_status.toUpperCase()}.</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row" id="welcome-reveal-racer" style={{display:'block'}}>
                            <div className="col s12 l5" style={{margin:'0px',padding:'0px 20px'}}>
                                <div className="row subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'0px',fontSize:'20px',marginTop:'10px',marginBottom:'40px'}}>
                                    Race week is here! Finish setting up your Base League Racing details and reveal your weekly random single use racing stacks to get racing.
                                </div>
                                <div className="row" style={{marginTop:'40px',padding:'0px 0px'}}>
                                    <div className="left section-line-seperator white-text white" style={{width:'60%',height:'3px'}}>
                                    </div>
                                </div>
                                <div className="row subheadline-text event-register-subheadline exr-base-blue-text" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'16px',marginTop:'40px',marginBottom:'0px'}}>
                                    GET STARTED
                                </div>
                                <div className="row headline-text event-register-subheadline white-text" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'24px',marginTop:'5px',marginBottom:'0px'}}>
                                    SET YOUR RACER NAME
                                </div>
                                
                                <div className="row subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'20px',marginTop:'20px',marginBottom:'40px'}}>
                                    What name do you want to show across races and leaderboards.
                                </div>
                                <div id="confirm-handle-form" className="row" style={{marginBottom:'20px'}}>
                                    <div className="col s12 m10 subheadline-text event-register-subheadline" style={{padding:'0px',marginBottom:'20px'}}>
                                        <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Confirm Your Racing Handle">
                                            <input id="leagueRacerHandle" className="black-bubble-input-round" 
                                                style={{width:'100%',fontSize:'20px',borderColor:'black'}} />
                                        </span>
                                        <span id="handle-confirm-button"  className="round-border-button right black white-text base-blue-background" 
                                            style={{marginTop:'40px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                            title="Save Your Racer Name" onClick={()=>confirmRacerHandle('racer')}>
                                            <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                                expand_circle_right
                                            </span>
                                            <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                                CONTINUE
                                            </span>
                                        </span>
                                        <span id="handle-confirm-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                            style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-3b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'100vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'40px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'18px',letterSpacing:'3px'}}>
                        GET STARTED // DISCLAIMER
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'30px'}}>
                        BASE RACING LEAGUE TERMS & RULES
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',marginBottom:'50px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m9 offset-m1 white-text event-register-subheadline" style={{fontSize:'18px',lineHeight:'1.5'}}>
                    This is a skills based, play to enjoy game.
                    Our platform is created for genuine enjoyment. Have a good time, and consider any rewards if any—as a little bonus recognition from us and our partners for your participation.
                    <br/><br/>
                    <span className="headline-text exr-base-blue-text">PLATFORM UPDATES</span><br/>
                    We might introduce changes to the platform, such as new games or features. Adapt to these changes like you would to a new twist in your favorite game, and enjoy the evolving experience.

                    <br/><br/>
                    <span className="headline-text exr-base-blue-text">PARTNER PRIZE POOLS</span><br/>
                    Partner prize pools are independently owned and managed by our partners. Participating in these pools is entirely optional, and users are encouraged to conduct their own research (DYOR) before participating. We are not financial advisors (NFA), and users should make informed decisions regarding their involvement in partner-sponsored prize pools.
                    <br/><br/>
                    <span className="headline-text exr-base-blue-text">EXR PLATFORM</span><br/>
                    EXR is a fun, engagement gaming platform and not directly affiliated with our partners. Our partners use our platform to enhance community engagement and enjoyment. Participation in partner activities is optional and designed to add depth to the overall gaming experience (DYOR).

                    </div>
                </div>
                <div className="row center-align" style={{marginTop:'50px',marginBottom:'100px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m8 offset-m2 l4 offset-l4 white-text center-align">
                        <div style={{width:'100%',position:'relative'}}>
                            <span id="disclaimer-confirm-button" className="black white-text base-blue-background" 
                                style={{display:'block',cursor:'pointer',maxWidth:'500px',marginTop:'50px',marginLeft:'5%',marginRight:'0px',width:'90%',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                title="Agree To League Rules & Terms To Continue"
                                onClick={()=> confirmLeagueDisclaimer()}>
                                <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                    expand_circle_right
                                </span>
                                <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                I AGREE & CONTINUE
                                </span>
                            </span>
                            <span id="disclaimer-confirm-loading" className="preloader-wrapper small active event-register-formbutton center" 
                                style={{display:'none',position:'absolute',top:'0px',left:'45%',height:'50px',width:'50px',marginTop:'23px'}}>
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
    </div>);
};