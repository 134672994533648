import * as THREE from "three";
import {TrackPropInstance} from "./trackPropInstance.js";

/**
 * An instantiation of a track prop
 */
export class TrackPropInstanceSingle extends TrackPropInstance {
    /**
     * Construct a track prop instance
     * @param {Vector3} position The prop position
     * @param {Vector3} rotation The prop rotation in euler angles
     * @param {Vector3} [rotationSpeed] The rotation speed
     */
    constructor(position, rotation, rotationSpeed = null) {
        super();

        this.position = position;
        this.rotation = rotation;
        this.euler = new THREE.Euler(rotation.x, rotation.y, rotation.z, "YXZ");
        this.eulerCurrent = this.euler.clone();
        this.rotationSpeed = rotationSpeed;
    }

    /**
     * Update the state
     */
    update() {
        super.update();

        if (this.rotationSpeed) {
            if ((this.euler.x += this.rotationSpeed.x) > Math.PI * 2)
                this.euler.x -= Math.PI * 2;
            else if (this.euler.x < 0)
                this.euler.x += Math.PI * 2;

            if ((this.euler.y += this.rotationSpeed.y) > Math.PI * 2)
                this.euler.y -= Math.PI * 2;
            else if (this.euler.y < 0)
                this.euler.y += Math.PI * 2;

            if ((this.euler.z += this.rotationSpeed.z) > Math.PI * 2)
                this.euler.z -= Math.PI * 2;
            else if (this.euler.z < 0)
                this.euler.z += Math.PI * 2;
        }
    }

    /**
     * Render a frame
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        super.render(time);

        if (this.rotationSpeed) {
            this.eulerCurrent.x = this.euler.x + this.rotationSpeed.x * time;
            this.eulerCurrent.y = this.euler.y + this.rotationSpeed.y * time;
            this.eulerCurrent.z = this.euler.z + this.rotationSpeed.z * time;

            for (let object = 0, objectCount = this.objects.length; object < objectCount; ++object)
                this.objects[object].quaternion.setFromEuler(this.eulerCurrent);
        }
    }

    /**
     * Instantiate this instance
     * @param {Scene} parent The parent scene to add the prop to
     * @param {Scene} scene The scene containing the meshes
     * @param {Track} track The track
     * @param {number} trackWidth The track width
     */
    instantiate(parent, scene, track, trackWidth) {
        const copy = scene.clone();

        super.instantiate(parent, copy, track, trackWidth);

        this.objects.push(copy);

        copy.position.copy(this.position);
        copy.quaternion.setFromEuler(this.euler);
        copy.matrixWorldNeedsUpdate = true;

        parent.add(copy);
    }
}