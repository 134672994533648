import {TrackPropOverride} from "./trackPropOverride.js";
import {effectForceField} from "../../../../../../exr-webgl-hub/effects/2d/effectForceField.js";

export class TrackPropOverrideForceField extends TrackPropOverride {
    static REPLACE = true;
    static NAME = "@portalForcefield";
    static SPEED = .0005;

    material = effectForceField.clone();
    time = 0;

    constructor(mesh) {
        super(mesh);

        mesh.material = this.material;

        this.group.add(mesh);
    }

    update() {
        this.time += TrackPropOverrideForceField.SPEED;
    }

    render(time) {
        this.material.uniforms.time.value = this.time + time * TrackPropOverrideForceField.SPEED;
    }
}