import * as THREE from "three";
import {GlobalCache} from "./globalCache.js";
import {LoadTracker} from "../loadTracker.js";

export class TextureCache extends GlobalCache {
    /**
     * Construct the texture cache
     */
    constructor() {
        super();

        this.loader = new THREE.TextureLoader();
    }

    /**
     * Load an asset from a server
     * @param {string} asset The asset url
     * @param {function} onLoaded A function that returns with the loaded asset after loading completes
     */
    loadFromServer(asset, onLoaded) {
        LoadTracker.register();

        this.loader.load(asset, texture => {
            texture.colorSpace = THREE.SRGBColorSpace;

            onLoaded(texture);

            LoadTracker.resolve();
        });
    }
}