import {Globals} from "../../global/globals.js";

export class Banners {
    static URLS = [
        "assets/banners/EXR_Logo_Mark_White.png",
        "assets/banners/EXR_Logo_Stacked_White.png",
        "assets/banners/moonbeam_logo_full.png",
        "assets/banners/moonbeam_logo_icon.png",
        "assets/banners/polkadot_logo_full.png",
        "assets/banners/polkadot_logo_icon.png",
        "assets/banners/zeitgeist_logo_short.png",
        "assets/banners/zeitgeist_logo_icon_white.png"
    ]

    static URLS2 = [
        "assets/banners/EXR_Logo_Mark_White.png",
        "assets/banners/EXR_Logo_Stacked_White.png",
        "assets/banners/Pink_Logo_Icon_1704949451078.png",
        "assets/banners/Bifrost_Color_Icon_1704934332216.png",
        "assets/banners/Bifrost_Logo_White_1704918173238.png",
        "assets/banners/Pink_Logo_Icon_1704949451078.png",
    ]

    static requests = [];

    /**
     * Request a banner URL
     * @returns {string} A banner URL
     */
    static requestURL(sponsor) {
        console.log(sponsor);
        if(sponsor == 'pink') {
            return Banners.URLS2[Math.floor(Math.random() * Banners.URLS2.length)];
        } else {
            return Banners.URLS[Math.floor(Math.random() * Banners.URLS.length)];
        }
    }

    /**
     * Request a banner
     * @param {number} aspect The aspect ratio
     * @returns {Promise<THREE.Texture>} A promise that returns a texture
     */
    static requestBanner(aspect = 1, sponsor='basic') {
        return Globals.TEXTURE_CACHE.load(Banners.requestURL(sponsor));
    }
}