import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance } from "../../../../../utils";
import {estimateMintGasFeeTotal} from "../../../../../utils/passport";
import {addEventUserAction} from "../../../../../utils/signon";
import $ from "jquery";

// PROPS: racername, racerstatus, exreventid, moveToNext
export const BaseMintPasses = (props:any) => {
    const { racerID, userID, accountAddress } = useSelector((state:any) => state.account);

    const [pageSet, setPage] = useState(false);
    const [minting_link, setMintingLink] = useState('');

    let exreventid = props.exreventid;

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Black_Ship_Splash_Background_1704920753949.png';
    let bg_image_ship = 'https://media.exiledracers.com/assets/game/builder/Ship_Bottom_Background_1704950678438.png';
    let bg_primary_splash = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Primary_Splash_Background_1709765585438.png';
    let bg_image_left = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Left_1709765609425.png';
    let bg_image_right = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Right_1709765624764.png';
    let bg_blue_smoke = 'https://media.exiledracers.com/assets/game/builder/Base_Blue_Smoke_Background_1712349198139.png';

    let moonbeam_logo = 'https://media.exiledracers.com/assets/game/builder/Moonbeam_White_Icon_1693419281943.png';
    let moonbeam_logo_full = 'https://media.exiledracers.com/assets/game/builder/Moonbeam_Logo_White_1704934123621.png';
    let exr_wordmark = 'https://media.exiledracers.com/assets/game/builder/EXR_Wordmark_Long_1693420169042.png';
    
    let base_full_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Base_Wordmark_Logo_Blue_1709766845568.png';
    let base_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Base_Icon_Logo_Blue_1709766897123.png';
    let base_network_logo = 'https://media.exiledracers.com/assets/game/builder/Base_Network_Logo_1709767498507.png';
    let base_full_logo_white = 'https://media.exiledracers.com/assets/game/builder/Base_Wordmark_Logo_White_1709766877696.png';
    let base_logo_white = 'https://media.exiledracers.com/assets/game/builder/Base_Icon_Logo_White_1709766912123.png';

    let axelar_full_logo_white = 'https://media.exiledracers.com/assets/game/builder/Axelar_Wordmark_Logo_White_1709767280477.png';
    let axelar_full_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Axelar_Wordmark_Logo_Blue_1709767264248.png';
    let axelar_logo_white = 'https://media.exiledracers.com/assets/game/builder/Axelar_Icon_Logo_White_1709767305636.png';
    let axelar_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Axelar_Icon_Logo_Blue_1709767292192.png';

    async function confirmMintPasses() {
        
        
        let walletAddress = accountAddress;
        //walletAddress = '0x2D5dde83495327A61763260288F9507bfa5D733B';
        let xchainID = '8453';

        let minterUserID = 1;
        if(userID > 0) {
            minterUserID = userID;
        }

        //var racer_wallet = (document.getElementById("racerWalletAddress") as HTMLInputElement).value.trim();
        // walletAddress = '0x82998Ed7e475Ef3920749d1788dEab8Ca20e2998';
        // walletAddress = '0x0babB92394d82313a84602B9f550838BBaF54E74';
        // walletAddress = racer_wallet;
        // xchainID = '84532';

        $("#mint-confirm-button").hide();
        $("#mint-confirm-input1").hide();
        $("#mint-confirm-input2").hide();
        $("#mint-confirm-txn").fadeIn(2000);
        $("#mint-confirm-loading").show();

        //let gasEstimate = await estimateMintGasFeeTotal(walletAddress,xchainID);
        // let gasEstimate = "154217208579571";
        // gasEstimate = "204217208579571";
        let gasEstimate = "2000000000000000";

        var addData:any = new Object();
        addData.userID = encodeURIComponent(minterUserID);
        addData.walletID = encodeURIComponent(walletAddress);
        addData.gas = encodeURIComponent(gasEstimate);

        // setTimeout(function() {
        //     $("#pass-mint-1").fadeOut();
        // },3000);
        // setTimeout(function() {
        //     $("#pass-mint-2").fadeIn();
        // },5000);

        if(walletAddress.length > 0) {
            axiosInstance.post('mint/xchain_passport/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var mint_info = result.data[0];
                    if (mint_info['status'] == 'success') {
                        let mint_hash = mint_info['receipt'];
                        let final_hash_link = 'https://testnet.axelarscan.io/gmp/'+mint_hash;
                        if (xchainID == '8453') {
                            final_hash_link = 'https://axelarscan.io/gmp/'+mint_hash;
                        }
                        setMintingLink(final_hash_link);

                        confirmRacerPass();
    
                        setTimeout(function() {
                            $("#pass-mint-1").fadeOut();
                        },3000);
                        setTimeout(function() {
                            $("#pass-mint-2").fadeIn();
                        },5000);
                    } else {
                        $("#mint-confirm-loading").hide();
                        $("#mint-confirm-txn").hide();
                        $("#mint-confirm-input1").show();
                        $("#mint-confirm-input2").show();
                        $("#mint-confirm-button").show();
                        alert('Something Went Wrong During The Mint. Try Again.');
                    }
                } else {
                    $("#mint-confirm-loading").hide();
                    $("#mint-confirm-txn").hide();
                    $("#mint-confirm-input1").show();
                    $("#mint-confirm-input2").show();
                    $("#mint-confirm-button").show();
                    alert('Something Went Wrong. Try Again.');
                }
            }).catch(error => {
                console.log(error);
            });
        } else {
            $("#mint-confirm-loading").hide();
            $("#mint-confirm-txn").hide();
            $("#mint-confirm-input1").show();
            $("#mint-confirm-input2").show();
            $("#mint-confirm-button").show();
            alert('Add Wallet Address');
        }
        
    }

    function continueMintStep(stepnum:number) {
        $("#mint-confirm-button"+stepnum).hide();
        $("#mint-confirm-loading"+stepnum).show();
        if(stepnum == 4) {
            setTimeout(function() {
                $("#pass-mint-"+stepnum).fadeOut(2000);
                mintingConfirmed();
            },1000);
        } else {
            setTimeout(function() {
                $("#pass-mint-"+stepnum).fadeOut(2000);
            },1000);
            setTimeout(function() {
                $("#pass-mint-"+(stepnum+1)).fadeIn(2000);
            },3000); 
        }
    }

    function startMintVideo() {
        $("#mint-confirm-button3").hide();
        $("#mint-confirm-loading3").show();
        setTimeout(function() {
            $("#pass-mint-3").fadeOut(2000);
        },1000);
        setTimeout(function() {
            $("#pass-mint-4").fadeIn(2000);
            const mintVideo = document.getElementById('mint-reveal-video') as HTMLVideoElement;
            if(mintVideo) {
                mintVideo.play();
            }
        },3000);
        setTimeout(function() {
            $("#mint-reveal-video-text").fadeIn(2000);
        },47000);

        // setTimeout(function() {
        //     const mintVideo = document.getElementById('mint-reveal-video') as HTMLVideoElement;
        //     if(mintVideo) {
        //         mintVideo.pause();
        //     }
        // },63000);
    }

    async function confirmRacerPass() {
        let actiontype = 'access_pass';
        let actionid = '';
        
        let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);
    }

    function mintingConfirmed() {
        props.moveToNext();
    }

    function scrollToLearnMore() {
        $('html, body').animate({
            scrollTop: $("#how-works-page-1").offset()?.top
         }, 1000);
    }

    if(pageSet === false) {
        setPage(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div  id="event-landing-page-3c"  className="passmint" style={{display:'none',position:'relative',width:'100%',minHeight:'100vh'}}>
        <div className="passmint" style={{width:'100%',height:'100vh',position:'fixed',top:'0px',left:'0px',backgroundImage:'url('+bg_image_left+')',backgroundSize:'cover',zIndex:'-1000'}}>
        </div>
        <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',height:'100vh',position:'fixed',top:'0px',left:'0px'}}>
        </div>
        <div className="row passmint" id="pass-mint-1" 
            style={{position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px',height:'100vh'}}>
            
            <div className="col s12 l6 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'70px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'20px',letterSpacing:'3px'}}>
                        YOU'RE ALMOST READY TO PLAY
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'75%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px',lineHeight:'1.15'}}>
                        MINT & CLAIM <br/>TO RACE
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text white-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.25',paddingRight:'20px',fontSize:'18px'}}>
                            Mint & CLAIM YOUR FREE BASE RACE PASS AND EXR PASSPORT NFT’S to START RACING in THE FIRST INTER-chain RACING LEAGUE.     
                        <br/><br/>   
                            PASSES ARE FREE TO MINT + SOULBOUND TO YOUR RACER WALLET.
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <span className="left">
                            <img src={base_logo_white} height="28px" style={{marginLeft:'15px'}} />
                        </span>
                        <span className="left" style={{margin:'15px 10px 0px 10px',height:'1px',width:'50px',borderTop:'1px dashed white'}}>
                        </span>
                        <span className="left">
                            <img src={axelar_full_logo_white} height="25px" />
                        </span>
                        <span className="left" style={{margin:'15px 10px 0px 10px',height:'1px',width:'50px',borderTop:'1px dashed white'}}>
                        </span>
                        <span className="left">
                            <img src={moonbeam_logo} height="35px" style={{marginTop:'-5px'}} />
                        </span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'70%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div id="mint-confirm-txn" className="row" style={{display:'none',marginTop:'-10px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'20px',letterSpacing:'2px'}}>
                        SENDING MINT TRANSACTION...
                    </div>
                </div>
                <div id="mint-confirm-form" className="row" style={{marginTop:'50px',marginBottom:'0px',padding:'0px 20px'}}>
                    
                    <div className="col s12 m10 offset-m1 subheadline-text event-register-subheadline" style={{padding:'0px',marginBottom:'20px'}}>
                        {/* <span id="mint-confirm-input1" className="left headline-text event-register-formheader white-text" 
                                    style={{width:'100%',marginLeft:'0px',paddingLeft:'10px',marginTop:'0px'}}>
                                    TEST WALLET ADDRESS
                        </span>
                        <span id="mint-confirm-input2" className="event-register-subheadline" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Test Wallet">
                                <input id="racerWalletAddress" className="black-bubble-input-round" 
                                        style={{fontSize:'20px',marginLeft:'0px',margin:'0px',marginRight:'-15px',marginBottom:'20px',width:'90%'}} />
                                <br/>
                        </span> */}

                        <span id="mint-confirm-input1" className="left headline-text event-register-formheader white-text" 
                                    style={{width:'100%',marginLeft:'0px',paddingLeft:'10px',marginTop:'0px'}}>
                                    MINTING TO WALLET:
                        </span>
                        <span id="mint-confirm-input2" className="event-register-subheadline white-text" style={{paddingLeft:'10px',width:'100%',fontSize:'20px',marginTop:'0px',marginBottom:'20px'}} title="Enter Test Wallet">
                                {accountAddress.slice(0,8)+'...'+accountAddress.slice(-12)}
                                <br/><br/>
                        </span>
                        <span id="mint-confirm-button" className="white-text base-blue-background event-register-subheadline" 
                            style={{cursor:'pointer',marginTop:'40px',marginLeft:'5px',marginRight:'0px',width:'auto',fontSize:'20px',borderRadius:'25px',padding:'16px 20px 12px 30px',height:'48px',position:'relative'}} 
                            title="Save Your Racer Name" onClick={()=>confirmMintPasses()}>
                            <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'42px'}}>
                                expand_circle_right
                            </span>
                            <span className="subheadline-text-mono" style={{margin:"2px 80px 0px 80px",fontSize:'24px',letterSpacing:'1px'}}>
                                MINT NOW
                            </span>
                        </span>
                        <span id="mint-confirm-loading" className="preloader-wrapper small active event-register-formbutton left" 
                            style={{display:'none',height:'30px',width:'30px',marginTop:'40px',marginLeft:'150px'}}>
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col s12 l6 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Base_Race_Pass_Splash_1715208941256.png" 
                                    width="100%" style={{borderRadius:'10px',maxWidth:'600px'}} />
                </div>
            </div>
            <div className="col s12" style={{marginTop:'20px',marginBottom:'30px'}}>
                    &nbsp;
            </div>
        </div>

        <div className="row passmint" id="pass-mint-2" 
            style={{display:'none',position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px',height:'100vh'}}>
            <div className="col s12 l6 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'70px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <span className="left">
                            <img src={base_logo_white} height="28px" style={{marginLeft:'10px'}} />
                        </span>
                        <span className="left" style={{margin:'15px 10px 0px 10px',height:'1px',width:'50px',borderTop:'1px dashed white'}}>
                        </span>
                        <span className="left">
                            <img src={axelar_full_logo_white} height="25px" />
                        </span>
                        <span className="left" style={{margin:'15px 10px 0px 10px',height:'1px',width:'50px',borderTop:'1px dashed white'}}>
                        </span>
                        <span className="left">
                            <img src={moonbeam_logo} height="35px" style={{marginTop:'-5px'}} />
                        </span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'-10px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'70%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'-10px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'20px',letterSpacing:'2px'}}>
                        YOUR PASSES ARE MINTING&nbsp;
                        <a className="exr-base-blue-light-text" href={minting_link} target="_blank" 
                            style={{cursor:'pointer',textDecoration:'none',textUnderlineOffset:'5px'}}>HERE</a>
                    </div>
                </div>

                <div className="row" style={{marginTop:'60px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px',lineHeight:'1.15'}}>
                        YOUR BASE<br/>RACING PASS
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text white-text" style={{textTransform:'uppercase',lineHeight:'1.25',paddingLeft:'10px',fontSize:'16px',letterSpacing:'1px'}}>
                            &#8226;&nbsp;&nbsp;BASE SOULBOUND NFT VIEWABLE ON EXR AND NFT MARKETPLACES
                            <br/><br/>
                            &#8226;&nbsp;&nbsp;DYNAMICALLY UPDATES WITH YOUR RACE DATA AFTER RACES FINISH
                            <br/><br/>
                            &#8226;&nbsp;&nbsp;CONTROLLED BY YOUR MOONBEAM PASSPORT NFT VIA AXELAR 
                            <br/><br/>
                            &#8226;&nbsp;&nbsp;UNLOCKS ACCESS TO SINGLE USE HAVOC RACING AND EXR PRIZE POOLS
                        </div>
                    </div>
                </div>
                <div id="mint-confirm-form2" className="row" style={{marginTop:'50px',marginBottom:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 subheadline-text event-register-subheadline" style={{padding:'0px',marginBottom:'20px'}}>
                        <span id="mint-confirm-button2" className="white-text base-blue-background event-register-subheadline" 
                            style={{cursor:'pointer',marginTop:'40px',marginLeft:'5px',marginRight:'0px',width:'auto',fontSize:'20px',borderRadius:'25px',padding:'16px 20px 12px 30px',height:'48px',position:'relative'}} 
                            title="Continue" onClick={()=>continueMintStep(2)}>
                            <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'42px'}}>
                                expand_circle_right
                            </span>
                            <span className="subheadline-text-mono" style={{margin:"2px 80px 0px 80px",fontSize:'24px',letterSpacing:'1px'}}>
                                CONTINUE
                            </span>
                        </span>
                        <span id="mint-confirm-loading2" className="preloader-wrapper small active event-register-formbutton left" 
                            style={{display:'none',height:'30px',width:'30px',marginTop:'20px',marginLeft:'150px'}}>
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col s12 l6 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Base_Race_Pass_Splash_1715208941256.png" 
                                    width="100%" style={{borderRadius:'10px',maxWidth:'600px'}} />
                </div>
            </div>
            <div className="col s12" style={{marginTop:'20px',marginBottom:'50px'}}>
                    &nbsp;
            </div>
        </div>

        <div className="row passmint" id="pass-mint-3" 
            style={{display:'none',position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px',height:'100vh'}}>
            <div className="col s12 l6 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'70px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <span className="left">
                            <img src={base_logo_white} height="28px" style={{marginLeft:'10px'}} />
                        </span>
                        <span className="left" style={{margin:'15px 10px 0px 10px',height:'1px',width:'50px',borderTop:'1px dashed white'}}>
                        </span>
                        <span className="left">
                            <img src={axelar_full_logo_white} height="25px" />
                        </span>
                        <span className="left" style={{margin:'15px 10px 0px 10px',height:'1px',width:'50px',borderTop:'1px dashed white'}}>
                        </span>
                        <span className="left">
                            <img src={moonbeam_logo} height="35px" style={{marginTop:'-5px'}} />
                        </span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'-10px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'70%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'-10px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'20px',letterSpacing:'2px'}}>
                        YOUR PASSES ARE MINTING&nbsp;
                        <a className="exr-base-blue-light-text" href={minting_link} target="_blank" 
                            style={{cursor:'pointer',textDecoration:'none',textUnderlineOffset:'5px'}}>HERE</a>
                    </div>
                </div>

                <div className="row" style={{marginTop:'60px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px',lineHeight:'1.15'}}>
                        YOUR EXR<br/> 
                        INTER-CHAIN<br/>
                        PASSPORT
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text white-text" style={{textTransform:'uppercase',lineHeight:'1.25',paddingLeft:'10px',fontSize:'18px'}}>
                            &#8226;&nbsp;&nbsp;MOONBEAM SOULBOUND NFT
                            <br/><br/>
                            &#8226;&nbsp;&nbsp;CONTROLS YOUR XCHAIN BASE RACE PASS NFT VIA AXELAR
                            <br/><br/>
                            &#8226;&nbsp;&nbsp;GIVES YOU ACCESS TO FUTURE INTER CHAIN LEAGUES
                        </div>
                    </div>
                </div>
                <div id="mint-confirm-form3" className="row" style={{marginTop:'50px',marginBottom:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 subheadline-text event-register-subheadline" style={{padding:'0px',marginBottom:'20px'}}>
                        <span id="mint-confirm-button3" className="white-text base-blue-background event-register-subheadline" 
                            style={{cursor:'pointer',marginTop:'40px',marginLeft:'5px',marginRight:'0px',width:'auto',fontSize:'20px',borderRadius:'25px',padding:'16px 20px 12px 30px',height:'48px',position:'relative'}} 
                            title="Continue" onClick={()=>startMintVideo()}>
                            <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'42px'}}>
                                expand_circle_right
                            </span>
                            <span className="subheadline-text-mono" style={{margin:"2px 80px 0px 80px",fontSize:'24px',letterSpacing:'1px'}}>
                                CONTINUE
                            </span>
                        </span>
                        <span id="mint-confirm-loading3" className="preloader-wrapper small active event-register-formbutton left" 
                            style={{display:'none',height:'30px',width:'30px',marginTop:'20px',marginLeft:'150px'}}>
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col s12 l6 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Base_Passport_Splash_1715209671639.png" 
                                    width="100%" style={{borderRadius:'10px',maxWidth:'600px'}} />
                </div>
            </div>
            <div className="col s12" style={{marginTop:'20px',marginBottom:'50px'}}>
                    &nbsp;
            </div>
        </div>

        <div className="row passmint" id="pass-mint-4" 
            style={{display:'none',position:'relative',background:'black',width:'100%',marginBottom:'0px',lineHeight:'0',minHeight:'100vh',height:'100%'}}>
            <div className="row center-align" style={{paddingTop:'0px',paddingBottom:'0px',marginBottom:'0px'}}>
                <video id="mint-reveal-video" disableRemotePlayback src="https://media.exiledracers.com/assets/game/builder/xChain_Splash_Reveal_Base_1715370475070.mp4" width="100%" style={{marginBottom:'0px'}} />
            </div>
            <div className="col s12 l8 transparent" id="mint-reveal-video-text" style={{display:'none',position:'absolute',zIndex:'100',top:'100px',left:'0px'}}>
                <div className="row" style={{marginTop:'10vh',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'20px',letterSpacing:'3px'}}>
                        YOU'VE NOW ENTERED THE
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'75%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px',lineHeight:'1.15'}}>
                        BASE RACING LEAGUE
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text white-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.25',paddingRight:'20px',fontSize:'18px'}}>
                            WELCOME RACER, YOUR RACING ADVENTURE BEGINS NOW. CLICK CONTINUE TO START REVEALING YOUR FIRST WEEK RACING STACKS.     
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20vh',marginBottom:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 subheadline-text event-register-subheadline" style={{padding:'0px',marginBottom:'20px'}}>
                        <span id="mint-confirm-button4" className="white-text base-blue-background event-register-subheadline" 
                            style={{cursor:'pointer',marginTop:'40px',marginLeft:'5px',marginRight:'0px',width:'auto',fontSize:'20px',borderRadius:'25px',padding:'16px 20px 12px 30px',height:'48px',position:'relative'}} 
                            title="Continue" onClick={()=>continueMintStep(4)}>
                            <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'42px'}}>
                                expand_circle_right
                            </span>
                            <span className="subheadline-text-mono" style={{margin:"2px 80px 0px 80px",fontSize:'24px',letterSpacing:'1px'}}>
                                CONTINUE
                            </span>
                        </span>
                        <span id="mint-confirm-loading4" className="preloader-wrapper small active event-register-formbutton left" 
                            style={{display:'none',height:'30px',width:'30px',marginTop:'20px',marginLeft:'150px'}}>
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};