const initialState = {
  metamaskConnected: "",
  isReady: false,
  loading: false,
  signup_loading: false,
  game_loading: false,
  location: localStorage.getItem('exr_last_page'),
  page_hash: (window.location.hash[window.location.hash.length - 1] === '/') ? window.location.hash.slice(0, -1) : window.location.hash,
  page_loaded: false, 
  admin_racers: [1,3,5,7,8,28,29,30,44,58],
  live_hosts: ['www.exiledracers.com','training.exiledracers.com','harumicup.exiledracers.com']
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PAGE_LOADED":
      return {
        ...state,
        page_loaded: true,
      };

    case "SET_IS_READY":
        return {
          ...state,
          isReady: true,
        };

    case "UPDATE_LOCATION":
      return {
        ...state,
        location: action.payload,
        page_loaded: false,
      };
    
    case "UPDATE_PAGE_HASH":
      return {
        ...state,
        page_hash: action.payload,
      };

    case "UPDATE_METAMASK_CONNECTION_STATUS":
      return {
        ...state,
        metamaskConnected: action.payload,
      };
    
    case "SIGNUP_LOADING":
      return {
        ...state,
        signup_loading: action.payload,
      };
      
    case "GAME_LOADING":
      return {
        ...state,
        game_loading: action.payload,
      };
    
    default:
      return state;
  }
};

export default appReducer;
