import {TrackPropOverride} from "./trackPropOverride.js";
import * as THREE from "three";
import {effectGlow} from "../../../../../../exr-webgl-hub/effects/2d/effectGlow.js";

export class TrackPropOverrideBannerLight extends TrackPropOverride {
    static REPLACE = false;
    static NAME = "@portalProjectorsLights";
    static COLOR = new THREE.Color("#3d90e3");

    material = effectGlow.clone();

    constructor(mesh) {
        super();

        this.material.uniforms.color = new THREE.Uniform(TrackPropOverrideBannerLight.COLOR);

        mesh.material = this.material;

    }
}