import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, ethers } from 'ethers'

import { setUserSignOn, setLoginWallet } from "../../../../../redux/Account/actions";
import {validateEmailText, sendEventEmailCode, checkEventEmailCode, addSpectatorInfo, addWalletInfo} from "../../../../../utils/signon";
import {getEventUserInfo, addEventUserAction, saveRacerHandle, WalletConnectModal, useWalletStatus} from "../../../../../utils/signon";
import {axiosInstance } from "../../../../../utils";
import {BaseHowWorksSplash} from "../HowWorks/index";
import {BasePreMintSetup} from "../PreMintSetup/index";
import {BaseMintPasses} from "../MintPasses/index";
import {BaseLeagueWeeklyReveal} from "../WeeklyReveal/index";
import {BaseLeagueWeeklyRace} from "../WeeklyRace/index";
import {BaseLeagueLeaderboards} from "../Leaderboards/index";
import {BaseLeagueWeeklyPredictions} from "../WeeklyPredictions/index";
import {BaseLeagueWeeklyRacedayDetails} from "../WeeklyRacedayDetails/index";
import {BaseLeagueWeeklyStackDetails} from "../WeeklyStackDetails/index";

import {TrackLoader} from "../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../webgl/race/minimap/minimap.js";

import $ from "jquery";

let current_user_id = 0; 
let current_wallet_address = '';
let current_racer_status = 'predictor';
let current_league_id = 3;
let current_season_id = 3;
let current_week_num = 4;
let current_raceday_id = 0;
let current_raceday_number = 0;
let current_raceday_track_id = 0;
let signup_racer = 'spectator';
let start_handle = '';
let confirm_handle = 0;
let confirm_pass = 0;
let confirm_disclaimer = 0;
let confirm_reveal = 0;
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;
let pilots_checked = 0;
let racecrafts_checked = 0;
let boosters_checked = 0;
let season_raceday_list:any = [];

export const BaseLeagueSplash = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state:any) => state.account);
    const { address, chainId, isConnected } = useWalletStatus();
    const { walletProvider } = useWeb3ModalProvider();

    const [pageSet, setPage] = useState(false);
    const [provider, setProvider] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("");
    const [current_wallet, setCurrentWallet] = useState("");
    const [racer_metamask, setRacerMetamask] = useState('');
    const [racer_talisman, setRacerTalisman] = useState('');
    const [walletNetwork, setWalletNetwork] = useState('CONNECT');
    const [walletNetworkAddress, setWalletNetworkAddress] = useState('Not Connected');
    const [registration_level, setRegistrationlevel] = useState('REGISTRATION COMPLETE');
    const [racer_name, setRacerName] = useState('user123');
    const [race_number, setRaceNumber] = useState('1');
    const [swapped_raceday_number,setSwappedRacedayNumber] = useState(1);
    const [raceday_number, setRacedayNumber] = useState('1');
    const [racer_status,setRacerStatus] = useState('predictor');
    const [racer_action_status,setRacerActionStatus] = useState('predict');

    const [mintStartHTML,setMintStartHTML] = useState(<></>);
    const [revealWeeklyStackHTML,setRevealWeeklyStackHTML] = useState(<></>);
    const [weeklyRaceHTML,setWeeklyRaceHTML] = useState(<></>);
    const [weeklyPredictionsHTML,setWeeklyPredictionsHTML] = useState(<></>);
    const [weeklyRacedayDetailsHTML, setWeeklyRacedayDetailsHTML] = useState(<></>);
    const [weeklyStackDetailsHTML, setWeeklyStackDetailsHTML] = useState(<></>);
    const [leaderboardsHTML,setLeaderboardsHTML] = useState(<></>);

    /* EXR Event ID */
    var exreventid = 5;
    let league_permalink_id = 'base-racing-league'
    // var exreventid = 3;
    // let league_permalink_id = 'pink-racing-league'
    let signup_user_id = 0;

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Black_Ship_Splash_Background_1704920753949.png';

    let bg_image_ship = 'https://media.exiledracers.com/assets/game/builder/Ship_Bottom_Background_1704950678438.png';

    let bg_primary_splash = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Primary_Splash_Background_1709765585438.png';
    let bg_image_left = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Left_1709765609425.png';
    let bg_image_right = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Right_1709765624764.png';
    
    let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';
    let logomark_image = 'https://media.exiledracers.com/assets/game/builder/EXR_Logomark_White_1693422646590.png';
    let polka_logo = 'https://media.exiledracers.com/assets/game/builder/Polkadot_White_Icon_1693419269508.png';
    let ztg_logo = 'https://media.exiledracers.com/assets/game/builder/Zeitgeist_White_Icon_1693419306813.png';
    let moonbeam_logo = 'https://media.exiledracers.com/assets/game/builder/Moonbeam_White_Icon_1693419281943.png';
    let moonbeam_logo_full = 'https://media.exiledracers.com/assets/game/builder/Moonbeam_Logo_White_1704934123621.png';
    let exr_wordmark = 'https://media.exiledracers.com/assets/game/builder/EXR_Wordmark_Long_1693420169042.png';
    let pink_full_logo = 'https://media.exiledracers.com/assets/game/builder/Pink_Logo_Icon_1704949451078.png';
    
    let base_full_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Base_Wordmark_Logo_Blue_1709766845568.png';
    let base_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Base_Icon_Logo_Blue_1709766897123.png';
    let base_network_logo = 'https://media.exiledracers.com/assets/game/builder/Base_Network_Logo_1709767498507.png';
    let base_full_logo_white = 'https://media.exiledracers.com/assets/game/builder/Base_Wordmark_Logo_White_1709766877696.png';
    let base_logo_white = 'https://media.exiledracers.com/assets/game/builder/Base_Icon_Logo_White_1709766912123.png';

    let axelar_full_logo_white = 'https://media.exiledracers.com/assets/game/builder/Axelar_Wordmark_Logo_White_1709767280477.png';
    let axelar_full_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Axelar_Wordmark_Logo_Blue_1709767264248.png';
    let axelar_logo_white = 'https://media.exiledracers.com/assets/game/builder/Axelar_Icon_Logo_White_1709767305636.png';
    let axelar_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Axelar_Icon_Logo_Blue_1709767292192.png';

    useEffect(() => {
        // if((userID > 0)&&(userID !== undefined)) {
        //     current_user_id = userID;
        //     signup_user_id = userID;
        //     current_wallet_address = accountAddress; 
        // }
        // checkWalletBalance()
    }, []);

    useEffect(() => {
        // checkWalletBalance()
    }, [props.wcModal]);

    function checkForWalletAddress() {
        let newaddress = props.wcModal.getAddress();
        let walletconnected = props.wcModal.getIsConnected();
        if((newaddress !== undefined)&&(walletconnected)&&((newaddress != current_wallet_address)||(walletNetwork == 'CONNECT'))) {
            current_wallet_address = newaddress;
            var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-10);
            
            dispatch(setLoginWallet(current_wallet_address));
            let newnetwork = props.wcModal.getWalletProvider();
            let newchain = props.wcModal.getChainId();
            setWalletNetwork(obf_wallet);
            setWalletNetworkAddress('Wallet Connected');
            $("#racerWalletConnected").val(obf_wallet);
            $("#connected-wallet").fadeIn();

            setTimeout(function(){
                checkForWalletAddress();
            },2000);
        } else {
            if((!walletconnected)&&(walletNetwork != 'CONNECT')) {
                setWalletNetwork('CONNECT');
                setWalletNetworkAddress('Not Connected');
                $("#racerWalletConnected").val('');
                $("#connected-wallet").fadeOut();
            }
            setTimeout(function(){
                checkForWalletAddress();
            },2000);
        }

    }

    async function checkWalletBalance() {
        let newaddress = props.wcModal.getAddress();
        let walletconnected = props.wcModal.getIsConnected();
        let final_balance = 0;
        if(walletconnected) {
            let waddress = props.wcModal.getAddress();
            // https://docs.walletconnect.com/web3modal/react/hooks
            if (walletProvider) {
                const wprovider = new BrowserProvider(walletProvider);
                let wbalance = await wprovider.getBalance(waddress);
                final_balance = parseFloat(ethers.formatEther(wbalance));
            }
        } 
        return final_balance;
    }

    async function submitEmailAddress() {
        var raceremail = (document.getElementById("racerEmailAddress") as HTMLInputElement).value.trim();

        var addData:any = new Object();
        addData.specEmail = encodeURIComponent(raceremail);

        if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else {
            $("#email-send-button").hide();
            $("#email-sent-loading").show();
            if(userID > 0) {
                showEmailLoggedIn();
            } else {
                let email_send = await sendEventEmailCode(raceremail);
                if(email_send['status'] == 'email_sent') {
                    setEmail(raceremail);
                    showEmailSendSent();
                } else {
                    showEmailSendReset();
                    alert('Something Went Wrong. Update and Try Again.');
                }
            }
        }
    }

    async function submitEmailCode() {
        var raceremail = (document.getElementById("racerEmailAddress") as HTMLInputElement).value.trim();
        var racercode = (document.getElementById("racerEmailCode") as HTMLInputElement).value.trim();
        
        var addData:any = new Object();
        addData.specEmail = encodeURIComponent(raceremail);
        addData.specCode = encodeURIComponent(racercode);
        addData.specEvent = encodeURIComponent(exreventid);

        if(raceremail.length <= 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if (racercode.length != 6) {
            alert('Add a Valid Email Code');
        } else {
            $("#code-send-button").hide();
            $("#code-confirmed-loading").show();
            let email_code_check:any = await checkEventEmailCode(raceremail,racercode,exreventid);
            if(email_code_check) {
                if(email_code_check['status'] == 'email_code_expired') {
                    showEmailCodeReset();
                    alert('Your Confirmation Code Expired. Register Again To Get a New Code.');
                } else if(email_code_check['status'] == 'email_code_wrong') {
                    showEmailCodeReset();
                    alert('Invalid Code. Check Your Code and Enter it Again');
                } else if (email_code_check['status'] == 'confirmed_waitroom') {
                    // New User - Add to Users Table
                    addNewWaitroomUser();
                    setRegistrationlevel('REGISTERED TO PREDICT');
    
                } else if (email_code_check['status'] == 'confirmed_existing') {
                    // Existing User - Approve Code
                    let userid = email_code_check['login_info']['userid'];
                    let racerid = email_code_check['login_info']['racerid'];
                    let spectatorid = email_code_check['login_info']['spectatorid'];
                    let logintime = email_code_check['login_info']['logintime'];
                    let logintoken = email_code_check['login_info']['logintoken'];
                    let walletid = email_code_check['login_info']['walletid'];
    
                    // Login to Site
                    current_user_id = userid;
                    signup_user_id = userid;
                    dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
    
                    if(walletid != null) {
                        if(walletid.length > 10) {
                            // Existing User - With Wallet
                            current_wallet_address = walletid;
                            var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-15);
                            $("#racerWalletConnected").val(obf_wallet);
                                
                            dispatch(setLoginWallet(current_wallet_address));
                            setCurrentWallet(current_wallet_address);
                            showWalletSaved();
                            //showEmailCodeApproved();
                        } else {
                            // Existing User - No Wallet - Prompt To Add
                            showEmailCodeApproved();
                            setRegistrationlevel('REGISTERED TO PREDICT');
                        }
                    } else {
                        // Existing User - No Wallet - Prompt To Add
                        showEmailCodeApproved();
                        setRegistrationlevel('REGISTERED TO PREDICT');
                    }
                    
                } else {
                    showEmailCodeReset();
                    alert('Whoops! Something Went Wrong. Update and Try Again');
                }
            }
        }
    }

    async function addNewWaitroomUser() {
        var raceremail = (document.getElementById("racerEmailAddress") as HTMLInputElement).value.trim();
        var racerhandle = '';

        let new_user_info:any = await addSpectatorInfo(raceremail,racerhandle,exreventid);

        if (new_user_info['status'] == 'confirmed') {
            // New User Created & Approved Code
            let userid = new_user_info['login_info']['userid'];
            let racerid = new_user_info['login_info']['racerid'];
            let spectatorid = new_user_info['login_info']['spectatorid'];
            let logintime = new_user_info['login_info']['logintime'];
            let logintoken = new_user_info['login_info']['logintoken'];

            // Login to Site
            current_user_id = userid;
            signup_user_id = userid;
            dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));

            // New User - Approve Code
            showEmailCodeApproved();
        } else {
            showEmailCodeReset();
            alert('Whoops! Something Went Wrong. Update and Try Again');
        }
    }

    async function submitWalletSaved() {
        var raceremail = (document.getElementById("racerEmailAddress") as HTMLInputElement).value.trim();
        if(current_wallet_address.length < 5) {
            alert('Connect A Wallet');
        } else if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if(current_user_id == 0) {
            alert('Signup or Login To Update');
        } else {
            $("#wallet-save-button").hide();
            $("#wallet-save-loading").show();
            let wallet_info:any = await addWalletInfo(raceremail,current_user_id,current_wallet_address,signup_racer);

            if(wallet_info) {
                if(wallet_info['status'] == 'duplicate_wallet') {
                    // $("#wallet-save-loading").hide();
                    // $("#wallet-save-button").show();
                    // alert('This Wallet Is Already Registered. Connect a Different Wallet Later or Login to your Account.');
                    showWalletSaved();
                } else if(wallet_info['status'] == 'no_account') {
                    $("#wallet-save-loading").hide();
                    $("#wallet-save-button").show();
                    alert('This Email Account is not Registered. Go Back and Complete the Signup Process.');
                } else if(wallet_info['status'] == 'confirmed') {
                    let userid = wallet_info['login_info']['userid'];
                    let racerid = wallet_info['login_info']['racerid'];
                    let spectatorid = wallet_info['login_info']['spectatorid'];
                    let logintime = wallet_info['login_info']['logintime'];
                    let logintoken = wallet_info['login_info']['logintoke'];
    
                    dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                    dispatch(setLoginWallet(current_wallet_address));
    
                    showWalletSaved();
                } else {
                    $("#wallet-save-loading").hide();
                    $("#wallet-save-button").show();
                    alert('Whoops! Something Went Wrong. Update and Try Again');
                }
            }
            
        }
    }

    async function connectWalletProvider(wallet_type:string) {
        let wallet_connection;

        let wcConnection = await props.wcModal.open();
        checkForWalletAddress();

        // if(wallet_type == 'walletconnect') {
        //     wallet_connection = await WalletConnectModal();
        // } else 
        
        // if (wallet_type == 'talisman') {
        //     wallet_connection = await connectTalisman();
        // } else {
        //     wallet_connection = await connectMetamask();
        // }
        // if(wallet_connection['status'] == 'not_installed') {
        //     alert('Install '+wallet_type.charAt(0).toUpperCase()+wallet_type.slice(1)+' on this Browser');
        // } else if(wallet_connection['status'] == 'connected') {
        //     current_wallet_address = wallet_connection['wallet_address'];
        //     let racer_wallet_type = wallet_connection['wallet_type'];
        //     let racer_inventory = wallet_connection['inventory_check'];
            
        //     if ((racer_inventory['total_pilots'] > 0)&&(racer_inventory['total_racecrafts_sync'] > 0)&&(racer_inventory['total_boosters_sync'] > 0)) {
        //         signup_racer = 'racer';
        //     } else {
        //         signup_racer = 'spectator';
        //     }

        //     setRacerMetamask(current_wallet_address);
        //     setCurrentWallet(current_wallet_address);

        //     if (racer_wallet_type == 'talisman') {
        //         setRacerTalisman(current_wallet_address);
        //     } else {
        //         setRacerMetamask(current_wallet_address);
        //     }

        //     var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-15);
        //     $("#racerWalletConnected").val(obf_wallet);
        //     showConnectedWalletForm();
        // } else {
        //     alert('Install '+wallet_type.charAt(0).toUpperCase()+wallet_type.slice(1)+' on this Browser');
        // }
    }

    async function loadEventUserInfo() {
        if(userID) {
            current_user_id = userID;
        }
        let user_status:any = await getEventUserInfo(current_user_id,exreventid);
        
        if(user_status['status'] == 'confirmed') {
            let user_racer_name = user_status['event_info']['racer_name'];
            let user_event_status = user_status['event_info']['race_event_status'];

            start_handle = user_racer_name;
            setRacerName(user_racer_name);
            setRacerStatus(user_event_status);
            current_racer_status = user_event_status;

            loadLeagueUserInfo();

            if (user_event_status == 'racer') {
                setRacerActionStatus('race');
            } else {
                setRacerActionStatus('predict');
            }

            confirm_handle = user_status['event_info']['racer_name_viewed'];
            confirm_pass = user_status['event_info']['access_pass_viewed'];
            confirm_disclaimer = user_status['event_info']['disclaimer_viewed'];
            confirm_reveal = user_status['event_info']['reveal_viewed'];

            // CHECK IF NEED TO DO THE PREMINT PROCESS
            if((confirm_handle == 0)||(confirm_pass == 0)||(confirm_disclaimer == 0)) {

                // LOAD PREMINT AND MINT PAGES
                let mintingHTML:any = <><BasePreMintSetup exreventid={exreventid} racername={user_racer_name} racerstatus={user_event_status} moveToNext={showMintingFlow} />
                <BaseMintPasses exreventid={exreventid} racername={user_racer_name} racerstatus={user_event_status} moveToNext={showRevealFlow} /></>;
                setMintStartHTML(mintingHTML);

                // DETERMINE PAGE TO SHOW: HANDLE, DISCLAIMER OR MINT PAGE
                
                setTimeout(function(){
                    $("#main-landing-page").fadeOut(2000);
                },2000);
                setTimeout(function(){
                    if(confirm_handle == 0) {
                        $("#event-landing-page-2b").fadeIn(3000);
                        // $("html, body").animate({ scrollTop: $("#event-landing-page-2b").offset()?.top }, 1000);
                    } else if(confirm_disclaimer == 0) {
                        $("#event-landing-page-2b").hide();
                        $("#event-landing-page-3b").fadeIn(3000);
                        $("html, body").animate({ scrollTop: $("#event-landing-page-3b").offset()?.top }, 1000);
                    } else if (confirm_pass == 0) {
                        $("#event-landing-page-2b").hide();
                        $("#event-landing-page-3b").hide();
                        $("#event-landing-page-3c").fadeIn(3000);
                        $("html, body").animate({ scrollTop: $("#event-landing-page-3c").offset()?.top }, 1000);
                    }
                },1000);
            } else {
                showRevealFlow();
            }
            setTimeout(function(){
                startToggles(user_event_status);
            },500);
            
            $(".howtoplay").each(function(){
                $(this).hide();
            });
        }
    }

    function showMintingFlow() {
        if (current_racer_status == 'racer') {
            $("#event-landing-page-3c").fadeIn();
            $("html, body").animate({ scrollTop: $("#event-landing-page-3c").offset()?.top }, 1000);
            setTimeout(function(){
                $("#event-landing-page-2b").fadeOut();
                $("#event-landing-page-3b").fadeOut();
            },2000);
        } else {
            viewWeeklyStackRaces();
            if(current_racer_status == 'predictor') {
                toggleRaceSections('predict_race');
            }
        }
    }

    function showRevealFlow() {
        if((confirm_reveal < current_week_num)&&(current_racer_status == 'racer')) {
            $("#event-landing-page-3c").hide();
            $("#event-landing-page-4b").fadeIn();
            $("html, body").animate({ scrollTop: $("#event-landing-page-4b").offset()?.top }, 1000);
        } else if((confirm_reveal >= current_week_num)||(current_racer_status == 'predictor')) {
            
            viewWeeklyStackRaces();
            if(current_racer_status == 'predictor') {
                toggleRaceSections('predict_race');
            }
        }
    }

    function loadLeagueUserInfo() {
        if(userID) {
            current_user_id = userID;
            current_racer_status = 'racer';
        }
        var addData:any = new Object();
        addData.leaguePermalink = encodeURIComponent(league_permalink_id);
        addData.specID = encodeURIComponent(current_user_id);

        axiosInstance.post('league/season_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var league_info = result.data;
                let current_league_id = league_info[0]['league_id'];
                current_season_id = league_info[0]['league_season_id'];
                let current_league_details = league_info;
                let current_season_image = league_info[0]['season_header_photo'];
                let current_season_name = league_info[0]['season_custom_name'];
                let current_season_number = league_info[0]['season_number'];
                let league_name = league_info[0]['league_name'];
                let season_racedays = league_info[0]['season_racedays'];
                season_raceday_list = season_racedays;
                if(season_racedays.length > 0) {
                    var active_raceday_id = 0;
                    var active_raceday_number = 0;
                    var last_completed_raceday_id = 0;
                    var last_completed_raceday_number = 0;
                    current_raceday_id = 0;
                    current_raceday_number = 0;
                    for(var z = 0; z < season_racedays.length;z++) {
                        var next_raceday_id = season_racedays[z]['raceday_id'];
                        var next_raceday_number = season_racedays[z]['raceday_number'];
                        var next_race_completed = season_racedays[z]['race_completed'];
                        var next_race_next = season_racedays[z]['race_next'];
                        var next_race_ondeck = season_racedays[z]['race_ondeck'];

                        // If Race Is Next or Ondeck With Races Available Get Smallest Race Number
                        if ((next_race_next == 1)||((next_race_ondeck == 1)&&(season_racedays[z]['raceday_races'].length > 0))) {
                            if((active_raceday_number == 0)||(active_raceday_number > next_raceday_number)) {
                                active_raceday_id = next_raceday_id;
                                active_raceday_number = next_raceday_number;
                            }
                        }

                        // Get Last Completed Week
                        if(next_race_completed == 1) {
                            if(last_completed_raceday_number < next_raceday_number) {
                                last_completed_raceday_id = next_raceday_id;
                                last_completed_raceday_number = next_raceday_number;
                            }
                        }
                    }

                    if (active_raceday_id > 0) {
                        current_week_num = active_raceday_number;
                        current_raceday_id = active_raceday_id;
                        current_raceday_number = active_raceday_number;
                    } else if (last_completed_raceday_id > 0) {
                        current_week_num = last_completed_raceday_number;
                        current_raceday_id = last_completed_raceday_id;
                        current_raceday_number = last_completed_raceday_number;
                    } else {
                        current_week_num = season_racedays[0]['raceday_number'];
                        current_raceday_id = season_racedays[0]['raceday_id'];
                        current_raceday_number = season_racedays[0]['raceday_number'];
                    }
                    setRaceNumber(String(current_week_num));
                    showWeeklyRaceSections(current_raceday_number); 
                } 
                loadLeagueLeaderboardLists(season_racedays);

            } else {
                // window.location.href= '/#/leagues';
            }
        }).catch(error => {
            console.log(error);
            // window.location.href= '/#/leagues';
        });
    }

    function showWeeklyRaceSections(raceweeknum:number) {
        var show_raceday_id = 0;
        var show_race_id = 0;
        var show_race_unique_id = 0;
        var show_track_id = 0;
        var raceday_status = 'details_only';
        var raceday_details = {};
        for(var z = 0; z < season_raceday_list.length;z++) {
            var next_raceday_id = season_raceday_list[z]['raceday_id'];
            var next_raceday_number = season_raceday_list[z]['raceday_number'];
            if(next_raceday_number == raceweeknum) {
                show_raceday_id = next_raceday_id;
                show_track_id = season_raceday_list[z]['race_track_id'];
                raceday_details = season_raceday_list[z];
                if(season_raceday_list[z]['raceday_races'].length > 0) {
                    show_race_id = season_raceday_list[z]['raceday_races'][0]['race_id'];
                    show_race_unique_id = season_raceday_list[z]['raceday_races'][0]['race_unique_id'];
                }

                if(season_raceday_list[z]['results_opened'] == 1) {
                    raceday_status = 'results_open';
                } else if(season_raceday_list[z]['prediction_opened'] == 1) {
                    raceday_status = 'predictions_open';
                } else if((season_raceday_list[z]['entry_open'] == 1)&&(season_raceday_list[z]['raceday_races'].length > 0)) {
                    raceday_status = 'entry_open';
                } else if((season_raceday_list[z]['prediction_opened'] == 0)&&(season_raceday_list[z]['entry_open'] == 0)&&(season_raceday_list[z]['raceday_races'].length > 0)) {
                    raceday_status = 'predictions_opening';
                } 
            }
        }
        current_raceday_track_id = show_track_id;

        if(raceweeknum == 1) {
            $("#swapweekleft").hide();
        } else {
            $("#swapweekleft").show();
        }

        if(season_raceday_list.length > 0) {
            if(raceweeknum == season_raceday_list[season_raceday_list.length-1]['raceday_number']) {
                $("#swapweekright").hide();
            } else {
                $("#swapweekright").show();
            }
        } else {
            $("#swapweekright").hide();
        }
        
        setWeeklyRacedayDetailsHTML(<></>);
        setWeeklyPredictionsHTML(<></>);
        setRevealWeeklyStackHTML(<></>);
        setWeeklyRaceHTML(<></>);
        setWeeklyStackDetailsHTML(<></>);

        setWeeklyRacedayDetailsHTML(<BaseLeagueWeeklyRacedayDetails exreventid={exreventid} league_id={current_league_id} season_id={current_season_id} raceday_id={show_raceday_id} raceday_details={raceday_details} raceStatus={raceday_status} />);
        setWeeklyPredictionsHTML(<BaseLeagueWeeklyPredictions exreventid={exreventid} league_id={current_league_id} season_id={current_season_id}  raceday_id={show_raceday_id}  raceStatus={raceday_status} />);
        setSwappedRacedayNumber(raceweeknum);
        setRevealWeeklyStackHTML(<BaseLeagueWeeklyReveal exreventid={exreventid} league_id={current_league_id} season_id={current_season_id} race_id={show_race_id} raceday_id={show_raceday_id} />);
        setWeeklyRaceHTML(<BaseLeagueWeeklyRace exreventid={exreventid}league_id={current_league_id} season_id={current_season_id} race_id={show_race_id} raceUniqueID={show_race_unique_id} raceTrackID={show_track_id} raceday_id={show_raceday_id} raceStatus={raceday_status} />);
        setWeeklyStackDetailsHTML(<BaseLeagueWeeklyStackDetails exreventid={exreventid} league_id={current_league_id} season_id={current_season_id} race_id={show_race_id} raceUniqueID={show_race_unique_id} raceTrackID={show_track_id} raceday_id={show_raceday_id} raceStatus={raceday_status} />);

        $("#race_results_link").hide();
        $("#predict_race_link").hide();
        $("#stack_entry_link").hide();
        $("#race_lineup_link").hide();
        $("#stack_details_link").hide();
        $("#race_details_link").show();

        setTimeout(function() {
            if(raceday_status == 'results_open') {
                $("#predict_race_link").show();
                $("#stack_entry_link").hide();
                if(current_racer_status == 'racer') {
                    $("#race_results_link").show();
                    $("#stack_details_link").show();
                    toggleRaceSections('race_results');
                } else {
                    toggleRaceSections('predict_race');
                }
            } else if(raceday_status == 'predictions_open') {
                $("#predict_race_link").show();
                if(current_racer_status == 'racer') {
                    $("#stack_entry_link").show();
                    $("#race_lineup_link").show();
                    $("#stack_details_link").show();
                }
                toggleRaceSections('predict_race');
            } else if((raceday_status == 'entry_open')||(raceday_status == 'predictions_opening')) {
                if(current_racer_status == 'racer') {
                    $("#stack_entry_link").show();
                    $("#race_lineup_link").show();
                    $("#stack_details_link").show();
                    toggleRaceSections('stack_entry');
                } else {
                    toggleRaceSections('race_details');
                }
            } else {
                toggleRaceSections('race_details');
            }
        },500);
    }

    function switchRaceWeek(nextdirection:string) {
        if(nextdirection == 'back') {
            let next_weeknum = swapped_raceday_number - 1;
            showWeeklyRaceSections(next_weeknum);
        } else {
            let next_weeknum = swapped_raceday_number + 1;
            showWeeklyRaceSections(next_weeknum);
        }
    }

    function startToggles(rstatus:string) {
        if(rstatus == 'predictor') {
            toggleRaceSections('predict_race');
        } else {
            toggleRaceSections('race_results');
        }
    }

    function loadLeagueLeaderboardLists(racedays:any) {
        setLeaderboardsHTML(<BaseLeagueLeaderboards racedays={racedays} group_count={1} exreventid={exreventid} league_id={current_league_id} season_id={current_season_id} display_type="grid" />);
    }

    async function revealWeeklyStack(weeknum:number) {
        $("#reveal-confirm-button").hide();
        $("#reveal-confirm-loading").show();
        let actiontype = 'reveal';
        let actionid = String(current_raceday_number);
        let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);

        setTimeout(function() {
            $("#reveal-confirm-button").hide();
            $("#reveal-confirm-loading").hide();
            $("#weekly-stack-reveal-button").fadeOut();
        },3000);
        setTimeout(function() {
            $("#weekly-stack-reveal-section").fadeIn(2000);
        },5000);         
    }

    async function viewWeeklyStackRaces() {
        // $(".howtoplay").each(function(){
        //     $(this).show();
        // });
        $("#event-landing-page-3c").hide();
        $("#event-landing-page-5b").fadeIn();
        $("html, body").animate({ scrollTop: $("#event-landing-page-5b").offset()?.top }, 1000);      
        setTimeout(function(){
            $("#event-landing-page-7").show();
            $("#event-landing-page-9").show();
            $("#event-landing-page-6b").show();
            $("#event-landing-page-66b").show();
        },3000);
    }

    function scrollToLeaderboards() {
        $("html, body").animate({ scrollTop: $("#event-landing-page-6b").offset()?.top }, 1000);      
    }

    function toggleRaceSections(sectionname:string) {
        $("#stack_entry_section").hide();
        $("#stack_details_section").hide();
        $("#race_details_section").hide();
        $("#race_lineup_section").hide();
        $("#race_results_section").hide();
        $("#predict_race_section").hide();
        $("#"+sectionname+"_section").show();

        $("#stack_entry_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#stack_details_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#race_details_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#race_lineup_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#predict_race_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#race_results_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#"+sectionname+"_link").removeClass("white-base-blue-link").addClass("white-base-blue-link-active");

        if(sectionname == 'race_details') {
            setTimeout(function() {
                loadRacedayTrack(current_raceday_track_id);
            },1000);
        }
    }

    function viewRacerLinueupStack(e:any) {
        e.preventDefault();
        var tmp_racer_id = e.target.id.replace("view_stack_button_","");
        if($("#lineup_stack_"+tmp_racer_id).is(":visible")) {
            $("#lineup_stack_"+tmp_racer_id).slideUp();
            $("#view_stack_button_"+tmp_racer_id).html('View Stack');
        } else {
            $("#lineup_stack_"+tmp_racer_id).slideDown();
            $("#view_stack_button_"+tmp_racer_id).html('Hide Stack');
        }
    }

    async function loadRacedayTrack(race_track_id:any) {
        let track_viewer_container = window.document.getElementById("track-viewer-league");
        if(track_viewer_container) {
            let track_data = await new Track(race_track_id,'id');
            new TrackLoader(track_data,
                () => {
                    if(track_viewer_container) {
                        $("#track-viewer-league").html('');
                        let track_minimap = new Minimap(new TrackMap(track_data),track_viewer_container).populate(null);
                    }
                }
            );
        }
    }
    
    function showEmailSendSent() {
        setTimeout(function(){
            $("#register-email").hide();

            $("#email-sent-text").fadeIn();
            $("#register-email-code").fadeIn();
        },3000);
    }

    function showEmailSendReset() {
        $("#email-send-button").show();
        $("#email-sent-loading").hide();
        $("#email-sent-button").hide();
    }

    function showEmailLoggedIn() {
        loadEventUserInfo();
        setTimeout(function(){
            $("#email-send-button").hide();
            $("#email-sent-loading").hide();
        },3000);
    }

    function showEmailCodeApproved() {
        setTimeout(function(){
            $("#register-email-code").hide();
            $("#email-sent-text").hide();

            $("#email-confirmed-text").fadeIn();
            $("#register-wallet").fadeIn();
        },3000);
    }

    function showConnectedWalletForm() {
        $("#connected-wallet").fadeIn();
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    }

    function showWalletSaved() {
        
        setTimeout(function(){
            loadEventUserInfo();
        },2000);

        setTimeout(function(){
            $("#wallet-save-loading").hide();
            $("#register-email-code").hide();
            $("#email-sent-text").hide();
            $("#register-wallet").hide();
            $("#connected-wallet").hide();
        
            $("#email-confirmed-text").fadeIn();
            $("#wallet-confirmed-text").fadeIn();
            $("#register-complete-text").fadeIn();
        },3000);
        
    }

    function showEmailCodeReset() {
        $("#code-send-button").show();
        $("#code-confirmed-loading").hide();
        $("#code-confirmed-button").hide();
    }

    function showRegistrationForm() {
        $("#register-button").hide();
        $("#home-value-props").hide();
        
        $("#home-value-props2").fadeIn();
        $('#register-email').fadeIn();
        $("#register-button-subheadline").hide();
    }

    

    if(pageSet === false) {
        setPage(true);
        // loadLeagueUserInfo();
        //loadEventUserInfo();
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto',overflowY:'hidden'}}>
        <div className="row" id="main-landing-page" style={{position:'relative',width:'100%',minHeight:'100vh',marginBottom:'0px',
        background:'url('+bg_primary_splash+')',backgroundSize:'cover',backgroundPosition:'center'
            }}>
            <div className="col s12 transparent firstsection" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="col s12">
                        <div className="row " style={{marginTop:'10px'}}>
                            <div className="col s12 m10 offset-m1 white-text">
                                <div className="headline-text event-register-large-headline-mid white-text" style={{fontSize:'40px',lineHeight:'1.25'}}>
                                   BASE RACING<br/>
                                   <span className="white-text">LEAGUE</span>
                                </div>
                            </div>
                        </div>
                        <div id="home-value-props" className="row" style={{marginTop:'30px'}}>
                            <div className="col s10 offset-s1 white-text show-on-medium-and-up hide-on-small-only">
                                <div className="subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    — COMPETE IN WEEKLY RACES<br/>
                                    — CLIMB POINTS LEADERBOARD<br/>
                                    — WIN CHANCE AT PRIZES<br/>
                                    — BONUS HOLDERS REWARDS
                                </div> 
                            </div>
                            <div className="col s12 white-text show-on-small-only hide-on-med-and-up">
                                <div className="subheadline-text center"
                                    style={{fontSize:'16px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    — COMPETE IN WEEKLY RACES<br/>
                                    — CLIMB POINTS LEADERBOARD<br/>
                                    — WIN CHANCE AT PRIZES<br/>
                                    — BONUS HOLDERS REWARDS
                                </div> 
                            </div>
                        </div>
                        <div id="home-value-props2" className="row" style={{marginTop:'30px',display:'none'}}>
                            {/* <div className="col s10 offset-s1 m7 offset-m1 l5 offset-l1 white-text" style={{display:'none'}}>
                                <div className="left subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    PRE-REGISTER FOR THE ALLOWLIST LOTTERY. CONFIRM YOUR <span className="exr-base-blue-light-text">EMAIL</span> and
                                    CONNECT YOUR <span className="exr-base-blue-light-text">WALLET</span> TO JOIN.
                                </div> 
                            </div> */}
                            <div className="col s10 offset-s1 m7 offset-m1 l5 offset-l1 white-text">
                                <div className="left subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    BASE RACING ENTRY IS <span className="exr-base-blue-light-text">CLOSED.</span> JOIN AS A <span className="exr-base-blue-light-text">PREDICTOR</span> OR 
                                    ACCESS YOUR <span className="exr-base-blue-light-text">ACCOUNT</span> BELOW.
                                </div> 
                            </div>
                        </div>
                        <div className="row register-sections" style={{marginTop:'0px',paddingTop:'0px',marginBottom:'0px'}}>
                            <div id="email-sent-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined grey-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left"
                                        style={{flex:1,fontSize:'18px',color:'silver',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>EMAIL CODE SENT:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {email}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                            <div id="email-confirmed-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined exr-base-blue-light-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left exr-base-blue-light-text"
                                        style={{flex:1,fontSize:'18px',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>EMAIL CONFIRMED:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {email}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                            <div id="wallet-confirmed-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined exr-base-blue-light-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left exr-base-blue-light-text"
                                        style={{flex:1,fontSize:'18px',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>WALLET CONFIRMED:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {current_wallet.slice(0,7)}...{current_wallet.slice(-5)}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                            <div id="register-complete-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined exr-base-blue-light-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left exr-base-blue-light-text"
                                        style={{flex:1,fontSize:'18px',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px',width:'50%',minWidth:'270px'}}> 
                                        <div style={{width:'50%',minWidth:'270px'}}> 
                                            <b>{registration_level}</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                Follow the <a className="exr-base-blue-light-text" target="_blank" href="https://discord.gg/exiledracers">EXR Discord</a> and <a target="_blank" className="exr-base-blue-light-text" href="https://x.com/exiledracers">X</a> to
                                                stay in the loop on next steps and the start of racing.</span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div id="register-email" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            
                            <div id="register-email-form-title" className="col s10 offset-s1 white-text register-email-forms">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}}>
                                    STEP 1 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px'}}>
                                    ENTER EMAIL ADDRESS
                                </span>
                            </div>
                            <div id="register-email-form" className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px'}}>
                                <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Address to Register">
                                    <input id="racerEmailAddress" className="black-bubble-input-round" 
                                        style={{fontSize:'20px',marginLeft:'0px',margin:'0px',marginRight:'-15px'}} />
                                </span>
                                <span id="email-send-button" className="round-border-button right black white-text base-blue-background" 
                                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitEmailAddress()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        SUBMIT
                                    </span>
                                </span>
                                <span id="email-sent-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div id="register-email-code" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            <div id="confirm-email-form-title" className="col s10 offset-s1 white-text">
                                <span className="left subheadline-text event-register-formheader" 
                                        style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}} title="Register For Exiled Racers">
                                        STEP 1 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%'}} title="Register For Exiled Racers">
                                    ENTER EMAIL CODE
                                </span>
                            </div>
                            <div id="confirm-email-form" className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px'}}>
                                <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Code to Confirm">
                                    <input id="racerEmailCode" className="black-bubble-input-round" 
                                        style={{width:'100%',fontSize:'20px',borderColor:'black'}} />
                                </span>
                                <span id="code-send-button" className="round-border-button right black white-text base-blue-background" 
                                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitEmailCode()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        SUBMIT
                                    </span>
                                </span>
                                <span id="code-confirmed-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div id="register-wallet" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            <div className="col col s10 offset-s1 white-text">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}} title="Register For Exiled Racers">
                                    STEP 2 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%'}} title="Register For Exiled Racers">
                                    CONNECT YOUR WALLET
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{padding:'0px',marginTop:'-10px',marginBottom:'20px'}}>
                                <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                                    <div className="row grey lighten-5" style={{display:'flex',flexDirection:'row',padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'1px solid white',cursor:'pointer'}}
                                            onClick={()=>{connectWalletProvider('walletconnect')}} title="Connect Wallet">
                                        <div className="left" style={{marginLeft:'10px',width:'50px',borderRadius:'10px',overflow:'hidden'}}>
                                            <img src="https://media.exiledracers.com/assets/game/builder/Wallet_Connect_Icon_Blue_1710281691551.png" style={{marginTop:'7px',borderRadius:'25px'}} height="40px" />
                                        </div>
                                        <div className="left center-align" style={{flex:'1',fontSize:'12px',borderRadius:'10px'}}>
                                            <div className="left headline-text" style={{width:'100%',padding:'0px',marginTop:'10px',marginBottom:'0px'}}>
                                                {walletNetwork}
                                            </div>
                                            <div className="left" style={{width:'100%',padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'14px'}}>
                                                <div className="black-text" onClick={()=>{connectWalletProvider('walletconnect')}}>
                                                    {walletNetworkAddress}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left" style={{marginRight:'10px',width:'50px',borderRadius:'10px',overflow:'hidden'}}>
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="connected-wallet" className="row register-sections" style={{display:'none',marginTop:'0px',marginBottom:'0px'}}>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px',padding:'0px 20px'}}>
                                <span className="left" style={{display:'none',width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Currently Connected Wallet">
                                    <input id="racerWalletConnected" className="black-bubble-input-round" 
                                        style={{width:'100%',fontSize:'20px',borderColor:'black'}} disabled />
                                </span>
                                <span id="wallet-save-button" className="round-border-button right black white-text base-blue-background" 
                                    style={{marginTop:'0px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitWalletSaved()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        CONTINUE
                                    </span>
                                </span>
                                <span id="wallet-save-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div id="register-button" className="row" style={{marginTop:'50px'}}>
                            <div className="col s12 m10 offset-m1 show-on-medium-and-up hide-on-small-only">
                                <span className="round-border-button left center-align black white-text event-register-formbutton2 base-blue-background" 
                                    style={{marginLeft:'5px',marginRight:'5px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 10px 30px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>showRegistrationForm()}>
                                    <span id="register-button-arrow" className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 5px 20px",fontSize:'14px',letterSpacing:'2px'}}>
                                        {/* REGISTER FOR ALLOWLIST LOTTERY */}
                                        ACCESS BASE RACING LEAGUE
                                    </span>
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m6 offset-m3 show-on-small-only hide-on-med-and-up center-align">
                                <span className="round-border-button center-align black white-text base-blue-background" 
                                    style={{marginLeft:'5px',marginRight:'5px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>showRegistrationForm()}>
                                    <span id="register-button-arrow2" className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'14px',letterSpacing:'1px'}}>
                                        {/* REGISTER TO RACE */}
                                        ACCESS BASE RACING
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="register-button-subheadline" className="row" style={{marginTop:'30px'}}>
                            <div className="col s10 offset-s1 headline-text event-register-subheadline-small exr-base-blue-light-text show-on-medium-and-up hide-on-small-only" 
                                style={{padding:'0px',marginBottom:'30px'}}>
                                <div style={{fontSize:'14px',marginLeft:'10px',textTransform:'uppercase'}}>
                                    Limited Spots. Lottery Based.  <a href="https://discord.gg/exiledracers" className="grey-text">Want to Partner?</a>
                                </div>
                            </div>
                            <div className="col s12 headline-text event-register-subheadline exr-base-blue-light-text show-on-small-only hide-on-med-and-up" 
                                style={{padding:'0px',marginBottom:'30px'}}>
                                <div style={{fontSize:'14px',marginLeft:'10px',textTransform:'uppercase'}}>
                                    Limited Spots. Lottery Based.<br/><a href="https://discord.gg/exiledracers" className="grey-text">Want to Partner?</a>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'70px',marginBottom:'70px',padding:'0px 20px'}}>
                            <div className="col s12 m10 offset-m1 main-sponsor-icons show-on-large-only hide-on-med-and-down" style={{padding:'0px'}}>
                                <div className="left" style={{marginTop:'5px',marginRight:'40px'}}>
                                    <a href="https://www.exiledracers.com" target="_blank" className="white-text">
                                        <img id="exr-logo-button" style={{cursor:'pointer'}} title="Exiled Racers" height="45px" src={logo_image} />
                                    </a>
                                </div>
                                <div className="left" style={{marginTop:'5px',marginRight:'40px',height:'45px',width:'1px',background:'white'}}>
                                </div>
                                <div className="left" style={{marginTop:'7px'}}>
                                    <a href="https://www.base.org/" target="_blank" className="center white-text" style={{marginRight:'50px',verticalAlign:'text-bottom'}}>
                                        <img id="base-logo-button" style={{cursor:'pointer'}} title="BASE" height="40px" src={base_full_logo_white} />
                                    </a>
                                </div>
                                <div className="left" style={{marginTop:'9px'}}>
                                    <a href="https://axelar.network/" target="_blank" className="center white-text" style={{verticalAlign:'text-bottom'}}>
                                        <img id="axelar-logo-button" style={{cursor:'pointer'}} title="Axelar" height="37px" src={axelar_full_logo_white} />
                                    </a>
                                </div>
                            </div>
                            <div className="col s12 m7 offset-m1 main-sponsor-icons center-align show-on-medium-and-down hide-on-large-only" style={{padding:'0px'}}>
                                <div className="row">
                                    <a href="https://www.exiledracers.com" target="_blank" className="white-text" style={{marginTop:'10px'}}>
                                        <img id="exr-logo-button" style={{cursor:'pointer'}} title="Exiled Racers" height="50px" src={logo_image} />
                                    </a>
                                </div> 
                                <div className="row">
                                    <a href="https://www.base.org/" target="_blank" className="center white-text" style={{verticalAlign:'text-bottom'}}>
                                        <img id="base-logo-button" style={{cursor:'pointer',marginTop:'30px'}} title="BASE" height="45px" src={base_full_logo_white} />
                                    </a>
                                </div>
                                <div className="row">
                                    <a href="https://axelar.network/" target="_blank" className="center white-text">
                                        <img src={axelar_full_logo_white} style={{cursor:'pointer',marginTop:'30px'}} title="Axelar" height="40px"  />
                                    </a>    
                                </div>
                                                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {mintStartHTML}
        <div className="row" id="event-landing-page-4b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'100vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'40px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'18px',letterSpacing:'3px'}}>
                        WELCOME {racer_name.toUpperCase()}
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'30px'}}>
                        REVEAL YOUR <span className="exr-base-blue-text">WEEK {race_number}</span> STACKS
                    </div>
                </div>
                <div id="weekly-stack-reveal-section" className="row" style={{display:'none',marginTop:'30px',marginBottom:'50px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        {revealWeeklyStackHTML}
                    </div>
                    <div className="row center-align" style={{marginTop:'50px',marginBottom:'100px',lineHeight:'1.1',padding:'0px 20px'}}>
                        <div className="col s12 m10 offset-m1 l7 offset-l3 white-text center-align">
                            <span className="black white-text base-blue-background" 
                                style={{cursor:'pointer',maxWidth:'700px',marginTop:'50px',marginLeft:'0px',marginRight:'0px',width:'100%',
                                        border:'0px solid white',fontSize:'20px',borderRadius:'60px',padding:'10px 20px 12px 30px',position:'relative'}} 
                                title="Continue to Stack Stats"
                                onClick={()=>viewWeeklyStackRaces()}>
                                <span className="hide-on-small-only show-on-med-and-up" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'37px'}}>
                                        expand_circle_down
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'20px',letterSpacing:'1px'}}>
                                    VIEW STACK ENTRY
                                    </span>
                                </span>
                                <span className="hide-on-med-and-up show-on-small-only" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'37px'}}>
                                        expand_circle_down
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'20px',letterSpacing:'1px'}}>
                                    VIEW STACK ENTRY
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div id="weekly-stack-reveal-button" className="row center-align" style={{marginTop:'200px',marginBottom:'100px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 l7 offset-l3 white-text center-align">
                        <div className="center-align" style={{width:'100%',position:'relative'}}>
                            <div id="reveal-confirm-button" className="black white-text base-blue-background" 
                                style={{cursor:'pointer',maxWidth:'700px',marginTop:'0px',marginLeft:'0px',marginRight:'0px',width:'100%',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'auto',position:'relative'}} 
                                title="Agree To League Rules & Terms To Continue"
                                onClick={()=> revealWeeklyStack(1)}>
                                <span className="hide-on-small-only show-on-med-and-up" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'50px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'30px',letterSpacing:'1px'}}>
                                    REVEAL YOUR STACK
                                    </span>
                                </span>
                                <span className="hide-on-med-and-up show-on-small-only" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'37px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'20px',letterSpacing:'1px'}}>
                                    REVEAL YOUR STACK
                                    </span>
                                </span>
                                
                            </div>
                            <span id="reveal-confirm-loading" className="preloader-wrapper small active event-register-formbutton center" 
                                style={{display:'none',position:'absolute',top:'0px',left:'45%',height:'50px',width:'50px',marginTop:'23px'}}>
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                
                
                
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-5b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'40px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        <span id="swapweekleft" className="material-icons white-text" 
                            style={{marginLeft:'10px',fontSize:'30px',verticalAlign:'text-bottom',cursor:'pointer'}}
                            title="Previous Race Week"
                            onClick={()=>switchRaceWeek('back')}>chevron_left</span>
                        <span style={{marginLeft:'10px'}}>
                            RACE WEEK {swapped_raceday_number}
                        </span>
                        <span id="swapweekright" className="material-icons white-text" 
                            style={{marginLeft:'10px',fontSize:'30px',verticalAlign:'text-bottom',cursor:'pointer'}}
                            title="Next Race Week"
                            onClick={()=>switchRaceWeek('next')}>chevron_right</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div id="race-weekly-inner-nav" className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-large-headline" style={{fontSize:'18px'}}>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link-active" style={{cursor:'pointer',maxWidth:'220px',marginBottom:'10px'}} 
                            onClick={()=>toggleRaceSections('race_results')} id="race_results_link">RACE RESULT</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px'}}
                            onClick={()=>toggleRaceSections('predict_race')} id="predict_race_link">PREDICTIONS</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'none'}}
                            onClick={()=>toggleRaceSections('stack_entry')} id="stack_entry_link">YOUR ENTRY</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{display:'none',cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px'}} 
                            onClick={()=>toggleRaceSections('race_lineup')} id="race_lineup_link">RACE LINEUP</span> 
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'none'}} 
                            onClick={()=>toggleRaceSections('stack_details')} id="stack_details_link">YOUR STACKS</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'none'}} 
                            onClick={()=>toggleRaceSections('race_details')} id="race_details_link">RACE DETAILS</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'block'}} 
                            onClick={()=>scrollToLeaderboards()}>LEADERBOARDS</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',marginBottom:'50px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        {weeklyRaceHTML}
                        {weeklyPredictionsHTML}
                        {weeklyStackDetailsHTML}
                        {weeklyRacedayDetailsHTML}
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-66b" style={{marginBottom:'0px',display:'none'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row exr-dark-grey-bg white-text center-align" style={{marginTop:'0px',marginBottom:'0px',padding:'30px 20px'}}>
                    <div className="col s12">
                        <div className="row headline-text exr-base-blue-text" style={{fontSize:'18px',marginTop:'10px',marginBottom:'10px'}}>BASE RACING LEAGUE SCHEDULE</div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 1 // COMPLETE</div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 20 JUN</div>
                        </div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 2 // COMPLETE</div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 27 JUN</div>
                        </div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 3 // COMPLETE</div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 04 JUL</div>
                        </div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 4 // <span className="exr-base-blue-text"> NOW</span></div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 11 JUL</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-6b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                {leaderboardsHTML}
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        
        

        <BaseHowWorksSplash />

        
        <div className="row" id="event-landing-page-7" style={{position:'relative',background:'black',width:'100vw',maxHeight:'70vh',overflow:'hidden',marginBottom:'0px'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="row center-align" style={{marginTop:'0px',marginBottom:'0px'}}>
                        <img src="https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg" style={{width:"100vw"}} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row base-blue-background howtoplay" id="event-landing-page-9" style={{display:'block',position:'relative',minWidth:'100vw',minHeight:'70vh',marginBottom:'0px'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="row center-align firstsection" style={{marginBottom:'10px'}}>
                        <div className="col s12 m10 offset-m1 white-text">
                            <div className="row white-text" style={{margin:'0px',padding:'0px',marginTop:'30px'}}>
                                <span className="headline-text white-text event-register-large-headline" style={{padding:'10px 20px',borderBottom:'2px solid white'}}>
                                    SUPPORTED BY
                                </span>
                            </div>
                            <div className="row black-text" style={{margin:'0px',padding:'0px',marginTop:'80px',marginBottom:'50px',lineHeight:'1.1'}}>
                                <div className="col s12 m12 l4" style={{margin:'0px',padding:'0px'}}>
                                    <div className="left" style={{width:'100%',padding:'30px'}}>
                                        <div className="row headline-text" style={{fontSize:'18px',padding:'0px',marginBottom:'30px'}}>
                                            GAME SPONSOR
                                        </div>
                                        <div className="row" style={{padding:'0px',height:'100px'}}>
                                            <a href="https://www.exiledracers.com/" target="_blank" title="Exiled Racers">
                                                <img src={logo_image} style={{marginTop:'-5px',height:'60px'}} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 l4" style={{margin:'0px',padding:'0px'}}>
                                    <div className="left" style={{width:'100%',padding:'30px'}}>
                                        <div className="row headline-text" style={{fontSize:'18px',padding:'0px',marginBottom:'30px'}}>
                                            CHAIN PLATFORM
                                        </div>
                                        <div className="row" style={{padding:'0px',height:'100px'}}>
                                            <a href="https://www.base.org/" target="_blank" title="BASE">
                                                <img src={base_logo_white} style={{height:'60px',marginRight:'50px',verticalAlign:'text-bottom'}} />
                                            </a>
                                            <a href="https://moonbeam.network/" target="_blank" title="Moonbeam Network">
                                                <img src={moonbeam_logo} style={{height:'60px',verticalAlign:'text-bottom'}} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 l4" style={{margin:'0px',padding:'0px'}}>
                                    <div className="left" style={{width:'100%',padding:'30px'}}>
                                        <div className="row headline-text" style={{fontSize:'18px',padding:'0px',marginBottom:'30px'}}>
                                            xCHAIN PARTNER
                                        </div>
                                        <div className="row" style={{padding:'0px',height:'100px'}}>
                                            <a href="https://axelar.network/" target="_blank" title="Axelar">
                                                <img src={axelar_full_logo_white} style={{height:'45px'}} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};