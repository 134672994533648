export var laps_tooltip = '<div class="row" style="width:200px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    laps_tooltip = laps_tooltip +'Laps <a class="exr-red-text">//</a> Strength</div>';
    laps_tooltip = laps_tooltip +'<div class="row" style="margin-bottom:0px;">';
    laps_tooltip = laps_tooltip +'Tracks with more laps require Pilots with more Stamina and Racecrafts with more Strength.';
    laps_tooltip = laps_tooltip +'</div></div>';

export var turns_tooltip = '<div class="row" style="width:200px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    turns_tooltip = turns_tooltip +'Turns <a class="exr-red-text">//</a> Handling</div>';
    turns_tooltip = turns_tooltip +'<div class="row" style="margin-bottom:0px;">';
    turns_tooltip = turns_tooltip +'Tracks with more turn segments require Racecrafts with better Handling.';
    turns_tooltip = turns_tooltip +'</div></div>';

export var straights_tooltip = '<div class="row" style="width:200px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    straights_tooltip = straights_tooltip +'Straights <a class="exr-red-text">//</a> Speed</div>';
    straights_tooltip = straights_tooltip +'<div class="row" style="margin-bottom:0px;">';
    straights_tooltip = straights_tooltip +'Tracks with more straight segments require Racecrafts with higher Speed.';
    straights_tooltip = straights_tooltip +'</div></div>';

export var shifts_tooltip = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    shifts_tooltip = shifts_tooltip +'Speed Shifts <a class="exr-red-text">//</a> Acceleration</div>';
    shifts_tooltip = shifts_tooltip +'<div class="row" style="margin-bottom:0px;">';
    shifts_tooltip = shifts_tooltip +'Tracks with more speed shifts require Racecrafts with higher Acceleration.';
    shifts_tooltip = shifts_tooltip +'</div></div>';

export var genetic_tooltip = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    genetic_tooltip = genetic_tooltip +'Genetic Boost</div>';
    genetic_tooltip = genetic_tooltip +'<div class="row" style="margin-bottom:0px;">';
    genetic_tooltip = genetic_tooltip +'Genetic Boosts are applied when the Pilot and Racecraft are from the same faction. Genetic Boosts add <a class="exr-yellow-text">3 points</a> towards <a class="exr-yellow-text">Handling</a> and <a class="exr-yellow-text">Focus.</a>';
    genetic_tooltip = genetic_tooltip +'</div></div>';

export var genetic_max_tooltip = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    genetic_max_tooltip = genetic_max_tooltip +'Genetic Boost Max</div>';
    genetic_max_tooltip = genetic_max_tooltip +'<div class="row" style="margin-bottom:0px;">';
    genetic_max_tooltip = genetic_max_tooltip +'Genetic Boost Max is applied when the Pilot, Racecraft and Booster are from the same faction. Genetic Boost Max adds <a class="exr-yellow-text">4 points</a> towards <a class="exr-yellow-text">Handling</a> and <a class="exr-yellow-text">Focus.</a>';
    genetic_max_tooltip = genetic_max_tooltip +'</div></div>';

export var genetic_track_tooltip = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    genetic_track_tooltip = genetic_track_tooltip +'Genetic Track Boost</div>';
    genetic_track_tooltip = genetic_track_tooltip +'<div class="row" style="margin-bottom:0px;">';
    genetic_track_tooltip = genetic_track_tooltip +'Genetic Track Boosts are applied when the Pilot or Racecraft match the Track\'s Planetary System faction. Genetic Track Boosts add <a class="exr-yellow-text">2 points</a> towards <a class="exr-yellow-text">Speed</a> for matching Racecrafts and <a class="exr-yellow-text">2 points</a> towards <a class="exr-yellow-text">Intelligence</a> for matching Pilots.';
    genetic_track_tooltip = genetic_track_tooltip +'</div></div>';

export var genetic_tooltip_wtrack = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    genetic_tooltip_wtrack = genetic_tooltip_wtrack +'Genetic Boost</div>';
    genetic_tooltip_wtrack = genetic_tooltip_wtrack +'<div class="row" style="margin-bottom:10px;">';
    genetic_tooltip_wtrack = genetic_tooltip_wtrack +'Genetic Boosts are applied when the Pilot and Racecraft are from the same faction. Genetic Boosts add <a class="exr-yellow-text">3 points</a> towards <a class="exr-yellow-text">Handling</a> and <a class="exr-yellow-text">Focus.</a>';
    genetic_tooltip_wtrack = genetic_tooltip_wtrack +'</div>';
    genetic_tooltip_wtrack = genetic_tooltip_wtrack +'<div class="row" style="margin-bottom:0px;">';
    genetic_tooltip_wtrack = genetic_tooltip_wtrack +'This stack also has a Genetic Track Boost that adds <a class="exr-yellow-text">2 points</a> towards <a class="exr-yellow-text">Speed</a> for matching Racecrafts and <a class="exr-yellow-text">2 points</a> towards <a class="exr-yellow-text">Intelligence</a> for matching Pilots.';
    genetic_tooltip_wtrack = genetic_tooltip_wtrack +'</div></div>';

export var genetic_max_tooltip_wtrack = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    genetic_max_tooltip_wtrack = genetic_max_tooltip_wtrack +'Genetic Boost Max</div>';
    genetic_max_tooltip_wtrack = genetic_max_tooltip_wtrack +'<div class="row" style="margin-bottom:10px;">';
    genetic_max_tooltip_wtrack = genetic_max_tooltip_wtrack +'Genetic Boost Max is applied when the Pilot, Racecraft and Booster are from the same faction. Genetic Boost Max adds <a class="exr-yellow-text">4 points</a> towards <a class="exr-yellow-text">Handling</a> and <a class="exr-yellow-text">Focus.</a>';
    genetic_max_tooltip_wtrack = genetic_max_tooltip_wtrack +'</div>';
    genetic_max_tooltip_wtrack = genetic_max_tooltip_wtrack +'<div class="row" style="margin-bottom:0px;">';
    genetic_max_tooltip_wtrack = genetic_max_tooltip_wtrack +'This stack also has a Genetic Track Boost that adds <a class="exr-yellow-text">2 points</a> towards <a class="exr-yellow-text">Speed</a> for matching Racecrafts and <a class="exr-yellow-text">2 points</a> towards <a class="exr-yellow-text">Intelligence</a> for matching Pilots.';
    genetic_max_tooltip_wtrack = genetic_max_tooltip_wtrack +'</div></div>';

export var training_tier_tooltip = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;">';
    training_tier_tooltip = training_tier_tooltip +'Training Prize Tiers</div>';
    training_tier_tooltip = training_tier_tooltip +'<div class="row left-align" style="margin-bottom:0px;">';
    training_tier_tooltip = training_tier_tooltip +'1st Place: 50 RPM<br/><br/>2nd Place: 25 RPM<br/><br/>';
    training_tier_tooltip = training_tier_tooltip +'</div></div>';

export var prize_tier_tooltip = '<div class="row" style="width:300px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;font-family:helvetica">';
    prize_tier_tooltip = prize_tier_tooltip +'Race Prize Tiers</div>';
    prize_tier_tooltip = prize_tier_tooltip +'<div class="row left-align" style="margin-bottom:20px;font-family:helvetica">';
    prize_tier_tooltip = prize_tier_tooltip +'<b>2 Racer Prize Pool</b><br/>1st: 75% <a class="exr-red-text">//</a> 2nd: 25%';
    prize_tier_tooltip = prize_tier_tooltip +'</div>';
    prize_tier_tooltip = prize_tier_tooltip +'<div class="row left-align" style="margin-bottom:20px;font-family:helvetica">';
    prize_tier_tooltip = prize_tier_tooltip +'<b>3 Racer Prize Pool</b><br/>1st: 70% <a class="exr-red-text">//</a> 2nd: 20% <a class="exr-red-text">//</a> 3rd: 10%';
    prize_tier_tooltip = prize_tier_tooltip +'</div>';
    prize_tier_tooltip = prize_tier_tooltip +'<div class="row left-align" style="margin-bottom:10px;font-family:helvetica">';
    prize_tier_tooltip = prize_tier_tooltip +'<b>4+ Racer Prize Pool</b><br/>1st: 65% <a class="exr-red-text">//</a> 2nd: 20% <a class="exr-red-text">//</a><br/>3rd: 10% <a class="exr-red-text">//</a> Remaining Split: 5%';
    prize_tier_tooltip = prize_tier_tooltip +'</div>';
    prize_tier_tooltip = prize_tier_tooltip +'<div id="tooltip-prize-legendary" class="row left-align" style="margin-bottom:10px;font-family:helvetica;display:none;display:none;">';
    prize_tier_tooltip = prize_tier_tooltip +'<a class="exr-yellow-text"><b>Legendary / Exiled Bonus:</b></a><br/>+5 RPM for Winner';
    prize_tier_tooltip = prize_tier_tooltip +'</div>';
    prize_tier_tooltip = prize_tier_tooltip +'</div></div>';

export var prize_wta_tooltip = '<div class="row" style="width:300px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-5px;margin-bottom:10px;padding-bottom:10px;font-size:16px;border-bottom:1px solid white;font-family:helvetica">';
    prize_wta_tooltip = prize_wta_tooltip +'Winner Take All Race</div>';
    prize_wta_tooltip = prize_wta_tooltip +'<div class="row left-align" style="margin-bottom:20px;font-family:helvetica">';
    prize_wta_tooltip = prize_wta_tooltip +'<b>1st Place:</b><br/> Takes 100% of Prize Pool';
    prize_wta_tooltip = prize_wta_tooltip +'</div>';
    prize_wta_tooltip = prize_wta_tooltip +'<div id="tooltip-prize-legendary" class="row left-align" style="margin-bottom:10px;font-family:helvetica;display:none;display:none;">';
    prize_wta_tooltip = prize_wta_tooltip +'<a class="exr-yellow-text"><b>Legendary / Exiled Bonus:</b></a><br/>+5 RPM for Winner';
    prize_wta_tooltip = prize_wta_tooltip +'</div>';
    prize_wta_tooltip = prize_wta_tooltip +'</div></div>';