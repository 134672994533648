import {Blip} from "./blip.js";

/**
 * A race minimap
 */
export class Minimap {
    static TRACK_WIDTH_SPACING = 3;
    static TRACK_WIDTH_DEFAULT = 16;

    #map;
    #element;

    /**
     * Construct a minimap
     * @param {TrackMap} map The track map for this minimap
     * @param {HTMLElement | null} element The minimap element, or null if this is the track designer
     */
    constructor(map, element) {
        this.#map = map;
        this.#element = element;
    }

    /**
     * Create the minimap element
     * @param {Racers} [racers] The racers, if any
     * @returns {Blip[]} All track blips for this minimap
     */
    populate(racers = null) {
        const blips = [];

        if (racers) for (let racer = 0, racerCount = racers.racers.length; racer < racerCount; ++racer)
            blips.push(new Blip(racers.racers[racer].data.color, racer === 0));

        this.#element.appendChild(
            this.#map.createElement(this.#element.clientWidth,
                this.#element.clientHeight,
                racers ? racers.trackWidth + Minimap.TRACK_WIDTH_SPACING : Minimap.TRACK_WIDTH_DEFAULT,
                blips));

        return blips;
    }

    /**
     * Remove elements
     */
    depopulate() {
        while (this.#element.firstChild)
            this.#element.removeChild(this.#element.firstChild);
    }
}