import * as THREE from "three";
import {glslConstants} from "../../glsl/glslConstants.js";
import {glslSimplexNoise} from "../../glsl/glslSimplexNoise.js";
import {glslHash} from "../../glsl/glslHash.js";

export const effectBannerScanLines = new THREE.ShaderMaterial({
    // language=GLSL
    vertexShader: glslConstants + glslSimplexNoise + `
        uniform float barPhase;
        uniform float time;
        
        out vec2 vUv;
        out float phase;
        out float brightness;
        flat out float shiftOffset;
        
        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
            vUv = uv;
            phase = position.y / barPhase + time;
            
            const float brightnessRadius = .7;
            const float brightnessPower = 2.5;
            
            float brightnessAngle = time * TAU;
            
            brightness = pow(.5 + .5 * snoise(position + vec3(cos(brightnessAngle), 0, sin(brightnessAngle)) * brightnessRadius), brightnessPower);
            shiftOffset = position.x + position.y;
        }
        `,
    // language=GLSL
    fragmentShader: glslConstants + glslHash + `
        uniform sampler2D bannerTexture;
        
        uniform float time;
        uniform float glowBoost;
        uniform float alphaBoost;
        uniform float aspect;
        
        in vec2 vUv;
        in float phase;
        in float brightness;
        flat in float shiftOffset;
        
        #define LOD_GLOW .5
        #define LOD_BRIGHTNESS 6.
        #define BRIGHTNESS_GLOW 1.1
        #define SHIFT_MAGNITUDE 15.5
        #define EDGE 48.
        #define EDGE_THRESHOLD (1. / EDGE)
        
        void main() {
            #ifdef FLIP_SIDED
            vec2 uv = vec2(1. - vUv.x, vUv.y);
            #else
            vec2 uv = vUv;
            #endif
            
            uv.x = .5 + (uv.x - .5) * (1. / min(1., aspect));
            uv.y = .5 + (uv.y - .5) * max(1., aspect);
            
            float edgeStrength = max(
                0.,
                1. - EDGE * max(0., max(abs(uv.y - .5), abs(uv.x - .5)) - .5 + EDGE_THRESHOLD));
            float bar = .5 + .5 * cos(phase * TAU);
            float shift = max(0., hash(floor(phase + .5) + time + shiftOffset) - .98);
            
            uv.x += shift * shift * sign(time - .5) * SHIFT_MAGNITUDE;
            
            gl_FragColor = texture2D(bannerTexture, uv);
            gl_FragColor.rgb *= glowBoost * bar;
            gl_FragColor.a *= 1. - alphaBoost + alphaBoost * bar;

            float glow = max(0., 1. - gl_FragColor.a);
            
            gl_FragColor += textureLod(bannerTexture, uv, LOD_GLOW) * glow;
            gl_FragColor += textureLod(bannerTexture, uv, LOD_BRIGHTNESS) * BRIGHTNESS_GLOW * brightness;
            
            gl_FragColor.a *= edgeStrength;
        }
        `,
    side: THREE.DoubleSide,
    blending: THREE.AdditiveBlending,
    transparent: true,
    depthWrite: false,
    uniforms: {
        bannerTexture: null,
        time: {
            value: 0
        },
        barPhase: {
            value: .1
        },
        glowBoost: {
            value: 1.3
        },
        alphaBoost: {
            value: .6
        },
        aspect: {
            value: 1
        }
    }
});