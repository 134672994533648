import {TrackMap} from "../track/map/trackMap.js";
import {StyleUtils} from "../utils/styleUtils.js";

/**
 * A blip on the map
 */
export class Blip {
    static CLASS = "blip";
    static CLASS_SPECIAL = Blip.CLASS + " special";
    static RADIUS = StyleUtils.getFloat("--minimap-blip-radius");
    static RIGHT = StyleUtils.getFloat("--minimap-blip-right");
    static LEFT = StyleUtils.getFloat("--minimap-blip-left");

    #element = document.createElementNS(TrackMap.SVG_URI, "path");

    /**
     * Construct a blip
     * @param {THREE.Color} color The blip color
     * @param {boolean} [special] True if this blip needs extra highlight, false by default
     */
    constructor(color, special = false) {
        this.#element.setAttribute("class", special ? Blip.CLASS_SPECIAL : Blip.CLASS);
        this.#element.setAttribute("fill", color.getStyle());
        this.#element.setAttributeNS(null, "d",
            "M " + Blip.RIGHT + " 0" +
            "L " + Blip.LEFT + " " + (-Blip.RADIUS) +
            "L 0 0" +
            "L " + Blip.LEFT + " " + Blip.RADIUS +
            "Z");
    }

    /**
     * Get the element
     * @returns {SVGPathElement} The element
     */
    get element() {
        return this.#element;
    }

    /**
     * Set the blip position
     * @param {number} x The X position
     * @param {number} y The Y position
     * @param {number} direction The direction
     */
    setPosition(x, y, direction) {
        this.#element.setAttribute(
            "transform", "translate(" + x.toString() + "," + y.toString() + ")" +
            "rotate(" + direction.toString() + ")");
    }
}