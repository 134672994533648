import {ModelCache} from "./cache/modelCache.js";
import {CubeMapCache} from "./cache/cubeMapCache.js";
import {TextureCache} from "./cache/textureCache.js";
import {HDRCache} from "./cache/hdrCache.js";

export class Globals {
    static MODEL_CACHE = new ModelCache();
    static TEXTURE_CACHE = new TextureCache();
    static CUBE_MAP_CACHE = new CubeMapCache();
    static HDR_CACHE = new HDRCache();
}