import {GlobalCache} from "./globalCache.js";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader.js";
import {LoadTracker} from "../loadTracker.js";
import * as THREE from "three";

export class HDRCache extends GlobalCache {
    constructor() {
        super();

        this.loader = new EXRLoader();
        this.generator = null;
    }

    /**
     * Set the renderer
     * @param {THREE.WebGLRenderer} renderer The renderer
     */
    setRenderer(renderer) {
        this.generator = new THREE.PMREMGenerator(renderer);
    }

    /**
     * Load an asset from a server
     * @param {string} asset The asset url
     * @param {function} onLoaded A function that returns with the loaded asset after loading completes
     */
    loadFromServer(asset, onLoaded) {
        LoadTracker.register();

        this.loader.load(asset, (texture) => {
            const PMREM = this.generator.fromEquirectangular(texture).texture;

            onLoaded([PMREM, texture]);

            LoadTracker.resolve();
        });
    }
}