export const setReady = () => {
  console.log("setting ready");
  return async (dispatch) => {
    dispatch({
      type: "SET_IS_READY",
    });
  };
};

export const setRacerID = (racerID) => {
  // localStorage.setItem('exrRacerID', racerID);
  localStorage.setItem('testerEXRRacerID', racerID);
  return async (dispatch) => {
    dispatch({
      type: "SET_RACER_ID",
      payload: racerID,
    });
  };
};

export const setLoginWallet = (walletID) => {
  localStorage.setItem('exrRacerWallet', walletID);
  return async (dispatch) => {
    dispatch({
      type: "SET_ACCOUNT_ADDRESS",
      payload: walletID,
    });
  };
};

export const setRacerLogin = (walletID,racerID) => {
  // localStorage.setItem('exrWalletID', walletID);
  // localStorage.setItem('exrRacerID', racerID);
  localStorage.setItem('testerEXRWalletID', walletID);
  localStorage.setItem('testerEXRRacerID', racerID);
  return async (dispatch) => {
    dispatch({
      type: "SET_RACER_LOGIN",
      payload: {racer_id: racerID, wallet_id: walletID},
    });
  };
};

export const setUserSignOn = (userID, racerID, spectatorID, loginToken, loginTime) => {
  // localStorage.setItem('exrWalletID', walletID);
  let tokenval = userID+"."+racerID+"."+spectatorID;
  localStorage.setItem('exrTokenID', loginToken);
  localStorage.setItem('exrTokenTime', loginTime);
  localStorage.setItem('exrTokenVal', tokenval);
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_SIGNON",
      payload: {user_id: userID, racer_id: racerID, spectator_id: spectatorID, token: loginToken, token_time: loginTime},
    });
  };
};

export const clearUserSignOn = () => {
  // localStorage.setItem('exrWalletID', walletID);
  localStorage.removeItem('exrTokenID');
  localStorage.removeItem('exrTokenTime');
  localStorage.removeItem('exrTokenVal');
  localStorage.removeItem('exrRacerWallet');
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_SIGNON",
      payload: {user_id: 0, racer_id: 0, spectator_id: 0, token: '', token_time: ''},
    });
    dispatch({
      type: "SET_ACCOUNT_ADDRESS",
      payload: '',
    });
  };
};