import {TrackPiece} from "./trackPiece.js";

/**
 * A straight track piece
 */
export class TrackPieceStraight extends TrackPiece {
    /**
     * Construct a straight track piece
     * @param {number} length The piece length in meters
     * @param {number} index The index of this piece in the list
     */
    constructor(length, index) {
        super(length, index);
    }

    /**
     * Sample this track piece
     * @param {Vector3} target The target vector to write to
     * @param {number} t The position to sample at in the range [0, 1]
     * @returns {Vector3} The target
     */
    sample(target, t) {
        target.x = t * this.length;
        target.y = target.z = 0;

        return this.transformByOffset(target);
    }
}
