import {TrackPropOverrideForceField} from "./trackPropOverrideForceField.js";
import {TrackPropOverrideBanner} from "./trackPropOverrideBanner.js";
import {TrackPropOverrideStartLight} from "./trackPropOverrideStartLight.js";
import {TrackPropOverrideBannerLight} from "./trackPropOverrideBannerLight.js";

export const trackPropOverrides = [
    TrackPropOverrideForceField,
    TrackPropOverrideBanner,
    TrackPropOverrideStartLight,
    TrackPropOverrideBannerLight
];