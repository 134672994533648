import {Globals} from "../../../global/globals.js";
import * as THREE from "three";

/**
 * A track prop
 */
export class TrackProp {
    static ENV_MAP_INTENSITY = 18;

    model;
    instances;
    scene;
    generated;
    passAudio;

    /**
     * Construct a prop
     * @param {string | null} model The model
     * @param {TrackPropInstance[]} instances The instances for this prop
     * @param {boolean} [generated] True if this prop was generated and doesn't need to be saved separately
     * @param {boolean} [passAudio] True if this prop should play pass by audio when passed by the camera
     */
    constructor(
        model,
        instances,
        generated = false,
        passAudio = false) {
        this.model = model;
        this.instances = instances;
        this.scene = null;
        this.generated = generated;
        this.passAudio = passAudio;
    }

    /**
     * Load this prop
     * @returns {Promise} A promise which resolves when the prop was loaded
     */
    load() {
        return new Promise(resolve => {
            Globals.MODEL_CACHE.load(this.model).then(scene => {
                this.scene = scene.scene;
                this.scene.traverse(node => {
                    if (node instanceof THREE.Mesh) {
                        node.material.envMapIntensity = TrackProp.ENV_MAP_INTENSITY;
                    }
                })

                resolve();
            });
        });
    }

    /**
     * Update the state
     */
    update() {
        for (let instance = 0, instanceCount = this.instances.length; instance < instanceCount; ++instance)
            this.instances[instance].update();
    }

    /**
     * Render a frame
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        for (let instance = 0, instanceCount = this.instances.length; instance < instanceCount; ++instance)
            this.instances[instance].render(time);
    }
}