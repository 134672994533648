import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { clearUserSignOn } from "../../../redux/Account/actions";
import {startPageModals} from "../../../utils";

import $ from "jquery";
let web3;
let user_type = 'public';
let arenalink = '';
let leaderboardlink = '';

type Props = {
    walletReturn: (wconnect: any, walletaddress: any)=>void;
}

const GlobalHeaderPublic = (props:Props) => {
    const dispatch = useDispatch();
    const [headerSet, setHeader] = useState(false);
    const [leftHeader, setLeftHeader] = useState('');
    const [rightHeader, setRightHeader] = useState('');
    const [mobileNav, setMobileNav] = useState('');

    function goToRacerHome() {
        if(user_type == 'racer') {
            window.location.href= '/#/workshop';
        } else {
            window.location.href= '/#/raceway';
        }
    }
    function goToRacesHome() {
        if(user_type == 'racer') {
            window.location.href= '/#/races_home';
        } else {
            window.location.href= '/#/raceway';
        }
    }
    function goToLeaguesHome() {
        if(user_type == 'racer') {
            window.location.href= '/#/races_home/leagues';
        } else {
            window.location.href= '/#/raceway';
        }
    }

    function goToLeaderboards() {
        window.location.href= '/#/open_leaderboard';
    }

    function goToRewards() {
        window.location.href= '/#/rewards';
    }

    function goToArenaHome() {
        window.location.href= '/#/raceway';
    }
    
    function goToAboutPages() {
        window.location.href= '/#/gameplay';
    }

    function goToCollection() {
        window.open('https://view.exiledracers.com/');
    }

    function goToLoginForm() {
        window.location.href= '/#/signin';
    }

    function logoutGame() {
        window.location.href= '/';
    }

    const rightsidelinks = <><div className="top-nav-header-right-item-full white-fill-link" style={{lineHeight:'1.5'}}  onClick={()=>goToLoginForm()}>
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Connect Wallet
                                </div>
                            </div></>;


    const mobilelinks = <><div className="row" style={{padding:'0px 30px',marginTop:'10px',marginBottom:'10px'}}>
                                <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" height="50px" className="cursor-pointer" onClick={()=>goToRacerHome()} />
                            </div>
                            <div className="divider"></div>
                            <div className={'row white-fill-link'+arenalink} style={{padding:'5px 30px',marginTop:'10px'}}  onClick={()=>goToRacerHome()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Raceway
                                </div>
                            </div>
                            <div className={'row white-fill-link'+leaderboardlink} style={{padding:'5px 30px'}} onClick={()=>goToLeaderboards()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Leaderboards
                                </div>
                                <div id="header-new-races-text" className="top-nav-header-item-badge exr-red-bg white-text" style={{display:'none'}}>
                                </div>
                            </div>
                            <div className="row white-fill-link" style={{padding:'5px 30px'}} onClick={()=>goToLoginForm()}>
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Login
                                </div>
                            </div>
                            <div className="row divider"></div>
                        </>;

    if(headerSet == false) {
        setHeader(true);
        setTimeout(function(){
            startPageModals();
        },500);
    }

    return(<div className="row white-text page-width-wide top-nav-header-full">
                <nav className="transparent z-depth-0">
                <div className="col s12 transparent nav-wrapper" style={{marginTop:'5px',marginBottom:'0px',position:'relative'}}>
                        <div className="left center-align" style={{lineHeight:'1',marginBottom:'0px',width:'100%',fontSize:'30px',verticalAlign:'text-top'}}>
                            <span>
                                <img src="https://media.exiledracers.com/assets/game/builder/Zeitgeist_Logo_White_Large_1708824090834.png" 
                                height="50px" 
                                className="cursor-pointer" 
                                onClick={()=>goToRacerHome()} 
                                style={{marginRight:'20px'}} /> 
                            </span>
                            <span></span>
                            <span>
                                <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" 
                                    style={{marginLeft:'20px'}} 
                                    height="50px" 
                                    className="cursor-pointer" 
                                    onClick={()=>goToRacerHome()} />
                            </span>
                            
                        </div>
                        <div id="wallet-header-nav" className="show-on-med-and-up hide-on-small-only" 
                            style={{position:'absolute',top:'0px',right:'0px',width:'300px'}}>
                        </div> 
                        <div data-target="mobile-header-nav" className="sidenav-trigger right show-on-med-and-down hide-on-large-only">
					    	<i className="top-nav-header-left-item material-icons" style={{fontSize:'28px',marginTop:'2px',marginBottom:'0px',cursor:'pointer'}}>menu</i>
					    </div>
                    </div>
                </nav>
                <ul id="settings-dropdown" className="dropdown-content transparent subheadline-text">
                    <li className="white center-align white-red-button" style={{borderRadius:'2px',marginTop:'12px'}}>
                        <a className="modal-trigger black-text white-red-button center-align" data-target="settingsbox" style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>SETTINGS</a>
                    </li>
                    <li className="white-red-button center-align" style={{borderRadius:'2px',fontSize:'12px',marginTop:'10px'}}>
                        <a className="black-text white-red-button center-align" onClick={()=>logoutGame()} style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>LOGOUT</a>
                    </li>
                </ul>
                <ul className="sidenav exr-new-dark-grey" id="mobile-header-nav" style={{padding:'10px 20px'}}>
                    {mobilelinks}
                </ul>
        </div>);
};

export default GlobalHeaderPublic;