import * as THREE from "three";
import {Bounds} from "../../../../exr-webgl-hub/math/bounds.js";

/**
 * A 2d track map for minimaps & previews
 */
export class TrackMap {
    static SVG_URI = "http://www.w3.org/2000/svg";
    static DEFAULT_WIDTH = 17;
    static DECIMAL_PRECISION = 4;
    static PADDING = 8;
    static SLICE_INTERVAL = 2;
    static CLASS_TRACK = "track";
    static CLASS_FINISH = "finish";
    static CLASS_BLIPS = "blips";

    /**
     * Construct a track map
     * @param {Track} track The track to construct a map for
     */
    constructor(track) {
        this.track = track;
    }

    /**
     * Create an element containing the track map
     * @param {number} width The maximum width
     * @param {number} height The maximum height
     * @param {number} trackWidth The track width
     * @param {Blip[]} [blips] Blips to add to the track, if any
     * @returns {SVGSVGElement} The SVG element with the track shape
     */
    createElement(width, height, trackWidth, blips = null) {
        return TrackMap.createTrackShape(this.track.createSlices(TrackMap.SLICE_INTERVAL), width, height, blips, trackWidth);
    }

    /**
     * Calculate the bounds for a given set of track slices and a track width
     * @param {TrackSlice[]} slices The slices
     * @param {number} width The track width
     * @returns {Bounds} bounds The track shape bounds
     */
    static calculateBounds(slices, width) {
        let left = 0;
        let top = 0;
        let right = 0;
        let bottom = 0;

        for (let slice = 0, sliceCount = slices.length; slice < sliceCount; ++slice) {
            if (slices[slice].position.x < left)
                left = slices[slice].position.x;
            else if (slices[slice].position.x > right)
                right = slices[slice].position.x;

            if (slices[slice].position.z < top)
                top = slices[slice].position.z;
            else if (slices[slice].position.z > bottom)
                bottom = slices[slice].position.z;
        }

        return new Bounds(
            left - width * .5 - TrackMap.PADDING,
            top - width * .5 - TrackMap.PADDING,
            right + width * .5 + TrackMap.PADDING,
            bottom + width * .5 + TrackMap.PADDING);
    }

    /**
     * Create the track shape
     * @param {TrackSlice[]} slices Track slices to model the track on
     * @param {number} width The maximum width
     * @param {number} height The maximum height
     * @param {Blip[]} [blips] Blips to add to the track, if any
     * @param {number} [trackWidth] The track width
     * @returns {SVGSVGElement} The SVG element with the track shape
     */
    static createTrackShape(slices, width, height, blips = null, trackWidth = TrackMap.DEFAULT_WIDTH) {
        slices.push(slices[0]);

        const element = document.createElementNS(TrackMap.SVG_URI, "svg");
        
        const bounds = TrackMap.calculateBounds(slices, trackWidth);
        const scale = Math.min(width / bounds.width, height / bounds.height);
        const scaledSize = new THREE.Vector2(bounds.width, bounds.height).multiplyScalar(scale);
        const group = document.createElementNS(TrackMap.SVG_URI, "g");
        const path = document.createElementNS(TrackMap.SVG_URI, "path");
        const sliceCount = slices.length;
        let pathString = "M" +
            (slices[0].position.x + Math.sin(slices[0].direction) * trackWidth * .5).toFixed(TrackMap.DECIMAL_PRECISION) + "," +
            (slices[0].position.z - Math.cos(slices[0].direction) * trackWidth * .5).toFixed(TrackMap.DECIMAL_PRECISION) + " ";

        group.setAttributeNS(null, "transform",
            "translate(" +
                ((width - scaledSize.x) * .5).toFixed(TrackMap.DECIMAL_PRECISION) + " " +
                (height - scaledSize.y).toFixed(TrackMap.DECIMAL_PRECISION) + ")" +
            "scale(" + scale.toFixed(TrackMap.DECIMAL_PRECISION) + " " + scale.toFixed(TrackMap.DECIMAL_PRECISION) + ")" +
            "translate(" +
                (-bounds.left).toFixed(TrackMap.DECIMAL_PRECISION) + " " +
                (-bounds.top).toFixed(TrackMap.DECIMAL_PRECISION) + ")");

        for (let slice = 1; slice < sliceCount; ++slice)
            pathString += "L" +
                (slices[slice].position.x + Math.sin(slices[slice].direction) * trackWidth * .5).toFixed(TrackMap.DECIMAL_PRECISION) + "," +
                (slices[slice].position.z - Math.cos(slices[slice].direction) * trackWidth * .5).toFixed(TrackMap.DECIMAL_PRECISION) + " ";

        for (let slice = sliceCount; slice-- > 0;)
            pathString += "L" +
                (slices[slice].position.x - Math.sin(slices[slice].direction) * trackWidth * .5).toFixed(TrackMap.DECIMAL_PRECISION) + "," +
                (slices[slice].position.z + Math.cos(slices[slice].direction) * trackWidth * .5).toFixed(TrackMap.DECIMAL_PRECISION) + " ";

        pathString += "Z";

        path.setAttributeNS(null, "d", pathString);
        path.classList.add(TrackMap.CLASS_TRACK);

        group.appendChild(path)

        const finish = document.createElementNS(TrackMap.SVG_URI, "path");

        finish.setAttributeNS(null, "d","M 0 " + (-trackWidth * .5) + "L 0 " + (trackWidth * .51));
        finish.classList.add(TrackMap.CLASS_FINISH);

        group.appendChild(finish);

        if (blips) {
            const blipGroup = document.createElementNS(TrackMap.SVG_URI, "g");

            blipGroup.classList.add(TrackMap.CLASS_BLIPS);

            for (const blip of blips)
                blipGroup.appendChild(blip.element);

            group.appendChild(blipGroup);
        }

        element.appendChild(group);

        return element;
    }
}