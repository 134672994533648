/**
 * The track loader for race entry pages
 */
export class TrackLoader {
    /**
     * Load a race
     * @param {Track} track Track data
     * @param {RaceDesign} design The race design
     * @param {function} onLoaded A function that is called when loading finished
     */
    constructor(track, onLoaded) {
        let loading = 2;
        this.track = null;

        const load = () => {
            onLoaded();
        };

        track.load().then(() => {
            this.track = track;

            load();
        });
    }
}