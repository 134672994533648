import { useEffect, useState } from "react";
import $ from "jquery";

export const BaseHowWorksSplash = (props:any) => {
    const [pageSet, setPage] = useState(false);

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Black_Ship_Splash_Background_1704920753949.png';
    let bg_image_ship = 'https://media.exiledracers.com/assets/game/builder/Ship_Bottom_Background_1704950678438.png';
    let bg_primary_splash = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Primary_Splash_Background_1709765585438.png';
    let bg_image_left = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Left_1709765609425.png';
    let bg_image_right = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Right_1709765624764.png';
    let bg_blue_smoke = 'https://media.exiledracers.com/assets/game/builder/Base_Blue_Smoke_Background_1712349198139.png';

    function scrollToLearnMore() {
        $('html, body').animate({
            scrollTop: $("#how-works-page-1").offset()?.top
         }, 1000);
    }

    if(pageSet === false) {
        setPage(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div className="howtoplay" style={{position:'relative',width:'100%',minHeight:'80vh'}}>
        <div className="howtoplay" style={{width:'100%',height:'100vh',position:'fixed',top:'0px',left:'0px',backgroundImage:'url('+bg_image_left+')',backgroundSize:'cover',zIndex:'-1000'}}>
        </div>
        <div className="row howtoplay" id="how-works-page-00" style={{display:'none',position:'relative',background:'black',width:'100vw',minHeight:'80vh',
                                                            backgroundImage:'url('+bg_blue_smoke+')',backgroundSize:'cover',backgroundPosition:'center',
                                                            marginBottom:'0px',paddingBottom:'50px'}}>
            
            <div className="col s12 center-align white-text headline-font" 
                style={{width:'100%',marginTop:'30vh',paddingBottom:'0px',fontSize:'24px'}}>
                    <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" width="75%" />
            </div>
            <div className="col s12 center-align white-text headline-font" 
                style={{marginTop:'50px',paddingBottom:'0px',fontSize:'24px',marginBottom:'100px'}}>
                <span className="white-base-blue-link" style={{cursor:'pointer'}}
                    onClick={()=>scrollToLearnMore()}>
                        <span style={{verticalAlign:'text-bottom'}}>LEARN MORE ABOUT EXR&nbsp;</span>
                        <span className="material-symbols-outlined white-base-blue-link" style={{fontSize:'37px', verticalAlign:'text-bottom'}}>
                        keyboard_double_arrow_right
                        </span>
                    </span>
                    
            </div>
        </div>
        <div className="row howtoplay center-align" id="how-works-page-0" style={{position:'relative',background:'black',width:'100vw',
                                                            backgroundImage:'url()',backgroundSize:'cover',backgroundPosition:'center',
                                                            marginBottom:'0px',paddingBottom:'50px'}}>
            

            <div className="col s12 m10 offset-m1 white-text" style={{marginTop:'80px'}}>
                <div className="headline-text event-register-large-headline">
                    INTRODUCING THE BASE RACING LEAGUE
                </div>
            </div>
            <div className="col s12 m10 offset-m1 white-text">
                <div className="subheadline-text exr-base-blue-text event-register-subheadline">
                    VIRTUAL RACES&nbsp;
                    <span className="white-text">//</span> PREDICTIONS&nbsp;
                    <span className="white-text">//</span> BONUS REWARDS
                </div>
            </div>
        </div>
        <div className="row howtoplay" id="how-works-page-0b" style={{position:'relative',background:'black',width:'100%',marginBottom:'0px',lineHeight:'.5'}}>
            <div className="row center-align" style={{paddingTop:'20px',paddingBottom:'0px',marginBottom:'0px'}}>
                <img src="https://media.exiledracers.com/global/splash/exr_game_footage_loop.gif" width="100%" style={{marginBottom:'0px'}} />

                {/* <img src="https://media.exiledracers.com/assets/game/builder/Engage_Footage_Splash_1712359421083.gif" width="100%" style={{marginBottom:'0px'}} /> */}
            </div>
        </div>
        <div className="row howtoplay" id="how-works-page-1" 
            style={{position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'100px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW IT WORKS
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        1. REVEAL YOUR WEEKLY STACK
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.5',paddingRight:'20px',fontSize:'16px'}}>
                            Get EXCITED and reveal your 2x New weekly free single use Random stacks—Powered by API3 and our new holders shared garage. 
                            Pro Tip: Hover over your Stack attributes on the right of the images. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Engage_Reveal_Splash_1712356875752.png" 
                                    width="80%" style={{borderRadius:'10px'}} />
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 l10">
                                <div className="row section-triple-subtext" style={{borderRadius:'20px'}}>
                                    <div className="col s2 m2 l1 right-align">
                                        <img src="https://media.exiledracers.com/assets/game/builder/API3_Logo_Vertical_1704931823661.png" height="70px" />
                                    </div>
                                    <div className="col s10 m9 l9" style={{marginTop:'18px',color:'grey',fontSize:'16px'}}>
                                        Provably fair
                                        random stacks
                                        powered by API3 
                                        <br/>
                                        Weekly stacks are 
                                        single use. For
                                        random league.  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="how-works-page-2" style={{position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW IT WORKS
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        2. ENTER YOUR BEST STACK
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.5',paddingRight:'20px',fontSize:'16px'}}>
                            Wanna get an edge? Pick the best combination of racing items based on Your stack attribute summary on the right. 
                            PRO TIP: Look out for Attribute, the track and genetic boosters.
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Engage_Restack_Splash_1712357397512.png" 
                                    width="80%" style={{borderRadius:'10px'}} />
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 l10">
                                <div className="row section-triple-subtext" style={{borderRadius:'20px'}}>
                                    <div className="col s2 m2 l1 right-align">
                                        <img src="https://media.exiledracers.com/assets/game/builder/API3_Logo_Vertical_1704931823661.png" height="70px" />
                                    </div>
                                    <div className="col s10 m9 l9" style={{marginTop:'18px',color:'grey',fontSize:'16px'}}>
                                        Provably fair
                                        random stacks
                                        powered by API3 
                                        <br/>
                                        Weekly stacks are 
                                        single use. For
                                        random league.  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="how-works-page-3" style={{position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW IT WORKS
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        3. MAKE YOUR PREDICTIONS
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.5',paddingRight:'20px',fontSize:'16px'}}>
                            Once the race entry windows close youll get the chance to make predictions on 5x races. Choose wisely. 
                            Bonus points for underdog selections. Pro tip: First to predict in a tie break matters. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Engage_Predict_Splash2_1712358585547.png" 
                                    width="75%" style={{borderRadius:'10px'}} />
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="how-works-page-4" style={{position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW IT WORKS
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        4. WATCH SIMULATED RACES
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.5',paddingRight:'20px',fontSize:'16px'}}>
                            Once the prediction window ends, after a few hours youl be able to watch the simulated algorithmic races. 
                            Switch views & turn sound on. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <div className="col s12" style={{margin:'0px',padding:'0px'}}>
                                <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Engage_Watch_Splash2_1712357769168.png" 
                                width="100%" style={{borderRadius:'10px'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="how-works-page-5" style={{position:'relative',width:'100%',minHeight:'80vh',marginBottom:'0px'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW IT WORKS
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        5. CHECK THE LEADERBOARD
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.5',paddingRight:'20px',fontSize:'16px'}}>
                            After races, your points will be ready to check on the weekly leaderboards. PRO Tip: Partner leaderboard is tasty.
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Engage_Leaderboard_Splash_1712358825030.png" 
                                    width="80%" style={{borderRadius:'10px'}} />
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row" id="how-works-page-6" style={{position:'relative',background:'black',width:'100vw',minHeight:'90vh',marginBottom:'0px'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10',marginTop:'100px'}}>
                <div className="row firstsection" style={{marginBottom:'10px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row" style={{marginTop:'0px',marginBottom:'150px',lineHeight:'1.1'}}>
                            <div className="col s12 l5 white-text" style={{padding:'0px 20px'}}>
                                <div className="row show-on-large-only hide-on-med-and-down" style={{marginTop:'7vh'}}>
                                    <div className="left base-blue-background white-text" style={{width:'35%',height:'3px'}}>
                                    </div>
                                </div>
                                <div className="row headline-text event-register-large-headline event-register-subheadline-mid show-on-large-only hide-on-med-and-down"  
                                    style={{fontSize:'40px',lineHeight:'1.25'}}>
                                    <span className="exr-base-blue-text">BASE</span><br/>
                                    LEAGUE <br/>
                                    REWARDS
                                </div>
                                <div className="row headline-text event-register-large-headline event-register-subheadline-mid show-on-medium-and-down hide-on-large-only"  
                                    style={{fontSize:'40px'}}>
                                    BASE LEAGUE REWARDS
                                </div>
                                <div className="row subheadline-text section-triple-subtext event-register-subheadline" 
                                        style={{fontSize:'16px',color:'silver',textTransform:'uppercase'}}>
                                            <span style={{color:'grey'}}>
                                                Compete for points. Climb the leaderboard. Stand a chance to win. 
                                            </span>
                                </div>
                                <div className="row show-on-medium-and-down hide-on-large-only" style={{marginTop:'0px'}}>
                                    <div className="left section-line-seperator base-blue-background white-text" style={{width:'45%',height:'2px'}}>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 l7 event-register-subheadline-mid" style={{color:'silver',padding:'0px 20px',textTransform:'uppercase'}}>
                                <div className="row subheadline-text" style={{borderBottom:'1px solid silver',marginBottom:'30px'}}>
                                    <div className="row subheadline-text event-register-subheadline-mid" style={{fontSize:'16px',margin:'0px',marginBottom:'15px'}}>
                                        Compete for a chance at prizes
                                    </div>
                                    <div className="row headline-text exr-base-blue-text event-register-subheadline-mid" style={{margin:'0px',marginBottom:'20px'}}>
                                        PARTNER <span className="white-text">PRIZE POOLS</span>
                                    </div>
                                    <div className="row subheadline-text" style={{fontSize:'14px',margin:'0px',marginBottom:'30px',textTransform:'uppercase'}}>
                                        Free to compete for partner prize pools
                                    </div>
                                </div>
                                <div className="row subheadline-text" style={{borderBottom:'1px solid silver',marginBottom:'30px'}}>
                                    <div className="row subheadline-text event-register-subheadline-mid" style={{fontSize:'16px',margin:'0px',marginBottom:'15px'}}>
                                        COMMUNITY HAVOC RACING
                                    </div>
                                    <div className="row headline-text exr-base-blue-text event-register-subheadline-mid" style={{margin:'0px',marginBottom:'20px'}}>
                                        <span className="white-text">HOLDER</span> REWARDS 
                                    </div>
                                    <div className="row subheadline-text" style={{fontSize:'14px',margin:'0px',marginBottom:'30px',textTransform:'uppercase'}}>
                                        Share items for points and chance at rewards
                                    </div>
                                </div>
                                <div className="row subheadline-text" style={{borderBottom:'1px solid silver',marginBottom:'30px'}}>
                                    <div className="row subheadline-text event-register-subheadline-mid" style={{fontSize:'16px',margin:'0px',marginBottom:'15px'}}>
                                        ENGAGEMENT Rewards
                                    </div>
                                    <div className="row headline-text exr-base-blue-text event-register-subheadline-mid" style={{margin:'0px',marginBottom:'20px'}}>
                                        <span className="white-text">EXR NFT</span> DROPS 
                                    </div>
                                    <div className="row subheadline-text" style={{fontSize:'14px',margin:'0px',marginBottom:'30px',textTransform:'uppercase'}}>
                                        Prize NFTs for active racers & PREDICTORS
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};