import * as THREE from "three";

export const effectStartLight = new THREE.ShaderMaterial({
    // language=GLSL
    vertexShader: `
        #define EXTRUDE .01
        
        varying vec2 vUv;
        
        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position + normal * EXTRUDE, 1.);
            
            vUv = uv;
        }
        `,
    // language=GLSL
    fragmentShader: `
        uniform vec3 color;
        uniform float countdown;
        
        varying vec2 vUv;
        
        void main() {
            const float ramp = 15.;
            
            float alpha = clamp(0., 1., .5 + ramp * (vUv.y - countdown));
            
            gl_FragColor = vec4(color, alpha);
        }
        `,
    uniforms: {
        color: new THREE.Uniform(new THREE.Color("#c1ff64")),
        countdown: {
            value: 1
        }
    },
    blending: THREE.AdditiveBlending,
    transparent: true,
});