import * as THREE from "three";
import {GlobalCache} from "./globalCache.js";
import {LoadTracker} from "../loadTracker.js";

export class CubeMapCache extends GlobalCache {
    /**
     * Construct the cube map cache
     */
    constructor() {
        super();

        this.generator = null;
        this.loader = new THREE.CubeTextureLoader();
        this.assetLists = {};
    }

    /**
     * Set the renderer
     * @param {THREE.WebGLRenderer} renderer The renderer
     */
    setRenderer(renderer) {
        this.generator = new THREE.PMREMGenerator(renderer);
    }

    /**
     * Load an asset from a server
     * @param {string} asset The asset url
     * @param {function} onLoaded A function that returns with the loaded asset after loading completes
     */
    loadFromServer(asset, onLoaded) {
        LoadTracker.register();

        this.loader.load(this.assetLists[asset], texture => {
            const PMREM = this.generator.fromCubemap(texture).texture;

            texture.dispose();

            onLoaded(PMREM);

            LoadTracker.resolve();
        });
    }

    /**
     * Load an asset
     * @param {string[]} assets The six cube map texture URLs
     * @returns {Promise} A promise that resolves when the asset is available
     */
    loadFromArray(assets) {
        const name = JSON.stringify(assets);

        this.assetLists[name] = assets;

        return super.load(name);
    }
}
