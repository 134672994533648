import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance } from "../../../../../utils";
import booster_story from "./booster_story_data.json"
import $ from "jquery";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

let nfttype_view = '';
let current_info_view = 1;
let grid_data:any = [];
let current_grid_selection = -1;

let current_user_id = 0; 
let current_wallet_address = '';
let signup_racer = 'spectator';
let race_raceday_id = 0;

export const BaseLeagueWeeklyStackDetails = (props:any) => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state:any) => state.account);

    /* EXR Event ID */
    var exreventid = props.exreventid;
    let garage_id = 3;
    let signup_user_id = 0;
    const [entrySet, setEntry] = useState(false);
    const [itemTextHTML, setItemTextHTML] = useState(<></>);
    const [current_nft_type, setCurrentNFTType] = useState(null);
    const [modalImageHTML, setModalImageHTML] = useState(<></>);
    const [modalTextHTML, setModalTextHTML] = useState(<></>);
    const [primaryImageHTML, setPrimaryImageHTML] = useState(<></>);
    const [current_token_id, setCurrentTokenID] = useState(null);
    const [current_faction, setCurrentFaction] = useState(null);
    const [high_res_link, setHighResLink] = useState('');

    const [item_list, setItemList] = useState([]);

    let nfttype = 'pilot';

    let serfStory = `The Serfs: A low cost mining, demolition and transportation workforce — slaves to the galaxy mega corps, slaves to their own software, 
    suddenly began to think for themselves after a system exploit. After years of working in the field, Serf's are stronger and have more stamina than other racers.`;

    let augStory = `The Augs: Ex-humans, casualties of war, critically injured and unable to work — now a marvel of modern biosynthetic engineering — 
    powered by a lifetime of debt owed to the galactic tech super giant Harumi Corp who offered them a second “life.” 
    Enhanced through technology, Augs have increased intelligence and connectivity.`;

    let mercStory = `Mercs: Blood thirsty guns for hire, dangerous, relentless and paid handsomely by the greed of 
    galaxy super corps — hungry for their next battle and planetary take over. Battle tested and trained from a young age, Mercs are the 
    most aggressive and agile faction.`;

    function grabStackItems() {
        race_raceday_id = props.raceday_id;
        var addData:any = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        
        addData.raceID = encodeURIComponent(props.race_id);
        addData.sharedGarageID = encodeURIComponent(garage_id);

        axiosInstance.post('member/items_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_item_list = result.data;
                setItemList(new_item_list); 
                
                var pilot_arr = [];
                var racecraft_arr = [];
                var booster_arr = [];
                
                for (var z = 0; z < new_item_list.length;z++) {
                    if (new_item_list[z].item_type == 'booster') {
                        booster_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'pilot') {
                        pilot_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'ship') {
                        racecraft_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'racecraft') {
                        racecraft_arr.push(new_item_list[z]);
                    }
                }

                grid_data = [];
                for (var z = 0; z < pilot_arr.length;z++) {
                    grid_data.push(pilot_arr[z]);
                }
                for (var z = 0; z < racecraft_arr.length;z++) {
                    grid_data.push(racecraft_arr[z]);
                }
                for (var z = 0; z < booster_arr.length;z++) {
                    grid_data.push(booster_arr[z]);
                }
                showPageSections();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function clickGridSquareHolder(e:any) {
        e.preventDefault();
        var tmp_grid_id = 0;
        let grid_elem = e.target;
        if(grid_elem.dataset.gridid) {
          tmp_grid_id = grid_elem.dataset.gridid;
        }
        clickGridSquare(tmp_grid_id);
      }
    
      function clickGridSquare(squareid:number) {
        var initialselection = 'N'
        if(current_grid_selection == -1) {
          initialselection = 'Y';
        }
        if(current_grid_selection != squareid) {
          current_grid_selection = squareid;
          selectGridSquare(squareid);
          changeInfoCarousel(1);
        }
      }

      function expandGridBox() {
        clickGridSquare(current_grid_selection);
      }
    
     
      function selectGridSquare(squareid:number) {
    
        $(".exr-filled-grid-box").each(function(){
        $(this).removeClass("exr-selected-grid-box");
        });
        $("#gridbox"+squareid).addClass("exr-selected-grid-box");
        nfttype = grid_data[squareid]['item_type'];
        
        let tmp_type_upper = nfttype.charAt(0).toUpperCase() + nfttype.slice(1);
        let tmp_faction = grid_data[squareid]['item_faction'];
        let tmp_rank = grid_data[squareid]['item_subname'];
        let tmp_level = grid_data[squareid]['item_level'];
        let tmp_generation = grid_data[squareid]['item_generation'];
        let lender_racer_name = grid_data[squareid]['lender_racer_name'];
        let high_image_link = grid_data[squareid]['image_high_res'];
        setHighResLink(high_image_link);

        let selected_stat1_name = 'Focus';
        let selected_stat1_stat = 0;
        let selected_stat2_name = 'Stamina';
        let selected_stat2_stat = 0;
        let selected_stat3_name = 'Aggression';
        let selected_stat3_stat = 0;
        let selected_stat4_name = 'Intelligence';
        let selected_stat4_stat = 0;
    
        let selected_rank1_stat = grid_data[squareid]['item_rarity_overall'];
        let selected_rank1_max = grid_data[squareid]['max_total'];
        let selected_rank2_stat = grid_data[squareid]['item_rarity_faction'];
        let selected_rank2_max = grid_data[squareid]['max_faction']
        let selected_rank3_stat = grid_data[squareid]['item_rarity_tier'];
        let selected_rank3_max = grid_data[squareid]['max_tier'];
        let selected_rank4_stat = grid_data[squareid]['item_rarity_game'];
        let selected_rank4_max = grid_data[squareid]['max_total'];
    
        let selected_rank3_statp = selected_rank3_stat;
        if(selected_rank3_stat == 1) {
            selected_rank3_statp = 0;
        }
    
        let attributeArray = [];
    
        setCurrentTokenID(grid_data[squareid]['item_token_id']);
        setCurrentFaction(tmp_faction);
    
        for(var z = 0; z < grid_data[squareid]['attributes'].length;z++) {
          if (grid_data[squareid]['attributes'][z]['attribute'] != 'Tier') {
            var tmpAttributeBubble = <div className="col s12 m6" key={z}>
              <div className="nviewer-attribute-bubble exr-stats-light-grey">
                <div style={{width:'100%',fontSize:'10px',padding:'0px'}}>
                  {grid_data[squareid]['attributes'][z]['attribute']}
                </div>
                <div style={{width:'100%',fontSize:'12px',fontWeight:'900',padding:'0px',marginTop:'2px'}}>
                  {grid_data[squareid]['attributes'][z]['attribute_value']}
                </div>
              </div>
            </div>;
            attributeArray.push(tmpAttributeBubble);
          }
        }
    
        var booster_type = '';
        for(var z = 0; z < grid_data[squareid]['stats'].length;z++) {
          if((grid_data[squareid]['stats'][z]['stat_name'] == 'Focus')&&((nfttype == 'pilot')||(nfttype == 'booster'))) {
            selected_stat1_name = 'Focus';
            selected_stat1_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'pilot';
            }
          } else if((grid_data[squareid]['stats'][z]['stat_name'] == 'Stamina')&&((nfttype == 'pilot')||(nfttype == 'booster')))  {
            selected_stat2_name = 'Stamina';
            selected_stat2_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'pilot';
            }
          } else if((grid_data[squareid]['stats'][z]['stat_name'] == 'Aggression')&&((nfttype == 'pilot')||(nfttype == 'booster'))) {
            selected_stat3_name = 'Aggression';
            selected_stat3_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'pilot';
            }
          } else if((grid_data[squareid]['stats'][z]['stat_name'] == 'Intelligence')&&((nfttype == 'pilot')||(nfttype == 'booster'))) {
            selected_stat4_name = 'Intelligence';
            selected_stat4_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'pilot';
            }
          } else if((grid_data[squareid]['stats'][z]['stat_name'] == 'Speed')&&((nfttype == 'racecraft')||(nfttype == 'booster'))) {
            selected_stat1_name = 'Speed';
            selected_stat1_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'racecraft';
            }
          } else if((grid_data[squareid]['stats'][z]['stat_name'] == 'Handling')&&((nfttype == 'racecraft')||(nfttype == 'booster')))  {
            selected_stat2_name = 'Handling';
            selected_stat2_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'racecraft';
            }
          } else if((grid_data[squareid]['stats'][z]['stat_name'] == 'Acceleration')&&((nfttype == 'racecraft')||(nfttype == 'booster')))  {
            selected_stat3_name = 'Acceleration';
            selected_stat3_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'racecraft';
            }
          } else if((grid_data[squareid]['stats'][z]['stat_name'] == 'Strength')&&((nfttype == 'racecraft')||(nfttype == 'booster'))) {
            selected_stat4_name = 'Strength';
            selected_stat4_stat = grid_data[squareid]['stats'][z]['stat_value'];
            if(nfttype == 'booster') {
              booster_type = 'racecraft';
            }
          } 
        }
    
        let selected_headline = tmp_faction+' / '+tmp_type_upper+' #'+grid_data[squareid]['item_token_id'];
        let selected_subheadline =  tmp_rank+' / Tier '+tmp_level+' / Rank '+selected_rank4_stat+' of '+grid_data[squareid]['max_total'];
        if(nfttype == 'booster') {
          if(booster_type == 'racecraft') {
            selected_stat1_name = 'Speed';
            selected_stat2_name = 'Handling';
            selected_stat3_name = 'Acceleration';
            selected_stat4_name = 'Strength';
          } else {
            selected_stat1_name = 'Focus';
            selected_stat2_name = 'Stamina';
            selected_stat3_name = 'Aggression';
            selected_stat4_name = 'Intelligence';
          }
          selected_headline = grid_data[squareid]['item_name'];
          selected_headline = grid_data["item_name"];
          if(tmp_level == 0) {
            tmp_faction = 'Legendary';
          } else if (tmp_level == 1) {
            tmp_faction = 'Exiled';
          }
          selected_subheadline =  tmp_faction+' / Tier '+tmp_level+' / Rank '+selected_rank4_stat+' of '+grid_data[squareid]['max_total'];
          tmp_rank = 'Tier '+tmp_level;
        } 
    
        let selected_faction = tmp_faction;
        let selected_faction_nickname = '';
        let selected_faction_story = '';
        if(tmp_faction == 'Mercenary') {
          selected_faction_nickname = 'Merc';
          selected_faction_story = mercStory;
        } else if (tmp_faction == 'Augment') {
          selected_faction_nickname = 'Aug';
          selected_faction_story = augStory;
        } else if (tmp_faction == 'Serf') {
          selected_faction_nickname = 'Serf';
          selected_faction_story = serfStory;
        } else {
          selected_faction_nickname = tmp_faction;
        }
        let selected_item_nickname = selected_faction_nickname+'s';
    
        if(nfttype == 'booster') {
          for(var j = 0;j< booster_story.length;j++) {
            let booster_name = grid_data[squareid]['item_name'].toLowerCase();
            let story_name = booster_story[j]['name'].toLowerCase();
            if(booster_name.includes(story_name)) {
              selected_item_nickname = booster_story[j]['name'];
              selected_faction_story = booster_story[j]['description'];
              selected_headline = selected_item_nickname;
              j = booster_story.length;
            }
          }
        }
    
        var attributeHTML = <>{attributeArray}</>;
    
        var selected_stat1_stat_pct = selected_stat1_stat;
        var selected_stat2_stat_pct = selected_stat2_stat;
        var selected_stat3_stat_pct = selected_stat3_stat;
        var selected_stat4_stat_pct = selected_stat4_stat;
        if(nfttype == 'booster') {
          selected_stat1_stat_pct = selected_stat1_stat*10;
          selected_stat2_stat_pct = selected_stat2_stat*10;
          selected_stat3_stat_pct = selected_stat3_stat*10;
          selected_stat4_stat_pct = selected_stat4_stat*10;
        }
        
    
        var statsHTML = <>
            <div className="row white-text" style={{fontSize:'14px',marginTop:'-8px',marginBottom:'2px'}}>
                <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                    <div style={{order:'1',flexBasis:'40px'}}>
                        <img src={"https://media.exiledracers.com/global/icons/exr_"+selected_stat1_name.toLowerCase()+".png"} height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                    </div>
                    <div style={{order:'2',flex:'10'}}>
                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                            <div className="left">{selected_stat1_name}</div>
                            <div className="right">{selected_stat1_stat}</div>
                        </div>
                        <div style={{width:'100%'}}>
                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                <div className="determinate white" style={{width:selected_stat1_stat_pct+'%',backgroundColor: 'white'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider-thin"></div>
            <div className="row white-text" style={{fontSize:'14px',marginTop:'12px',marginBottom:'2px'}}>
                <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                    <div style={{order:'1',flexBasis:'40px'}}>
                        <img src={"https://media.exiledracers.com/global/icons/exr_"+selected_stat2_name.toLowerCase()+".png"} height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                    </div>
                    <div style={{order:'2',flex:'10'}}>
                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                            <div className="left">{selected_stat2_name}</div>
                            <div className="right">{selected_stat2_stat}</div>
                        </div>
                        <div style={{width:'100%'}}>
                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                <div className="determinate white" style={{width:selected_stat2_stat_pct+'%',backgroundColor: 'white'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider-thin"></div>
            <div className="row white-text" style={{fontSize:'14px',marginTop:'12px',marginBottom:'2px'}}>
                <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                    <div style={{order:'1',flexBasis:'40px'}}>
                        <img src={"https://media.exiledracers.com/global/icons/exr_"+selected_stat3_name.toLowerCase()+".png"} height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                    </div>
                    <div style={{order:'2',flex:'10'}}>
                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                            <div className="left">{selected_stat3_name}</div>
                            <div className="right">{selected_stat3_stat}</div>
                        </div>
                        <div style={{width:'100%'}}>
                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                <div className="determinate white" style={{width:selected_stat3_stat_pct+'%',backgroundColor: 'white'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider-thin"></div>
            <div className="row white-text" style={{fontSize:'14px',marginTop:'12px',marginBottom:'2px'}}>
                <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                    <div style={{order:'1',flexBasis:'40px'}}>
                        <img src={"https://media.exiledracers.com/global/icons/exr_"+selected_stat4_name.toLowerCase()+".png"} height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                    </div>
                    <div style={{order:'2',flex:'10'}}>
                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                            <div className="left">{selected_stat4_name}</div>
                            <div className="right">{selected_stat4_stat}</div>
                        </div>
                        <div style={{width:'100%'}}>
                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                <div className="determinate white" style={{width:selected_stat4_stat_pct+'%',backgroundColor: 'white'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </>
        
        var rarityHTML = <>
        <div className="row white-text" style={{fontSize:'14px',marginTop:'-7px',marginBottom:'2px'}}>
            <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                <div style={{order:'1',flexBasis:'100px'}}>
                  <div className="nviewer-rarity-num-large">
                    #{selected_rank1_stat}
                  </div>
                </div>
                <div style={{order:'2',flex:'10'}}>
                    <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                        <div className="left">Overall Ranking</div>
                        <div className="right">{selected_rank1_stat}/{selected_rank1_max}</div>
                    </div>
                    <div style={{width:'100%'}}>
                        <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                            <div className="determinate white" style={{width:(100*(1-(selected_rank1_stat/selected_rank1_max)))+'%',backgroundColor: 'white'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="divider-thin"></div>
        <div className="row white-text" style={{fontSize:'14px',marginTop:'12px',marginBottom:'2px'}}>
            <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                <div style={{order:'1',flexBasis:'100px'}}>
                  <div className="nviewer-rarity-num-large">
                    #{selected_rank2_stat}
                  </div>
                </div>
                <div style={{order:'2',flex:'10'}}>
                    <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                        <div className="left">{selected_faction_nickname} Ranking</div>
                        <div className="right">{selected_rank2_stat}/{selected_rank2_max}</div>
                    </div>
                    <div style={{width:'100%'}}>
                        <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                            <div className="determinate white" style={{width:(100*(1-(selected_rank2_stat/selected_rank2_max)))+'%',backgroundColor: 'white'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="divider-thin"></div>
        <div className="row white-text" style={{fontSize:'14px',marginTop:'12px',marginBottom:'2px'}}>
            <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                <div style={{order:'1',flexBasis:'100px'}}>
                  <div className="nviewer-rarity-num-large">
                    #{selected_rank3_stat}
                  </div>
                </div>
                <div style={{order:'2',flex:'10'}}>
                    <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                        <div className="left">{tmp_rank} Ranking</div>
                        <div className="right">{selected_rank3_stat}/{selected_rank3_max}</div>
                    </div>
                    <div style={{width:'100%'}}>
                        <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                            <div className="determinate white" style={{width:(100*(1-(selected_rank3_statp/selected_rank3_max)))+'%',backgroundColor: 'white'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="divider-thin"></div>
        <div className="row white-text" style={{fontSize:'14px',marginTop:'12px',marginBottom:'2px'}}>
            <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                <div style={{order:'1',flexBasis:'100px'}}>
                  <div className="nviewer-rarity-num-large">
                    #{selected_rank4_stat}
                  </div>
                </div>
                <div style={{order:'2',flex:'10'}}>
                    <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                        <div className="left">Game Stats Ranking</div>
                        <div className="right">{selected_rank4_stat}/{selected_rank4_max}</div>
                    </div>
                    <div style={{width:'100%'}}>
                        <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                            <div className="determinate white" style={{width:(100*(1-(selected_rank4_stat/selected_rank4_max)))+'%',backgroundColor: 'white'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>;
        
    
        var tmp_modal_text = <><div className="exr-nviewer-main-faction-icon-static hide-on-med-and-down show-on-large">
                                        <img src={"https://media.exiledracers.com/global/icons/exr_"+selected_faction_nickname.toLowerCase()+"_icon.png"} height="60px" />
                                      </div>
                                      <div className="exr-nviewer-main-faction-icon show-on-medium-and-down hide-on-large-only">
                                        <img src={"https://media.exiledracers.com/global/icons/exr_"+selected_faction_nickname.toLowerCase()+"_icon.png"} height="40px" />
                                      </div>
                                      <div id="nviewer-mint-key-headline-text" className="exr-nviewer-main-headline">
                                          {selected_headline}
                                      </div>
                                      <div id="nviewer-mint-key-count-text" className="exr-nviewer-main-subheadline exr-base-blue-text" style={{marginTop:'15px'}}>
                                          {selected_subheadline}
                                      </div>
                                      <div id="nviewer-mint-shared-text" className="exr-nviewer-main-subheadline white-text" style={{marginTop:'15px'}}>
                                        Shared By: {lender_racer_name}
                                      </div>
                                      <div id="nviewer-info-carousel" style={{minHeight:'272px',marginTop:'30px',display:'block !important'}}>
                                        <div id="infosection1" className="nviewer-info-carousel-section" style={{display:'block'}}>
                                          <div className="exr-nviewer-section-headline" style={{lineHeight:'1.5',fontSize:'18px'}}>
                                              {tmp_type_upper} Stats
                                          </div>
                                          <div className="exr-nviewer-divider" style={{marginTop:'5px',marginBottom:'20px'}}></div>
                                          {statsHTML}
                                        </div>
                                        <div id="infosection2" className="nviewer-info-carousel-section">
                                          <div className="exr-nviewer-section-headline" style={{lineHeight:'1.5',fontSize:'18px'}}>
                                              Rarity Ranking
                                          </div>
                                          <div className="exr-nviewer-divider" style={{marginTop:'5px',marginBottom:'20px'}}></div>
                                          {rarityHTML}
                                        </div>
                                        <div id="infosection3" className="nviewer-info-carousel-section">
                                          <div className="exr-nviewer-section-headline" style={{lineHeight:'1.5',fontSize:'18px'}}>
                                              <span className="show-on-large-only hide-on-med-and-down">
                                                About the {selected_item_nickname}
                                              </span>
                                              <span className="hide-on-large-only show-on-med-and-down">
                                                About the {selected_item_nickname}
                                              </span>
                                          </div>
                                          <div className="exr-nviewer-divider" style={{marginTop:'5px',marginBottom:'15px'}}></div>
                                          <div className="exr-nviewer-main-text" style={{lineHeight:'1.5',fontSize:'16px'}}>
                                              {selected_faction_story}
                                          </div>
                                        </div>
                                        <div id="infosection4" className="nviewer-info-carousel-section">
                                          <div className="exr-nviewer-section-headline" style={{lineHeight:'1.5',fontSize:'18px'}}>
                                            Visual Attributes
                                          </div>
                                          <div className="exr-nviewer-divider" style={{marginTop:'5px',marginBottom:'10px'}}></div>
                                          <div className="row">
                                            {attributeHTML}
                                          </div>
                                        </div>
                                      </div>
                                      <div id="nviewer-info-carousel-bottom-divider" className="exr-nviewer-divider" style={{marginTop:'0px',marginBottom:'20px'}}></div>
                                      <div id="nviewer-info-carousel-buttons" style={{marginTop:'20px',marginBottom:'150px'}}>                                    
                                        <div id="carobutton4" title="View Visual Attributes" onClick={()=>changeInfoCarousel(4)} className="nviewer-info-carousel-button-base-blue"></div>
                                        <div id="carobutton3"  title="View Faction Story" onClick={()=>changeInfoCarousel(3)} className="nviewer-info-carousel-button-base-blue"></div>
                                        <div id="carobutton2" title="View Rarity Rankings" onClick={()=>changeInfoCarousel(2)} className="nviewer-info-carousel-button-base-blue"></div>
                                        <div id="carobutton1" title="View Stats" onClick={()=>changeInfoCarousel(1)} className="nviewer-info-carousel-button-base-blue nviewer-info-carousel-button-base-blue-active"></div>
                                      </div>
                                  </>;
          
          setItemTextHTML(tmp_modal_text);
          changeInfoCarousel(1);
      }
    
      function changeInfoCarousel(sectionnum:number) {
        if(sectionnum != current_info_view) {
          current_info_view = sectionnum;
          $(".nviewer-info-carousel-button-base-blue").each(function(){
            $(this).removeClass("nviewer-info-carousel-button-base-blue-active");
          });
          $("#carobutton"+sectionnum).addClass("nviewer-info-carousel-button-base-blue-active");
          $(".nviewer-info-carousel-section").each(function(){
            $(this).hide();
          });
          $("#infosection"+sectionnum).fadeIn();
        }
      }
    
      async function showPageSections() {
        let primary_image_path = 'https://media.exiledracers.com/global/mint_images/exr_pilot_mint_key_spinning.gif';
    
        var grid_width:any = $("#exr-nviewer-grid-box").width();
        var grid_segment_class = "col s12";
        var grid_height = grid_width;
        var max_squares_row = 1;
        var grid_height_adjustment = 5;
        var padding_adjustment = '0px 3px';
        if (grid_data.length <= 6) {
          grid_segment_class = "col s12 m6";
          grid_height = grid_width/2;
          max_squares_row = 2;
          grid_height_adjustment = 0;
          padding_adjustment = '0px 3px';
        } else if (grid_data.length > 6) {
          grid_segment_class = "col s12 m4";
          grid_height = grid_width/3;
          max_squares_row = 3;
        }
        var modal_grid_array = [];
        var currentsquare = 1;
        for(var i = 0; i < grid_data.length;i++) {
          var grid_image = grid_data[i]['item_image_location'];
          if(grid_image.length < 5) {
            grid_image = 'https://media.exiledracers.com/global/exr_logo_v2_white_trans.png';
          }
          var extraclass = '';
          if(i == 0) {
            extraclass = ' exr-selected-grid-box';
          }
          var tmp_modal_square = <div id={"gridbox"+i} className={grid_segment_class+" exr-filled-grid-box"+extraclass} key={i+100} style={{padding:padding_adjustment,borderRadius:'10px',minHeight:grid_height-15+'px'}}>
              <img src={grid_image} width="100%" className="exr-new-dark-grey" style={{borderRadius:'10px'}} data-gridid={i} onClick={(e)=>clickGridSquareHolder(e)}/>
          </div>
          modal_grid_array.push(tmp_modal_square);
          if(i == 0) {
            primary_image_path = grid_image;
          }
          currentsquare = currentsquare + 1;
          if((currentsquare > max_squares_row)&&(i+1 < grid_data.length)) {
            currentsquare = 1;
          }
        }
        if(grid_data.length > 0) {
          /* Load Initial Text */
          selectGridSquare(0);
          if(grid_data.length == 1) {
            clickGridSquare(0);
            setTimeout(function(){
              expandGridBox();
            },10);
          }
    
          if(window.innerWidth >= 850) {
            if (grid_data.length < 4) {
              max_squares_row = 4;
              currentsquare = grid_data.length+1;
            }
            for(var i = currentsquare; i <= max_squares_row;i++) {
              var tmp_modal_square = <div className={grid_segment_class} key={i} style={{padding:padding_adjustment}}>
                  <div className="exr-new-dark-grey exr-blank-grid-box" style={{width:'100%',borderRadius:'10px',height:grid_height-grid_height_adjustment+'px'}}>&nbsp;</div>
              </div>
              modal_grid_array.push(tmp_modal_square);
            }
          }
    
          let final_modal_text = <>{modal_grid_array}</>;
          setModalImageHTML(final_modal_text);
    
        }

        /* Update Empty Grid Box Heights */
        setTimeout(function(){
            resizeBlankBoxes();
          },100);
    
          /* Update Empty Grid Box Heights 2 */
          setTimeout(function(){
            resizeBlankBoxes();
          },1000);
    } 

    function resizeBlankBoxes() {
        /* Update Picture Grid Boxes */
        var grid_width:any = $("#exr-nviewer-grid-box").width();
        var grid_height = grid_width;
        var grid_height_adjustment = 5;
        if (grid_data.length <= 6) {
          grid_height = grid_width/2;
          grid_height_adjustment = 0;
        } else if (grid_data.length > 6) {
          grid_height = grid_width/3;
        }
        // $(".exr-filled-grid-box").each(function() {
        //   $(this).css({minHeight:grid_height-15+'px'});
        // });

        /* Update Blank Grid Boxes */
        var boxheight:any = 0;
        if($("#exr-nviewer-grid-box").is(":visible")) {
            boxheight = $("#gridbox0").height();
        }
        $(".exr-blank-grid-box").each(function() {
          $(this).css({height:boxheight-grid_height_adjustment+'px'});
        });
    }


    if((entrySet == false)||(race_raceday_id != props.raceday_id)) {
        setEntry(true);
        grabStackItems();
    }

    return(<><div id="stack_details_section" className="row" style={{display:'block',marginBottom:'10px'}}>
                <div className="col s12 l7" style={{fontSize:'16px',padding:'2.5px'}}>
                    <SimpleBar id="exr-nviewer-grid-box" data-simplebar className="exr-nviewer-segment transparent" style={{width:'100%',padding:'0px'}}>
                        <div id="nviewer-grid-shell">
                            {modalImageHTML}
                        </div>
                    </SimpleBar>
                </div>
                <div className="col s12 l5" style={{fontSize:'16px',padding:'2.5px'}}>
                    <div id="exr-nviewer-info-box" className="exr-new-dark-grey" style={{borderRadius:'10px',padding:'30px',position:'relative'}}>
                        {itemTextHTML}
                        <div id="bottominfobuttonsbig" style={{position:'absolute',bottom:'15px',left:'15px'}}>
                            <div id="viewerviewinfo2"  className="left" style={{cursor:'pointer',fontSize:'14px',marginRight:'15px'}}>
                                <a target="_blank" title="View High Resolution" className="white-red-link" href={high_res_link} style={{fontSize:'14px'}}>
                                <span className="left">
                                    <i className="material-icons white-text" style={{fontSize:'25px'}}>high_quality</i>
                                </span>
                                <span className="left" style={{marginLeft:'5px',marginTop:'4px'}}>Hi-Res</span> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>         
        </div></>);
}