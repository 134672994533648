import * as THREE from "three";
import {glslSimplexNoise} from "../../glsl/glslSimplexNoise.js";
import {glslConstants} from "../../glsl/glslConstants.js";

export const effectForceField = new THREE.ShaderMaterial({
    // language=GLSL
    vertexShader: `
        uniform float zThreshold;
        
        out vec2 vUv;
        out float zAlpha;
        
        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
            vUv = uv;
            zAlpha = min(1., gl_Position.z / zThreshold);
        }
        `,
    // language=GLSL
    fragmentShader: glslConstants + glslSimplexNoise + `
        uniform vec3 color;
        uniform float time;
        
        in vec2 vUv;
        in float zAlpha;
        
        void main() {
            const float radius = 7.;
            const float drift = 1.2;
            const float radians = .25;
            const float band = 4.;
            
            float angle = time * TAU + radians * vUv.y * vUv.y;
            float alpha = vUv.y * 4. * (vUv.x - vUv.x * vUv.x) * sin(min(1., (1. - vUv.y) * 15.) * ETA) * zAlpha;
            vec3 noisePosition = vec3((radius + vUv.y * drift) * vec2(cos(angle), sin(angle)), vUv.x * band);
            
            gl_FragColor = vec4(color, alpha * (.5 + .5 * snoise(noisePosition)));
            
            
        }
        `,
    side: THREE.DoubleSide,
    blending: THREE.AdditiveBlending,
    transparent: true,
    depthWrite: false,
    uniforms: {
        //color: new THREE.Uniform(new THREE.Color("#5b9af8")),
        color: new THREE.Uniform(new THREE.Color("#c7167d")),
        zThreshold: {
            value: .05
        },
        time: {
            value: 0
        }
    }
});