/**
 * 2D bounds
 */
export class Bounds {
    /**
     * Boundaries
     * @param {number} left The left boundary
     * @param {number} top The top boundary
     * @param {number} right The right boundary
     * @param {number} bottom The bottom boundary
     */
    constructor(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }

    /**
     * Get the width of these bounds
     * @returns {number} The width
     */
    get width() {
        return this.right - this.left;
    }

    /**
     * Get the height of these bounds
     * @returns {number} The height
     */
    get height() {
        return this.bottom - this.top;
    }
}
