import * as THREE from "three";

/**
 * A track piece
 */
export class TrackPiece {
    /**
     * Construct a track piece
     * @param {number} length The piece length in meters
     * @param {number} index The index of this piece in the list
     */
    constructor(length, index) {
        this.length = length;
        this.index = index;
        this.next = null;
        this.offsetPosition = new THREE.Vector3();
        this.offsetAngle = 0;
    }

    /**
     * Get the center position of this track piece
     * @returns {Vector3} The center of this track position
     */
    get center() {
        const position = new THREE.Vector3();

        this.sample(position, .5);

        return position;
    }

    /**
     * Get the total length of the track starting at this piece
     * @returns {number} The total length
     */
    get totalLength() {
        if (this.next)
            return this.next.totalLength + this.length;

        return this.length;
    }

    /**
     * Get the rotation this track piece produces
     * @returns {number} The rotation in radians
     */
    get rotation() {
        return Math.abs(this.derivative(1) - this.offsetAngle);
    }

    /**
     * Sample this track piece
     * @param {Vector3} target The target vector to write to
     * @param {number} t The position to sample at in the range [0, 1]
     * @returns {Vector3} The target
     */
    sample(target, t) {
        return target.copy(this.offsetPosition);
    }

    /**
     * Sample the track derivative
     * @param {number} t The position to sample at in the range [0, 1]
     * @returns {number} The track derivative in radians
     */
    derivative(t) {
        return this.offsetAngle;
    }

    /**
     * Transform a vector by the track piece offset
     * @param {Vector3} vector The vector to rotate
     * @returns {Vector3} The rotated vector
     */
    transformByOffset(vector) {
        const c = Math.cos(this.offsetAngle);
        const s = Math.sin(this.offsetAngle);
        const x = vector.x;
        const z = vector.z;

        vector.x = c * x - s * z;
        vector.z = s * x + c * z;

        return vector.add(this.offsetPosition);
    }

    /**
     * Add a track piece
     * @param {TrackPiece} piece The next track piece
     * @returns {TrackPiece} The added piece
     */
    add(piece) {
        this.next = piece;

        this.sample(this.next.offsetPosition, 1);
        this.next.offsetAngle = this.derivative(1);

        return piece;
    }
}