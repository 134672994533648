import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {BaseLeagueSplash, BaseMintPasses} from "./Sections";

const SplashBaseLeague = (props:any) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state: any) => state.account);

    const [pageSet, setPage] = useState(false);
    const [currentPage, setCurrentPage] = useState(<></>);

    document.addEventListener('DOMContentLoaded', function() {
        
        if(window.location.href.indexOf('register') >= 0) {
            setCurrentPage(<BaseLeagueSplash wcModal={props.wcModal}  />);
        } else if(window.location.href.indexOf('results') >= 0) {
            setCurrentPage(<BaseLeagueSplash wcModal={props.wcModal}  />);
        } else {
            setCurrentPage(<BaseLeagueSplash wcModal={props.wcModal}  />);
        }
    });

    if(pageSet == false) {
        setPage(true);
        if(window.location.href.indexOf('register') >= 0) {
            setCurrentPage(<BaseLeagueSplash wcModal={props.wcModal}  />);
        } else {
            setCurrentPage(<BaseLeagueSplash wcModal={props.wcModal}  />);
        }
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            {currentPage}
            {/* <BaseMintPasses wcModal={props.wcModal}  />); */}
            </div>);
};

export default SplashBaseLeague;