import * as THREE from "three";
import {TrackPieceStraight} from "./pieces/trackPieceStraight.js";
import {TrackPieceArc} from "./pieces/trackPieceArc.js";
import {DataLoader} from "../utils/dataLoader.js";
import {TrackPropInstanceSingle} from "./props/instance/trackPropInstanceSingle.js";
import {TrackProp} from "./props/trackProp.js";
import {TrackSlice} from "./trackSlice.js";
import {current_endpoint} from "../../../utils/axios.helper"

/**
 * Racetrack data
 */
export class Track extends DataLoader {
    //static URL = "https://data.exiledracers.com/api/v1/race/track_list_admin_simple/";
    static URL = current_endpoint+"race/track_list_game/";
    static KEY = "raceUniqueID";
    static KEY_HASH = "raceUniqueID";
    static KEY_ID = "trackID";
    static KEY_LAPS = "laps";
    static KEY_PIECES = "pieces";
    static KEY_PROPS = "props";
    static KEY_GALAXY = "race_track_galaxy";
    static KEY_RTYPE = "race_type";

    static KEY_PIECE_TYPE = "type";
    static KEY_PIECE_LENGTH = "length";
    static KEY_PIECE_DEGREES = "degrees";
    static KEY_PIECE_RADIUS = "radius";

    static KEY_PROP_ASSET = "asset";
    static KEY_PROP_INSTANCES = "instances";
    static KEY_PROP_INSTANCE_POSITION = "position";
    static KEY_PROP_INSTANCE_ROTATION = "rotation";

    static SPACING = 3;

    /**
     * Decode a track piece from server data
     * @param {Object} data Server data describing the track piece
     * @param {number} index The index of the piece in the list
     * @returns {TrackPiece} The decoded track piece
     */
    static decodePiece(data, index) {
        switch (data[Track.KEY_PIECE_TYPE]) {
            case "straight":
                return new TrackPieceStraight(
                    data[Track.KEY_PIECE_LENGTH],
                    index);
            case "arc":
                return new TrackPieceArc(
                    Math.abs(Math.PI * 2 * data[Track.KEY_PIECE_DEGREES] / 360) * data[Track.KEY_PIECE_RADIUS],
                    index,
                    Math.PI * 2 * data[Track.KEY_PIECE_DEGREES] / 360);
        }
    }

    /**
     * Construct track data
     * @param {string} id The race id
     * @param {string} id_type The race id
     */
    constructor(id, id_type = 'hash') {
        let param_name = Track.KEY_HASH;

        if (id_type === 'id') {
            param_name = Track.KEY_ID;
        }

        super(Track.URL, param_name, String(id));

        this.start = null;
        this.props = [];
        this.laps = 0;
        this.totalLength = undefined;
        this.pieceCount = 0;
        this.track_galaxy = '';
        this.race_type = '';
    }

    /**
     * Get the position of the finish line camera
     * @returns {Vector3} The position
     */
    get finishLineCameraPosition() {
        return new THREE.Vector3(15, 7, 15)
    }

    /**
     * Get the track length
     * @returns {number} The track length
     */
    get length() {
        return this.totalLength || (this.totalLength = this.start.totalLength);
    }

    /**
     * Get the track center
     * @returns {THREE.Vector3} The track center
     */
    get center() {
        const center = new THREE.Vector3();

        for (let piece = this.start; piece; piece = piece.next)
            center.add(piece.center);

        center.divideScalar(this.pieceCount);

        return center;
    }

    /**
     * Get an array containing all track pieces in order
     * @returns {TrackPiece[]} All track pieces
     */
    get pieces() {
        const pieces = [];

        for (let piece = this.start; piece !== null; piece = piece.next)
            pieces.push(piece);

        return pieces;
    }

    /**
     * Create slices for track modelling
     * @param {number} [spacing] The spacing between slices
     * @returns {TrackSlice[]} The track slices
     */
    createSlices(spacing = Track.SPACING) {
        const slices = [];
        let piece = this.start;
        let lastDerivative = -1;

        while (piece) {
            const sliceCount = Math.ceil(piece.length / spacing);

            for (let slice = 0; slice < sliceCount; ++slice) {
                const derivative = piece.derivative(slice / sliceCount);

                if (slice === sliceCount - 1 || derivative !== lastDerivative)
                    slices.push(new TrackSlice(
                        piece.sample(new THREE.Vector3(), slice / sliceCount),
                        derivative));

                lastDerivative = derivative;
            }

            piece = piece.next;
        }

        return slices;
    }

    /**
     * Receive loaded data
     * @param {Object} data The loaded data
     */
    receiveData(data) {
        const pieces = data[Track.KEY_PIECES];
        let tail = null;

        this.laps = data[Track.KEY_LAPS];
        this.track_galaxy = data[Track.KEY_GALAXY];
        this.race_type = data[Track.KEY_RTYPE];

        if(pieces.length > 0) {
            for (const piece of pieces) {
                if (this.start === null)
                    this.start = tail = Track.decodePiece(piece, this.pieceCount++);
                else
                    tail = tail.add(Track.decodePiece(piece, this.pieceCount++));
            }
        }
        

        if (data.hasOwnProperty(Track.KEY_PROPS)) {
            const props = data[Track.KEY_PROPS];

            for (const prop of props) {
                const asset = prop[Track.KEY_PROP_ASSET];
                const instances = prop[Track.KEY_PROP_INSTANCES];
                const instanceArray = [];

                for (const instance of instances) {
                    const position = instance[Track.KEY_PROP_INSTANCE_POSITION];
                    const rotation = instance[Track.KEY_PROP_INSTANCE_ROTATION];

                    instanceArray.push(new TrackPropInstanceSingle(
                        new THREE.Vector3(
                            position["x"],
                            position["y"],
                            position["z"]),
                        new THREE.Vector3(
                            rotation["x"],
                            rotation["y"],
                            rotation["z"])));
                }

                this.props.push(new TrackProp(
                    asset,
                    instanceArray));
            }
        }
    }
}