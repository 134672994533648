export class GlobalCache {
    /**
     * Construct a global resource cache
     */
    constructor() {
        this.loading = {};
        this.loaded = {};
    }

    /**
     * Load an asset from a server
     * @param {string} asset The asset url
     * @param {function} onLoaded A function that returns with the loaded asset after loading completes
     */
    loadFromServer(asset, onLoaded) {
        onLoaded(null);
    }

    /**
     * Load an asset
     * @param {string} asset The asset URL
     * @returns {Promise} A promise that resolves when the asset is available
     */
    load(asset) {
        return new Promise(resolve => {
            if (this.loaded.hasOwnProperty(asset))
                resolve(this.loaded[asset]);
            else if (this.loading.hasOwnProperty(asset))
                this.loading[asset].push(resolve);
            else {
                this.loading[asset] = [resolve];

                this.loadFromServer(asset, loaded => {
                    this.loaded[asset] = loaded;

                    for (const r of this.loading[asset])
                        r(this.loaded[asset]);

                    delete this.loading[asset];
                });
            }
        });
    }

    /**
     * Invalidate an asset that was (possibly) previously cached
     * @param {string} asset The asset name
     */
    invalidate(asset) {
        if (this.loaded.hasOwnProperty(asset))
            delete this.loaded[asset];

        if (this.loading.hasOwnProperty(asset))
            delete this.loading[asset];
    }
}