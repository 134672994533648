import {TrackPropOverride} from "./trackPropOverride.js";
import * as THREE from "three";
import {effectBannerScanLines} from "../../../../../../exr-webgl-hub/effects/2d/effectBannerScanLines.js";
import {Banners} from "../../../../banners/banners.js";
import {TrackPieceStraight} from "../../../pieces/trackPieceStraight.js";

export class TrackPropOverrideBanner extends TrackPropOverride {
    static REPLACE = false;
    static NAME = "@portalBanner";
    static SPEED = .015;

    material = effectBannerScanLines.clone();
    time = 0;
    underTrack;

    /**
     * Parse the aspect ratio of the banner mesh
     * @param {string} name The banner mesh
     * @returns {number} The aspect ratio
     */
    static parseAspect(name) {
        const resolutionText = name.split("_")[2].split("x");

        return Number.parseFloat(resolutionText[0]) / Number.parseFloat(resolutionText[1]);
    }

    /**
     * Construct a banner
     * @param {THREE.Mesh} mesh The mesh
     * @param {Track} track The track
     * @param {number} trackWidth The track width
     * @param {string} sponsor The track sponsor
     */
    constructor(mesh, track, trackWidth, sponsor) {
        super(mesh);
        let aspect_ratio = 1;
        Banners.requestBanner(aspect_ratio, sponsor).then(texture => {
            texture.flipY = false;

            this.material.uniforms.bannerTexture = new THREE.Uniform(texture);
            this.material.uniforms.aspect.value = (texture.image.width / texture.image.height) / TrackPropOverrideBanner.parseAspect(mesh.name);
        });

        mesh.material = this.material;

        // This works just for the finish gate banners, it's not a general solution
        const position = mesh.getWorldPosition(new THREE.Vector3());

        if (position.y < 0) {
            const trackPieces = track.pieces;

            if ((position.x < 0 && !(trackPieces[trackPieces.length - 1] instanceof TrackPieceStraight)) ||
                (position.x > 0 && !(trackPieces[0] instanceof TrackPieceStraight)))
                mesh.visible = false;
        }
    }

    /**
     * Update the state
     */
    update() {
        if ((this.time += TrackPropOverrideBanner.SPEED) > 1)
            --this.time;
    }

    /**
     * Render the banner
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        this.material.uniforms.time.value = this.time + TrackPropOverrideBanner.SPEED * time;
    }
}