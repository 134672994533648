export class LoadTracker {
    static loading = 0;
    static cap = 0;
    static progress = null;
    static listeners = [];

    /**
     * Register a loading item
     * @param {number} [count] The number of load actions to register at once
     */
    static register(count = 1) {
        LoadTracker.cap = Math.max(LoadTracker.cap, LoadTracker.loading += count);
    }

    /**
     * Resolve a previously registered loading action
     */
    static resolve() {
        --LoadTracker.loading;

        for (const listener of LoadTracker.listeners)
            listener(LoadTracker.getProgress());

        if (LoadTracker.loading === 0)
            LoadTracker.listeners.length = 0;
    }

    /**
     * Set a function to call when loading progresses
     * @param {function} listener The function
     */
    static addProgressListener(listener) {
        this.listeners.push(listener);
    }

    /**
     * Remove a progress listener
     * @param {function} listener A previously added progress listener
     */
    static removeProgressListener(listener) {
        this.listeners.splice(this.listeners.indexOf(listener), 1);
    }

    /**
     * Get the current loading progress
     * @returns {number} The progress in the range [0, 1]
     */
    static getProgress() {
        return 1 - LoadTracker.loading / LoadTracker.cap;
    }
}