import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import { updateLocation, signupLoading, updatePageHash } from "./redux/App/actions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import logo from './logo.svg';
import './App.css';
import {HeaderPublic} from "./components/organisms";
import {BaseLeague} from "./components/pages";
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers/react';

const projectId = '16f92b2d5656feab7242fad0cf8cc20f'

const wcChains = [{
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
},{
  chainId: 1284,
  name: 'Moonbeam',
  currency: 'GLMR',
  explorerUrl: 'https://moonscan.io/',
  rpcUrl: 'https://rpc.api.moonbeam.network'
},{
  chainId: 8453,
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org',
  rpcUrl: 'https://mainnet.base.org'
},{
  chainId: 84532,
  name: 'Base Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.basescan.org',
  rpcUrl: 'https://sepolia.base.org'
}];

const wcMetadata = {
    name: 'Exiled Racers',
    description: 'The First Racing Manager & Prediction Game Powered by Web3',
    //url: 'https://base.exiledracers.com',
    url: 'http://localhost:3001',
    icons: ['https://www.exiledracers.com/favicon-96x96.png']
}

const ethersConfig = defaultConfig({
    metadata: wcMetadata,
    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
});

const wcModal = createWeb3Modal({
    ethersConfig,
    chains: wcChains,
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
});

function App() {
  const [appState, setAppState] = useState(false);

  if(appState == false) {
      setAppState(true);
  }
  
  return (
    <div>
      <HashRouter basename="/">
        <Routes>
          <Route path="race/:raceid" element={<BaseLeague wcModal={wcModal}  />} />
          <Route path="race" element={<BaseLeague wcModal={wcModal}  />} />
          <Route path="/" element={<BaseLeague wcModal={wcModal} />} />
          <Route path="*" element={<BaseLeague wcModal={wcModal} />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
