import {TrackPiece} from "./trackPiece.js";

/**
 * An arc track piece
 */
export class TrackPieceArc extends TrackPiece {
    /**
     * Construct an arc track piece
     * @param {number} length The piece length in meters
     * @param {number} index The index of this piece in the list
     * @param {number} radians The amount of radians to curve
     */
    constructor(length, index, radians) {
        super(length, index);

        this.radius = length / radians;
        this.radians = radians;
    }

    /**
     * Sample this track piece
     * @param {Vector3} target The target vector to write to
     * @param {number} t The position to sample at in the range [0, 1]
     * @returns {Vector3} The target
     */
    sample(target, t) {
        target.x = Math.sin(t * this.radians) * this.radius;
        target.y = 0;
        target.z = -this.radius + this.radius * Math.cos(t * this.radians);

        return this.transformByOffset(target);
    }

    /**
     * Sample the track derivative
     * @param {number} t The position to sample at in the range [0, 1]
     * @returns {number} The track derivative in radians
     */
    derivative(t) {
        return -this.radians * t + this.offsetAngle;
    }
}